import { Component, OnInit, Input } from '@angular/core';

import { checkBaggageType, Flight, Ticket, } from 'app/shared';

@Component({
    selector: 'app-departure-arrival-diff',
    templateUrl: './departure-arrival-diff.component.html',
    styleUrls: ['./departure-arrival-diff.component.css']
})
export class DepartureArrivalDiffComponent implements OnInit {

    @Input('flight') flight: Flight;
    @Input() isOtheroutbound: boolean = false;
    @Input('text') text: string;

    isDepartureAndArrivalSame: boolean = true;
    isArrivalAndDepartureSame: boolean = true;
    isDepartureHasBag: boolean;
    isArrivalHasBag: boolean;
    isTypeOfBagsDifferent: boolean;

    returnFirstTicket: Ticket;
    returnLastTicket: Ticket;
    outboundFirstTicket: Ticket;
    outboundLastTicket: Ticket;

    constructor() { }

    ngOnInit(): void {
        if(this.flight) {
            this.isDepartureAndArrivalSame = this.flight.isDepartureAndArrivalSame;
            this.isArrivalAndDepartureSame = this.flight.isArrivalAndDepartureSame;
    
            this.returnFirstTicket = this.flight.returnTickets[0];
            this.returnLastTicket = this.flight.returnTickets[this.flight.returnTickets.length - 1];
    
            this.outboundFirstTicket = this.flight.outboundTickets[0];
            this.outboundLastTicket = this.flight.outboundTickets[this.flight.outboundTickets.length - 1];
    
            this.isDepartureHasBag = this.flight.isDepartureHasBag;
            this.isArrivalHasBag = this.flight.isArrivalHasBag;
    
            this.checkBaggageType(this.outboundFirstTicket,this.returnLastTicket);
        }
    }

    checkBaggageType(outboundFirstTicket,returnLastTicket) {
        let firstTicketResponse = checkBaggageType(outboundFirstTicket.baggage);

        if(firstTicketResponse === 'isSmallBag') {
            let lastTicketResponse = checkBaggageType(returnLastTicket.baggage);
            if(firstTicketResponse != lastTicketResponse) {
                this.isTypeOfBagsDifferent = true;
            }
        }
        else if (firstTicketResponse === 'isCabinBag') {
            let lastTicketResponse = checkBaggageType(returnLastTicket.baggage);
            if(firstTicketResponse != lastTicketResponse) {
                this.isTypeOfBagsDifferent = true;
            }
        }
        else if(firstTicketResponse === 'isCheckedBag'){
            let lastTicketResponse = checkBaggageType(returnLastTicket.baggage);
            if(firstTicketResponse != lastTicketResponse) {
                this.isTypeOfBagsDifferent = true;
            }
        }
    }
}