<div  *ngIf="desktop === true">
  <div class="title-section">
    <span class="title">
      <span class="filter_icon"><img
        src="../../../assets/img/icons/filter.svg"></span>
        <span>Filters</span>
        <span class="active-filters" *ngIf="filtersService.countOfFilters">({{filtersService.countOfFilters}} applied)</span>
    </span>
    <span class="clear-all-btn" *ngIf="filtersService.filtersHasLength()" (click)="clearAll()">{{filtersService.countOfFilters > 1 ? 'Clear all' : 'Clear'}}</span>
  </div>
  <div class="filters-container">
    <div *ngIf="allCollapsed" class="expand-collapse" (click)="expendAll()">
      <span>Expand all</span>
      <i class="flaticon-agree"></i>
    </div>
    <div *ngIf="!allCollapsed" class="expand-collapse" (click)="collapseAll()">
      <span>Collapse all</span>
      <i class="flaticon-agree rotate"></i>
    </div>
    <div class="filter-item" *ngIf="departureTimeItems.length > 0">
      <app-filter-dropdown
        #departureTimeRef
        [desktop]="true"
        [name]="'Departure Time'"
        [typeInString]="'departureTime'"
        [optionItems]="'departureTimeItems'"
        [isActive]="isActive" 
        [hasSelectAndClearField] = 'true'
        [filters]="filtersService.filters" 
        [filter_length]="filtersService.filters.departureTime.length"
        (selectAllButton)="selectAll($event)" 
        (clearAllButton)="clearAll($event)"
        (onApplyClick)="applyFilter()"
        [items]="departureTimeItems" 
        [previousFilters]="previousFilters"
        (updateCountAfterFilterApplied)="updateCountAfterFilterApplied($event)"
        [mobile]="mobile">
      </app-filter-dropdown>
    </div>
    <div class="filter-item" *ngIf="destinationItems.length > 0">
      <app-filter-dropdown
        #destinationRef
        id="Destination"
        [desktop]="true"
        [name]="'Destination'"
        [typeInString]="'destinations'"
        [optionItems]="'destinationItems'"
        [isActive]="isActive" 
        [hasSelectAndClearField] = 'true'
        [filters]="filtersService.filters" 
        [filter_length]="filtersService.filters.destinations.length"
        (selectAllButton)="selectAll($event)" 
        (clearAllButton)="clearAll($event)"
        (onApplyClick)="applyFilter()"
        [items]="destinationItems" 
        [previousFilters]="previousFilters"
        (updateCountAfterFilterApplied)="updateCountAfterFilterApplied($event)"
        [mobile]="mobile">
      </app-filter-dropdown>
    </div>
    <div class="filter-item" *ngIf="stopItems.length > 0">
      <app-filter-dropdown
        #stopsRef
        [desktop] = 'true'
        [name]="'Stops'"
        [typeInString]="'stops'"
        [optionItems]="'stopItems'"
        [isActive]="isActive" 
        [hasSelectAndClearField] = 'false'
        [filters]="filtersService.filters" 
        [filter_length] = "filtersService.filters.stops.length"
        (onApplyClick)="applyFilter()"
        [items]="stopItems" 
        [previousFilters]="previousFilters"
        (updateCountAfterFilterApplied)="updateCountAfterFilterApplied($event)"
        [mobile]="mobile">
      </app-filter-dropdown>
    </div>
    <div class="filter-item" *ngIf="airlineItems.length > 0">
      <app-filter-dropdown
        #airlineRef
        [desktop]='true'
        [name]="'Airline'"
        [typeInString]="'airlines'"
        [optionItems]="'airlineItems'"
        [isActive]="isActive" 
        [hasSelectAndClearField]='true'
        [filters]="filtersService.filters" 
        [filter_length]="filtersService.filters.airlines.length"
        (onApplyClick)="applyFilter()"
        (selectAllButton)="selectAll($event)" 
        (clearAllButton)="clearAll($event)"
        [items]="airlineItems" 
        [previousFilters]="previousFilters"
        (updateCountAfterFilterApplied)="updateCountAfterFilterApplied($event)"
        [mobile]="mobile">
      </app-filter-dropdown>
    </div>
    <div class="filter-item" *ngIf="filtersService.priceItems.length > 0 && optionsForPrice != null && flights.length > 1">
      <app-filter-dropdown
        #priceRef
        [desktop]='true' 
        [name]="'Price'"
        [typeInString]="'price'"
        [optionItems]="'filtersService.priceItems'"
        [isActive]="isActive" 
        [hasSelectAndClearField] = 'true'
        [filters]="filtersService.filters" 
        [filter_length]="filtersService.filters.price.length"
        (onApplyClick)="applyFilter()"
        (selectAllButton)="selectAll($event)" 
        (clearAllButton)="clearAll($event)"
        [items]="filtersService.priceItems" 
        [previousFilters]="previousFilters"
        [typeOfAppliedFilter]="typeOfAppliedFilter"
        [length_of_flights]="flights.length"
        (updateCountAfterFilterApplied)="updateCountAfterFilterApplied($event)"
        [optionsForPrice]='optionsForPrice'
        [mobile]="mobile">
      </app-filter-dropdown>
    </div>
    <div class="filter-item" *ngIf="filtersService.hoursItems.length > 0 && optionsForTime != null && flights.length > 1">
      <app-filter-dropdown
        #timeLeftRef
        [desktop] = 'true'
        [name]="'Time left'"
        [typeInString]="'timeLeft'"
        [optionItems]="'filtersService.hoursItems'"
        [isActive]="isActive" 
        [hasSelectAndClearField] = 'false'
        [filters]="filtersService.filters" 
        [filter_length] = "filtersService.filters.timeLeft.length"
        (onApplyClick)="applyFilter()"
        [items]="filtersService.hoursItems" 
        [previousFilters]="previousFilters"
        [length_of_flights]="flights.length"
        [typeOfAppliedFilter]="typeOfAppliedFilter"
        (updateCountAfterFilterApplied)="updateCountAfterFilterApplied($event)"
        [optionsForTime]='optionsForTime'
        [mobile]="mobile">
      </app-filter-dropdown>
    </div>
  </div>
</div>

<div class='main'  *ngIf="!desktop">
  <div class="title-section">
    <span class="title">
      <span class="filter_icon"><img
        src="../../../assets/img/icons/filter.svg"></span>
        <span>Filters</span>
        <span class="active-filters" *ngIf="filtersService.countOfFilters">({{filtersService.countOfFilters}} applied)</span>
        <span class="clear-all-btn" *ngIf="filtersService.filtersHasLength()" (click)="clearAll()">{{filtersService.countOfFilters > 1 ? 'Clear all' : 'Clear'}}</span>
    </span>
    <span class="close-button" (click)="close()">
      <!-- <span class="glyphicon glyphicon-remove"></span> -->
      <img alt="" src="../../../assets/img/icons/cross-dark-gray.svg">
    </span>
  </div>
  <div class="filters-container">
    <div *ngIf="allCollapsed" class="expand-collapse" (click)="expendAll()">
      <span>Expand all</span>
      <i class="flaticon-agree"></i>
    </div>
    <div *ngIf="!allCollapsed" class="expand-collapse" (click)="collapseAll()">
      <span>Collapse all</span>
      <i class="flaticon-agree rotate"></i>
    </div>
    <div class="filter-item" *ngIf="departureTimeItems.length > 0">
      <app-filter-dropdown
        #departureTimeRef
        [desktop] = 'false'
        [name]="'Departure Time'"
        [typeInString]="'departureTime'"
        [optionItems]="'departureTimeItems'"
        [isActive]="isActive" 
        [hasSelectAndClearField] = 'true'
        [filters]="filtersService.filters" 
        [filter_length] = "filtersService.filters.departureTime.length"
        (selectAllButton)="selectAll($event)" 
        (clearAllButton) = clearAll($event)
        (onApplyClick)="applyFilter()"
        (onOptionClick)="optionIsClicked()"
        [items]="departureTimeItems" 
        [previousFilters]="previousFilters"
        (updateCountAfterFilterApplied)="updateCountAfterFilterApplied($event)"
        [mobile]="mobile">
      </app-filter-dropdown>
    </div>
    <div class="filter-item" *ngIf="destinationItems.length > 0">
      <app-filter-dropdown
        #destinationRef
        [desktop] = 'false'
        [name]="'Destination'"
        [typeInString]="'destinations'"
        [optionItems]="'destinationItems'"
        [isActive]="isActive" 
        [hasSelectAndClearField] = 'true'
        [filters]="filtersService.filters" 
        [filter_length] = "filtersService.filters.destinations.length"
        (selectAllButton)="selectAll($event)" 
        (clearAllButton) = clearAll($event)
        (onApplyClick)="applyFilter()"
        (onOptionClick)="optionIsClicked()"
        [items]="destinationItems" 
        [previousFilters]="previousFilters"
        (updateCountAfterFilterApplied)="updateCountAfterFilterApplied($event)"
        [mobile]="mobile">
      </app-filter-dropdown>
    </div>
    <div class="filter-item" *ngIf="stopItems.length > 0">
      <app-filter-dropdown
        #stopsRef
        [desktop] = 'false'
        [name]="'Stops'"
        [typeInString]="'stops'"
        [optionItems]="'stopItems'"
        [isActive]="isActive" 
        [hasSelectAndClearField] = 'false'
        [filters]="filtersService.filters" 
        [filter_length] = "filtersService.filters.stops.length"
        (onOptionClick)="optionIsClicked()"
        (onApplyClick)="applyFilter()"
        [items]="stopItems" 
        [previousFilters]="previousFilters"
        (updateCountAfterFilterApplied)="updateCountAfterFilterApplied($event)"
        [mobile]="mobile">
      </app-filter-dropdown>
    </div>
    <div class="filter-item" *ngIf="airlineItems.length > 0">
      <app-filter-dropdown
        #airlineRef
        [desktop] = 'false'
        [name]="'Airline'"
        [typeInString]="'airlines'"
        [optionItems]="'airlineItems'"
        [isActive]="isActive" 
        [hasSelectAndClearField] = 'true'
        [filters]="filtersService.filters" 
        [filter_length] = "filtersService.filters.airlines.length"
        (onOptionClick)="optionIsClicked()"
        (onApplyClick)="applyFilter()"
        (selectAllButton)="selectAll($event)" 
        (clearAllButton) = "clearAll($event)"
        [items]="airlineItems" 
        [previousFilters]="previousFilters"
        (updateCountAfterFilterApplied)="updateCountAfterFilterApplied($event)"
        [mobile]="mobile">
      </app-filter-dropdown>
    </div>
    <div class="filter-item" *ngIf="filtersService.priceItems.length > 0 && optionsForPrice != null">
      <app-filter-dropdown
        #priceRef
        [desktop] = 'false' 
        [name]="'Price'"
        [typeInString]="'price'"
        [optionItems]="'filtersService.priceItems'"
        [isActive]="isActive" 
        [hasSelectAndClearField] = 'true'
        [filters]="filtersService.filters" 
        [filter_length] = "filtersService.filters.price.length"
        (onOptionClick)="optionIsClicked()"
        (onApplyClick)="applyFilter()"
        (selectAllButton)="selectAll($event)" 
        (clearAllButton) = clearAll($event)
        [items]="filtersService.priceItems" 
        [previousFilters]="previousFilters"
        [typeOfAppliedFilter]="typeOfAppliedFilter"
        [length_of_flights]="flights.length"
        (updateCountAfterFilterApplied)="updateCountAfterFilterApplied($event)"
        [optionsForPrice]= 'optionsForPrice'
        [mobile]="mobile">
      </app-filter-dropdown>
    </div>
    <div class="filter-item time-left" *ngIf="filtersService.hoursItems.length > 0 && optionsForTime != null">
      <app-filter-dropdown
        #timeLeftRef
        [desktop] = 'false'
        [name]="'Time left'"
        [typeInString]="'timeLeft'"
        [optionItems]="'filtersService.hoursItems'"
        [isActive]="isActive" 
        [hasSelectAndClearField] = 'false'
        [filters]="filtersService.filters" 
        [filter_length] = "filtersService.filters.timeLeft.length"
        (onOptionClick)="optionIsClicked()"
        (onApplyClick)="applyFilter()"
        [items]="filtersService.hoursItems" 
        [previousFilters]="previousFilters"
        [length_of_flights]="flights.length"
        [typeOfAppliedFilter]="typeOfAppliedFilter"
        (updateCountAfterFilterApplied)="updateCountAfterFilterApplied($event)"
        [optionsForTime]='optionsForTime'
        [mobile]="mobile">
      </app-filter-dropdown>
    </div>
  </div>
</div>