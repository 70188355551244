import { Component, OnInit, Input } from '@angular/core';
import { Baggage, BaggageItem } from '../../shared';

@Component({
    selector: 'app-baggage-info',
    templateUrl: './baggage-info.component.html',
    styleUrls: ['./baggage-info.component.css']
})

export class BaggageInfoComponent implements OnInit {
    @Input('baggage_items') baggage_items: Array<BaggageItem>;

    small_bag = false;
    cabin_bag = false;
    large_bag = false;
    

    displayText: string = ''

    constructor() {
    }

    ngOnInit() {

        if(this.baggage_items[0].qty && !this.baggage_items[1].qty && !this.baggage_items[2].qty) {
            this.small_bag = true;
            this.displayText = 'Small Bag only'
        }
        if (this.baggage_items[0].qty && this.baggage_items[1].qty && !this.baggage_items[2].qty) {
            this.cabin_bag = true;
            this.displayText = 'Cabin Bags only'
        }
        else if(this.baggage_items[0].qty && this.baggage_items[1].qty && this.baggage_items[2].qty){
            this.large_bag = true;
            if (this.baggage_items[2].unit === 'KG') {
                this.displayText += '1'
            }
            else {
                this.displayText += this.baggage_items[2].qty.toString()
            }

            if(this.baggage_items[2].qty > 1 && this.baggage_items[2].unit !== 'KG'){
                this.displayText += ' Checked Bags'
            }
            else{
                this.displayText += ' Checked Bag'
            }

            // if (this.baggage.unit === 'KG') {
            //     this.displayText += ` (${this.baggage.qty} kg)`
            // }
        }
    }
}