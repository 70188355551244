
import {finalize} from 'rxjs/operators';
import { Component, ViewChild, ElementRef, ViewEncapsulation, EventEmitter, Input, Output, OnInit, HostListener } from '@angular/core';

import { Ticket, Flight, toggle, PassengerList } from 'app/shared/index';
import { Subscription } from 'rxjs';

import { ReturnFlightAccordionService } from 'app/components/return-flight-accordion/return-flight-accordion.service';
import { FlightManagerService } from 'services/flight-manager/flight-manager.service';
import { RestService } from 'services/rest/rest.service';
import { AlertService } from 'services/alert/alert.service';

import { Router } from '@angular/router'
import { HttpClient } from '@angular/common/http';

import { ActivatedRoute } from '@angular/router';

import * as moment from 'moment';

@Component({
    selector: 'app-return-flight-accordion',
    templateUrl: './return-flight-accordion.component.html',
    styleUrls: ['./return-flight-accordion.component.css'],
    animations: [
        toggle
    ]
})
export class ReturnFlightAccordionComponent implements OnInit {
    innerWidth: number;

    @HostListener('window:resize', ['$event'])
    onResize(event) {
        this.innerWidth = window.innerWidth;
    }

    @Output('isToday') isToday = new EventEmitter();

    @Input('highlight') highlight: boolean;
    @Input('color') color: string
    @Input('flight') flight: Flight;
    @Input('isOtherOutbound') isOtherOutbound: boolean;
    @Input('state') state = 'inactive';

    returnFirstTicket: Ticket;
    returnLastTicket: Ticket;
    outboundFirstTicket: Ticket;
    outboundLastTicket: Ticket;

    onClose: EventEmitter<void> = new EventEmitter();
    subscription: Subscription;

    outboundWillArriveNextDay: boolean = false;
    inboundWillArriveNextDay: boolean = false;
    dayOfOutboundArrival: number;
    dayOfInboundArrival: number;

    departureWillFlyTomorrow: boolean = false;

    passengerList: PassengerList;

    arriveAt: boolean = false;
    returnFrom: boolean = false;

    selectedFlightId: string;
    isDepartureAndArrivalSame: boolean = true;
    isArrivalAndDepartureSame: boolean = true;

    totalDuration: moment.Duration = moment.duration(0, 'hours');
    totalDurationOfReturnFlights: moment.Duration = moment.duration(0, 'hours');

    loading: boolean = false;
    keepWaiting = false;

    notHighlightFlight: boolean = true;

    multipleAirlinesOutbound: boolean = false;
    multipleAirlinesInbound: boolean = false;
    outboundIsUndisclosed: boolean = false;
    inboundIsUndisclosed: boolean = false;

    constructor(
        private accordionService: ReturnFlightAccordionService,
        private flightManager: FlightManagerService,
        private rest: RestService,
        private alertService: AlertService,
        private router: Router,
        private httpClient: HttpClient,
        private activateRout:ActivatedRoute
    ) {
        this.subscription = this.onClose.subscribe(() => {
            this.state = 'inactive'
        })
        this.passengerList = this.flightManager.passengerList;


    }
    ngOnDestroy() {
        this.subscription.unsubscribe();
        this.flight.stopCountDown();
    }
    ngOnInit() {
        this.checkAirlines(this.flight);
        // let selectedFlightNumber = localStorage.getItem('selectedFlight');
        if(!this.flight.isOtherOutbound) {
            this.notHighlightFlight = false;
        }

        this.innerWidth = window.innerWidth;

        this.color = this.color || 'grey';
        
        this.returnFirstTicket = this.flight.returnTickets[0];
        this.returnLastTicket = this.flight.returnTickets[this.flight.returnTickets.length - 1];
        this.outboundFirstTicket = this.flight.outboundTickets[0];
        this.outboundLastTicket = this.flight.outboundTickets[this.flight.outboundTickets.length - 1];
        

        if (this.flight.outboundTickets[0].departure.code !=
            this.flight.returnTickets[this.flight.returnTickets.length - 1].arrival.code)
            this.isDepartureAndArrivalSame = false;


        if (this.flight.outboundTickets[this.flight.outboundTickets.length - 1].arrival.code !=
            this.flight.returnTickets[0].departure.code)
            this.isArrivalAndDepartureSame = false;

        this.checkReturnDate()
        this.checkDepartureDate();
        this.getStop(this.flight)
        this.getStopForReturnFlights(this.flight);

        if (this.state == 'active') {
            this.accordionService.onReturnFlightSelected.emit(this.flight);
            this.selectedFlightId = this.flight.id;
        }
    }


    checkAirlines(flight:Flight) {
        if(flight.outboundTickets.length > 0) {
            let airlineName = flight.outboundTickets[0].airline.name;
            flight.outboundTickets.forEach((ticket)=>{
                if(ticket.airline.name != airlineName) {
                    this.multipleAirlinesOutbound = true;
                    return;
                }
            })
        }

        if(!this.flight.outboundTickets[0].airline.name) {
            this.flight.outboundTickets.forEach((ticket)=>{
                if(!ticket.airline.name) {
                    this.outboundIsUndisclosed = true; 
                 }else {
                     this.outboundIsUndisclosed = false
                     return
                 }
            })
        }

        if(flight.returnTickets.length > 0) {
            let airlineName = flight.returnTickets[0].airline.name;
            flight.returnTickets.forEach((ticket)=>{
                if(ticket.airline.name != airlineName) {
                    this.multipleAirlinesInbound = true;
                    return;
                }
            })
        }

        if(!this.flight.returnTickets[0].airline.name) {
            this.flight.returnTickets.forEach((ticket)=>{
                if(!ticket.airline.name) {
                    this.inboundIsUndisclosed = true; 
                 }else {
                     this.inboundIsUndisclosed = false
                     return
                 }
            })
        }
    }

    toggle() {
        this.state = this.state === 'active' ? 'inactive' : 'active';
        let flight = this.flight;
        if (this.state === 'active') {
            this.accordionService.closeOpenedAccordion(this.onClose);
            this.selectedFlightId = flight.id;
            this.getFlightDate(this.outboundFirstTicket,true);
        }
        else {
            flight = null;
            this.accordionService.reset();
            this.getFlightDate(this.outboundFirstTicket,false);
        }
        this.accordionService.onReturnFlightSelected.emit(flight)
    }

    onPassengersUpdated(passengerList: PassengerList) {
        this.passengerList = passengerList;
    }
    
    checkReturnDate() {
        if (this.returnFirstTicket && this.returnLastTicket) {
            let result = this.flightManager.willArriveNextDay(this.returnFirstTicket,this.returnLastTicket);
            this.inboundWillArriveNextDay = result?.willArriveNextDay;
            this.dayOfInboundArrival = result?.dayOfArrival;
            // if (this.returnFirstTicket.departure.date.date() != this.returnLastTicket.arrival.date.date()) {
            //     this.inboundWillArriveNextDay = true;
            //     this.dayOfInboundArrival = this.returnLastTicket.arrival.date.clone().startOf('day').diff(this.returnFirstTicket.departure.date.clone().startOf('day'), 'days');
            // }
        }

        if (this.outboundFirstTicket && this.outboundLastTicket) {
            let result = this.flightManager.willArriveNextDay(this.outboundFirstTicket,this.outboundLastTicket);
            this.outboundWillArriveNextDay = result?.willArriveNextDay;
            this.dayOfOutboundArrival = result?.dayOfArrival;
            // if (this.outboundFirstTicket.departure.date.date() != this.outboundLastTicket.arrival.date.date()) {
            //     this.outboundWillArriveNextDay = true;
            //     this.dayOfOutboundArrival = this.outboundLastTicket.arrival.date.clone().startOf('day').diff(this.outboundFirstTicket.departure.date.clone().startOf('day'), 'days');
            // }
        }
    }

    navigateToPassengerPage() {
        this.loading = true;
        this.keepWaiting = true;
        this.rest.getPriceBreakdown(this.selectedFlightId, this.passengerList.adult, this.passengerList.children, this.passengerList.infants).pipe(
            finalize(() => {
                this.loading = false;
                this.keepWaiting = false;
            }))
            .subscribe((data) => {
                this.flightManager.getPassengersDetails(data);
                this.router.navigate(['/wizard/passenger'], {
                    queryParams: {
                        bookingId: data.bookingId,
                        flightId: this.flight.id,
                        adults: this.passengerList.adult,
                        children: this.passengerList.children,
                        infants: this.passengerList.infants
                    }
                });
            }, (err) => {
                this.loading = false;
                if(err.code === 1) {
                    this.alertService.errorCode_1(true);
                }else {
                    this.alertService.somethingBadHappened(true);
                }
                
            })
    }

    checkDepartureDate() {
        if (this.outboundFirstTicket.departure.date.date() > moment().date()) {
            this.departureWillFlyTomorrow = true;
        }
    }

    getStop(flight) {
        this.totalDuration = moment.duration(0, 'hours');
        flight.outboundTickets.map((ticket, index) => {
            this.totalDuration.add(ticket.duration);
            if (index > 0) {
                let previousTicket = flight.outboundTickets[index - 1];
                this.totalDuration.add(ticket.departure.date.diff(previousTicket.arrival.date));
            }
        })
    }

    getStopForReturnFlights(flight) {
        this.totalDurationOfReturnFlights = moment.duration(0, 'hours');
        flight.returnTickets.map((ticket, index) => {
            this.totalDurationOfReturnFlights.add(ticket.duration);
            if (index > 0) {
                let previousTicket = flight.returnTickets[index - 1];
                this.totalDurationOfReturnFlights.add(ticket.departure.date.diff(previousTicket.arrival.date));
            }
        })
    }

    getFlightDate(ticket: Ticket,status) {
        this.isToday.emit({date:ticket.departure.date, status: status});
    }
}
