import { Baggage, Flight, FlightFilter } from "app/shared";
import * as moment from "moment";


export const sortFlightsByPrice = (flights: Array<Flight>, order: number, overwrite: boolean = false) => {
    if (!overwrite)
        flights = flights.slice();
    // if order is = 1, then sorts by descending order
    // if order is = -1, then sorts by ascending order
    flights.sort((flight1: Flight, flight2: Flight) => {
        if (flight1.price < flight2.price)
            return -1 * order;
        if (flight1.price > flight2.price)
            return order;

        return 0;
    })

    return flights;
}

export const sortFlightsByTime = (flights: Array<Flight>, order: number, overwrite: boolean = false) => {
    if (!overwrite)
        flights = flights.slice();
    // if order is = 1, then sorts by descending order
    // if order is = -1, then sorts by ascending order
    flights.sort((flight1: Flight, flight2: Flight) => {
        let date1 = flight1.outboundTickets[0].departure.date;
        let date2 = flight2.outboundTickets[0].departure.date;
        if (date1.isBefore(date2))
            return -1 * order;
        if (date1.isAfter(date2))
            return order;

        return 0;
    })

    return flights;
}

export const sortFlightsByTimeLeft = (flights: Array<Flight>, order: number, overwrite: boolean = false) => {
    if (!overwrite)
        flights = flights.slice();
    // if order is = 1, then sorts by descending order
    // if order is = -1, then sorts by ascending order
    flights.sort((flight1: Flight, flight2: Flight) => {
        if (flight1.timeLeft.asSeconds() < flight2.timeLeft.asSeconds())
            return -1 * order;
        if (flight1.timeLeft.asSeconds() > flight2.timeLeft.asSeconds())
            return order;

        return 0;
    })

    return flights;
}

export const sortFlightsByStops = (flights: Array<Flight>, order: number, overwrite: boolean = false) => {
    if (!overwrite)
        flights = flights.slice();
    // if order is = 1, then sorts by descending order
    // if order is = -1, then sorts by ascending order
    flights.sort((flight1: Flight, flight2: Flight) => {
        if (flight1.outboundTickets.length < flight2.outboundTickets.length)
            return -1 * order;
        if (flight1.outboundTickets.length > flight2.outboundTickets.length)
            return order;

        return 0;
    })
    return flights;
}

export const sortFlightsByAirline = (flights: Array<Flight>, order: number, overwrite: boolean = false) => {
    if (!overwrite)
        flights = flights.slice();
    // if order is = 1, then sorts by descending order
    // if order is = -1, then sorts by ascending order
    flights.sort((flight1: Flight, flight2: Flight) => {
        let airline1 = flight1.outboundTickets[0].airline.name || "Undisclosed";
        let airline2 = flight2.outboundTickets[0].airline.name || "Undisclosed";
        if (airline1.toLowerCase() < airline2.toLowerCase())
            return -1 * order;
        if (airline1.toLowerCase() > airline2.toLowerCase())
            return order;

        return 0;
    })
    return flights;
}

export const sortFlightsByDestination = (flights: Array<Flight>, order: number, overwrite: boolean = false) => {
    if (!overwrite)
        flights = flights.slice();
    // if order is = 1, then sorts by descending order
    // if order is = -1, then sorts by ascending order
    flights.sort((flight1: Flight, flight2: Flight) => {
        let dest1 = flight1.outboundTickets[flight1.outboundTickets.length - 1].arrival.city;
        let dest2 = flight2.outboundTickets[flight2.outboundTickets.length - 1].arrival.city;
        if (dest1 < dest2)
            return -1 * order;
        if (dest1 > dest2)
            return order;

        return 0;
    })
    return flights;
}


export const filterifyFlightByPrice = (flight: Flight, filter: FlightFilter): boolean => {
    let shouldBeFilteredOut = false;
    if(filter.price.length > 1) {
        let arrOfAnswers = [];
        filter.price.forEach(price => {
            let min = parseInt(price.split('-')[0]);
            let max = parseInt(price.split('-')[1]);
            
            let flightPrice = Math.ceil(flight.price);

            if(flightPrice >= min && flightPrice <= max) {
                arrOfAnswers.push(true);
            }else {
                arrOfAnswers.push(false);
            }
        });
        if(arrOfAnswers.includes(true)) {
            shouldBeFilteredOut = false;
        }else {
            shouldBeFilteredOut = true;
        }
    }else if(filter.price.length === 1) {
        let price = filter.price[0];
            let min = parseInt(price.split('-')[0])
            let max = parseInt(price.split('-')[1])
            let flightPrice = Math.ceil(flight.price);
            if (flightPrice < min || flightPrice > max) {
                shouldBeFilteredOut = true;
            }
    }

    return shouldBeFilteredOut;
}

// export const filterifyFlightByTimeLeft = (flight: Flight, filter: FlightFilter): boolean => {
//     let shouldBeFilteredOut = false;
//     filter.timeLeft.map((timeLeft) => {
//         // timeLeft is in hours
//         let min = parseInt(timeLeft.split('-')[0])
//         let max = parseInt(timeLeft.split('-')[1])
//         let flightTimeLeft = flight.timeLeft.hours();
//         if (flightTimeLeft >= min || flightTimeLeft < max) {
//             shouldBeFilteredOut = true;
//         }
//     });

//     return shouldBeFilteredOut;
// }

export const filterifyFlightByTimeLeft = (flight: Flight, filter: FlightFilter): boolean => {
    let shouldBeFilteredOut = false;
    if(filter.timeLeft.length > 1) {
        let arrOfAnswers = [];
        filter.timeLeft.forEach(timeLeft => {
            let min = parseInt(timeLeft.split('-')[0]);
            let max = parseInt(timeLeft.split('-')[1]);
        
            let flightTimeLeft = flight.timeLeft.days() * 24 + flight.timeLeft.hours();

            if(flightTimeLeft >= min || flightTimeLeft < max) {
                arrOfAnswers.push(true);
            }else {
                arrOfAnswers.push(false);
            }
        });
        if(arrOfAnswers.includes(true)) {
            shouldBeFilteredOut = false;
        }else {
            shouldBeFilteredOut = true;
        }
    }else if(filter.timeLeft.length === 1) {
        let timeLeft = filter.timeLeft[0]
            // timeLeft is in hours
            let min = parseInt(timeLeft.split('-')[0])
            let max = parseInt(timeLeft.split('-')[1])
            let flightTimeLeft = flight.timeLeft.days() * 24 + flight.timeLeft.hours();
            if (flightTimeLeft < min || flightTimeLeft >= max) {
                shouldBeFilteredOut = true;
            }
    }
    

    return shouldBeFilteredOut;
}

// export const filterifyFlightByAirline = (flight: Flight, filter: FlightFilter): boolean => {
//     let shouldBeFilteredOut = false;
//     filter.airlines.map((airline) => {
//         if (airline == flight.outboundTickets[0].airline.name)
//             shouldBeFilteredOut = true;
//     });

//     return shouldBeFilteredOut;
// }

export const filterifyFlightByAirline = (flight: Flight, filter: FlightFilter): boolean => {
    let shouldBeFilteredOut = false;

    if(filter.airlines.length) {
        let arrOfAnswers = [];
        filter.airlines.forEach(airline => {

            if(airline === "Multiple airlines") {
                if(flight.outboundTickets[0].airline.name != flight.outboundTickets[flight.outboundTickets.length -1].airline.name) {
                    arrOfAnswers.push(true);
                }else {
                    arrOfAnswers.push(false);
                }
            }else {
                if(airline === flight.outboundTickets[0].airline.name) {
                    arrOfAnswers.push(true);
                }else {
                    arrOfAnswers.push(false);
                }
            }
        });

        if(arrOfAnswers.includes(true)) {
            shouldBeFilteredOut = false;
        }else {
            shouldBeFilteredOut = true;
        }
    }
    return shouldBeFilteredOut;
}

export const filterifyFlightByDepartureTime = (flight: Flight, filter: FlightFilter): boolean => {
    let shouldBeFilteredOut = false;
    const localDate = moment();

    if(filter.departureTime.length) {
        let arrOfAnswers = [];
        filter.departureTime.forEach(date => {

            if (date === "Today") {
                if (flight.outboundTickets[0].departure.date.day() === localDate.day()) {
                    arrOfAnswers.push(true);
                } else {
                    arrOfAnswers.push(false);
                }
            } else if (date === "Tomorrow") {
                if (
                    flight.outboundTickets[0].departure.date.day() - localDate.day() === 1 ||
                    flight.outboundTickets[0].departure.date.day() - localDate.day() === -6
                ) {
                    arrOfAnswers.push(true);
                } else {
                    arrOfAnswers.push(false);
                }
            } else {
                if (
                    flight.outboundTickets[0].departure.date.day() !== localDate.day() &&
                    flight.outboundTickets[0].departure.date.day() - localDate.day() !== 1 &&
                    flight.outboundTickets[0].departure.date.day() - localDate.day() !== -6
                ) {
                    arrOfAnswers.push(true);
                } else {
                    arrOfAnswers.push(false);
                }
            }
        });

        if(arrOfAnswers.includes(true)) {
            shouldBeFilteredOut = false;
        }else {
            shouldBeFilteredOut = true;
        }
    }
    return shouldBeFilteredOut;
}


export const filterifyFlightByDestination = (flight: Flight, filter: FlightFilter): boolean => {
    let shouldBeFilteredOut = false;
    if(filter.destinations.length > 1) {
        let arrOfAnswers = [];
        filter.destinations.forEach(worldArea => {

            if(worldArea === flight.outboundTickets[flight.outboundTickets.length - 1].arrival.worldArea) {
                arrOfAnswers.push(true);
            }else {
                arrOfAnswers.push(false);
            }
        });

        if(arrOfAnswers.includes(true)) {
            shouldBeFilteredOut = false;
        }else {
            shouldBeFilteredOut = true;
        }

    }else if(filter.destinations.length === 1) {
       let worldArea = filter.destinations[0];
            if (worldArea != flight.outboundTickets[flight.outboundTickets.length - 1].arrival.worldArea)
                shouldBeFilteredOut = true;
    }
    

    return shouldBeFilteredOut;
}

export const filterifyFlightByStop = (flight: Flight, filter: FlightFilter): boolean => {
    let shouldBeFilteredOut = false;
    
    if(filter.stops.length > 1) {
        let arrOfAnswers = [];
        filter.stops.forEach(stop => {
            if(stop == 'Direct') stop = 0;

            if(stop == flight.outboundTickets.length - 1) {
                arrOfAnswers.push(true);
            }else {
                arrOfAnswers.push(false);
            }
        });

        if(arrOfAnswers.includes(true)) {
            shouldBeFilteredOut = false;
        }else {
            shouldBeFilteredOut = true;
        }

    }else if(filter.stops.length === 1) {
        let stop = filter.stops[0];
            if(stop == 'Direct') stop = 0;
            if (stop != flight.outboundTickets.length - 1)
                shouldBeFilteredOut = true;
    }
    

    return shouldBeFilteredOut;
}

export const stringArrLowerCaseCompare = (item1:string, item2:string): number => {
    item1 = item1.toLowerCase();
    item2 = item2.toLowerCase();
    if (item1 < item2)
        return -1;
    if (item1 > item2)
        return 1;
    return 0;
}

export const compareStops = (item1:string, item2:string): number => {
    item1 = item1.toLowerCase();
    item2 = item2.toLowerCase();
    if(item1 === 'direct' || item2 === 'direct') {
        return -1;
    }
    if (item1 < item2){
        return -1;
    }
    if (item1 > item2){
        return 1;
    }
        
    return 0;
}

export const checkBaggageType = (baggage:Baggage)=> {
    if(baggage.items[0].qty && !baggage.items[1].qty && !baggage.items[2].qty) {
        return 'isSmallBag';
    }
    if (baggage.items[0].qty && baggage.items[1].qty && !baggage.items[2].qty) {
        return 'isCabinBag';
    }
    else if(baggage.items[0].qty && baggage.items[1].qty && baggage.items[2].qty){
        return 'isCheckedBag';
    }
}