import { Injectable } from '@angular/core';

declare let gtag:Function;

@Injectable({
  providedIn: 'root'
})
export class AnalyticsService {

  constructor() { }

  public sendEvent(category: string, action: string, label: string = null, value: number = 1){ 

    try {
      gtag('event', action, { 
        event_category: category, 
        event_label: label, 
        value: value
      });
    } catch (error) {
      console.log(error);
    }

  }
}
