import { Pipe, PipeTransform } from '@angular/core';


@Pipe({
    name: 'priceRound'
})
export class PriceRoundPipe implements PipeTransform {

    transform(value: number): any {
      return Math.ceil(value);
    }
}
