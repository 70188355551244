<div class="main-wrapper">

    <h3 class="main-header">
        What our customers say
    </h3>

    <div class="reviews-section container">

        <div class="arrow-btn" (click)="onPreviousClick()">
            <div class="icon previous">
                <i class="flaticon-agree"></i>
            </div>
        </div>

        <div class="reviews" #reviewsContainer (scroll)="onScroll($event)">

                <div class="review" *ngFor="let review of reviews">
                    <div>
                        <div class="review-text-box">
                            <q>{{review.reviewText}}</q>
                        </div>
                        <div class="ballon-arrow">

                        </div>
                    </div>

                    <div class="name-and-score">
                        <div class="name">
                            {{review.name}}
                        </div>
                        <div class="score">
                            <img class="review-stars" src="../../../assets/img/trustpilot-stars.png" alt="">
                        </div>
                    </div>
                </div>

                <div class="last-spacing-review-item"></div>

        </div>

        <div class="arrow-btn"  (click)="onNextClick()">
            <div class="icon next">
                <i class="flaticon-agree"></i>
            </div>
        </div>
    </div>
</div>