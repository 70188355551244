<span class="main" *ngIf="!minutes" [ngClass]="{'no-letter-spacing': isMobile}">
    <span class="numbers" [ngClass]="[flight_card ?'flights_card_style': '', img_card ? 'img_card_style': '']">{{hours$ | async}}</span>
    <span class="tiny">{{text_hours$ | async }}<span *ngIf="!isMobile">{{' '}}</span></span>
    <span class="numbers" [ngClass]="[flight_card ?'flights_card_style': '', img_card ? 'img_card_style': '']">{{minutes$ | async}}</span>
    <span class="tiny">{{text_minutes$ | async }}<span *ngIf="!isMobile">{{' '}}</span></span>
    <span class="numbers" [ngClass]="[flight_card ?'flights_card_style': '', img_card ? 'img_card_style': '']">{{seconds$ | async}}</span>
    <span class="tiny">{{text_seconds$ | async }}</span>
</span>
<span class="main" *ngIf="minutes">{{minutes$ | async}}<span
        class="tiny">{{text_minutes$ | async }}{{' '}}</span>{{seconds$ | async}}<span
        class="tiny">{{text_seconds$ | async }}</span></span>