import { Component, Input, OnInit } from '@angular/core';

import { Flight } from 'app/shared/index';
import { ConfigService } from 'app/services/config/config.service';
import { ClockService } from 'services/clock/clock.service';
import * as moment from 'moment';

@Component({
    selector: 'app-img-card',
    templateUrl: './img-card.component.html',
    styleUrls: ['./img-card.component.css']
})
export class ImgCardComponent implements OnInit {
  @Input('flight') flight: Flight;
  imgUrl: string;

  isToday: boolean = false;

  
  constructor(private config: ConfigService, private clock: ClockService) {
    this.imgUrl = this.config.get('IMAGE_HOST_URL') + this.config.get('IMAGE_HOST_FOLDER_PATH');
  }

  ngOnInit() {
    this.isToday = moment().day() === this.flight.outboundTickets[0].departure.date.day() ? true : false ;

    this.imgUrl = this.imgUrl + this.flight?.outboundTickets[this.flight.outboundTickets.length-1].arrival.cityCode.toLocaleLowerCase() + '.jpg';
    this.flight.startCountDown(this.clock);
  }
  
  ngOnDestroy() {
    this.flight.stopCountDown();
  }
}
