import { Injectable } from '@angular/core';
import { EventEmitter } from '@angular/core';

@Injectable()
export class FlightAccordionService {
    readonly onFligthCompRegistered: EventEmitter<string> = new EventEmitter(true);
    
    private registeredFlightComponents: Map<string, string> = new Map()
    private onCloseCurrentAccordion: EventEmitter<void>;

    constructor() {}

    closeOpenedAccordion(onCloseNextAccordion: EventEmitter<void>){
        if(this.onCloseCurrentAccordion)
            this.onCloseCurrentAccordion.emit();
        this.onCloseCurrentAccordion = onCloseNextAccordion;
    }

    register(flightId: string): void{
        this.registeredFlightComponents.set(flightId, flightId);
        this.onFligthCompRegistered.emit(flightId);
    }

    deregister(flightId: string): void{
        this.registeredFlightComponents.delete(flightId);
    }

    isRegistered(flightId: string): boolean{
        return this.registeredFlightComponents.has(flightId);
    }
    
    reset(){
        if(this.onCloseCurrentAccordion)
            this.onCloseCurrentAccordion.emit();
        this.onCloseCurrentAccordion = null;
    }

    getRegisteredFlightCount(){
        return this.registeredFlightComponents.size;
    }
}
