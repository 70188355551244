import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-cookie-message',
  templateUrl: './cookie-message.component.html',
  styleUrls: ['./cookie-message.component.scss']
})
export class CookieMessageComponent implements OnInit {

  public accepted = false;

  constructor() { }

  ngOnInit() {
    if(localStorage.getItem('cookie-accepted')){
      this.accepted = true;
    }
  }

  onGotItClick() {
    localStorage.setItem('cookie-accepted', 'true');
    this.accepted = true;
  }

}
