import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'limitTo'
})
export class LimitToPipe implements PipeTransform {

    transform(value: Array<any>, limit?: number): any {
        let max = Math.max(limit, value.length);
        return value.slice(0, limit)
    }

}
