import { Component, Input, Output, EventEmitter, HostListener, AfterViewInit } from '@angular/core';
import { filterDropdown } from 'app/shared';

import { Options } from '@angular-slider/ngx-slider';
import { FilterItem } from 'services/filters/filters.service';

import { FiltersService } from 'services/filters/filters.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-filter-dropdown',
  templateUrl: './filter-dropdown.component.html',
  styleUrls: ['./filter-dropdown.component.css'],
  animations: [filterDropdown]
})
export class FilterDropdownComponent implements AfterViewInit {

  @Input('isActive') isActive: boolean = false;
  @Input('name') name: string;
  @Input() previousFilters: any;

  @Input('filters') filters;
  @Input('filter_length') filter_length;
  @Input() items = [];
  @Input() typeInString: string;
  @Input() optionItems: string;
  @Input() hasSelectAndClearField;
  @Input() mobile: boolean = false;
  @Input() desktop: boolean = false;
  @Input('length_of_flights') length_of_flights: number = 0;
  @Input('typeOfAppliedFilter') typeOfAppliedFilter: string = "";
  @Input('optionsForPrice') optionsForPrice: Options = {}
  @Input('optionsForTime') optionsForTime: Options = {};

  @Output() onOptionClick = new EventEmitter();
  @Output() onApplyClick = new EventEmitter();
  @Output() selectAllButton = new EventEmitter();

  @Output() updateCountAfterFilterApplied = new EventEmitter();


  itemLength: number = 0;
  isOpen = false;

  initialVisibleDestinationsLimit = 3;
  visibleDestinationsLimit = this.initialVisibleDestinationsLimit;

  priceLowValue: number = 300;
  priceHighValue: number = 600;

  timeLowValue: number;
  timeHighValue: number;

  applyMobileFilters$: Subscription;
  filterCollapse = {
    "Departure Time": true,
    "Stops": false,
    "Airline": false,
    "Price": false,
    "Time left": false,
    "Destination": false,
  };


  private wasInside = false;
  @HostListener('click')
  clickInside() {
    this.wasInside = true;
  }

  @HostListener('document:click')
  clickout() {
    if (!this.wasInside) {
      this.isOpen = false;
    }
    this.wasInside = false;
  }

  constructor(
    public filtersService: FiltersService) {
  }

  ngOnInit() {
    this.applyMobileFilters$ = this.filtersService.applyMobileFilters.subscribe((condition) => {
      if (condition) {
        this.onApply()
      }
    })
  }

  ngAfterViewInit() {
    this.filtersService.updateHeightOfFilterContainer.next(true);
  }

  filterBy(by: string, value: number | string) {
    let index = this.filters[by].indexOf(value);

    if (index > -1) {
      // if the item exists already then we need to remove it from the list
      this.filters[by].splice(index, 1);
    } else {
      // if the item doesn't exist then we need to add it in the list
      this.filters[by].push(value);
    }
    return this.isApplyButtonActive(by);
  }

  filterByForPriceAndTimeLeft(by: string, value: number | string, type: string) {
    if (type === 'price') {
      if (this.filtersService.priceLowValue === this.optionsForPrice.floor && this.filtersService.priceHighValue === this.optionsForPrice.ceil) {
        this.filters.price = [];
      } else {
        this.filters.price = [];
        this.filters.price.push(value);
      }
    } else if (type === 'time') {
      if (this.filtersService.timeLowValue === this.optionsForTime.floor && this.filtersService.timeHighValue === this.optionsForTime.ceil) {
        this.filters.timeLeft = [];
      } else {
        this.filters.timeLeft = [];
        this.filters.timeLeft.push(value);
      }
    }

    return this.isApplyButtonActive(by);
  }

  isApplyButtonActive(filter: string) {
    const prev = this.previousFilters[filter];
    const curr = this.filters[filter];

    if (prev.length != curr.length)
      return true;

    return curr.some(x => !prev.includes(x))
  }

  onApply() {
    this.onApplyClick.emit();
  }

  selectAll(firstItem: string, secondItem: string) {
    this.selectAllButton.emit({ firstItem, secondItem });
  }

  toggleFilter() {
    this.isOpen = !this.isOpen;
  }

  valueChangeOfPrice(event) {
    this.updateCountAfterFilterApplied.emit('Price');

    this.items.forEach((item) => {
      item.isChecked = false;
    })

    let filterBy = '' + event.value + '-' + event.highValue;
    this.filtersService.priceLowValue = event.value;
    this.filtersService.priceHighValue = event.highValue;

    this.items.unshift(new FilterItem(filterBy, true, filterBy));

    this.filterByForPriceAndTimeLeft(this.typeInString, this.items[0].filterBy, 'price');
    if (this.desktop) {
      this.onApply();
    } else {
      setTimeout(() => {
        this.onOptionClick.emit();
      })
    }
  }

  valueChangeOfTime(event) {
    this.updateCountAfterFilterApplied.emit('Time left');
    if (this.items[0].filterBy != '0-8') {
      this.items.shift();
    }
    this.items.forEach((item) => {
      item.isChecked = false;
    })

    let filterBy = '' + event.value + '-' + event.highValue;
    this.filtersService.timeLowValue = event.value;
    this.filtersService.timeHighValue = event.highValue;

    this.items.unshift(new FilterItem(filterBy, true, filterBy));

    this.filterByForPriceAndTimeLeft(this.typeInString, this.items[0].filterBy, 'time');
    if (this.desktop) {
      this.onApply();
    } else {
      setTimeout(() => {
        this.onOptionClick.emit();
      })
    }
  }

  valueChangeOfInputs(type: string) {
    this.updateCountAfterFilterApplied.emit(type);
    if (this.desktop) {
      setTimeout(() => {
        this.onApply();
      })
    } else {
      setTimeout(() => {
        this.onOptionClick.emit();
      })
    }
  }

  toggleFilterCollapse(name: string) {
    this.filterCollapse[name] = !this.filterCollapse[name];
    this.filtersService.updateHeightOfFilterContainer.next(true);
  }
}
