<div class="search-container">
    <input type="text" #myInput [placeholder]="placeholderText" [(ngModel)]="searchText"
        (input)="onInputChange($event.target.value)">
    <span class="search-icon">
        <!-- <i class="fa fa-search" aria-hidden="true"></i> -->
        <img src="../../../assets/img/icons/search-dark-gray.svg">
    </span>
    <span class="clear-icon" *ngIf="searchText" (click)="clearSearch()">
        <!-- <i class="fa fa-times" aria-hidden="true"></i> -->
        <img src="../../../assets/img/icons/cross.svg">
    </span>
</div>