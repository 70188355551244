import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-card-component',
  templateUrl: './card-component.component.html',
  styleUrls: ['./card-component.component.css']
})
export class CardComponentComponent implements OnInit {

  @Input('featuredFlights') featuredFlights: any;
  @Output('scrollToFlightAccardionFlight') scrollToFlightAccardionFlight: EventEmitter<void> = new EventEmitter();


  constructor() { }

  ngOnInit() {
  }

  scrollToFlightAccardion(flightId) {
    this.scrollToFlightAccardionFlight.emit(flightId);
  }
}
