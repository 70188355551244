<div class="global-person-choose">
  <img class="global-person-choose-icon" src="assets/img/person.png" alt="person icon">
  <button type="button" class="btn show-person-box" #toggle (click)="toggle.opened = !toggle.opened">
    <span class="reset">{{ passengerList.adult }} Adult<span class="reset" *ngIf="passengerList.adult>1">s</span>
    </span>
    <span class="reset" *ngIf="passengerList.children>0">, {{ passengerList.children }}
      <span class="reset" *ngIf="passengerList.children==1">Child</span>
      <span class="reset" *ngIf="passengerList.children>1">Children</span>
    </span>
    <span class="reset" *ngIf="passengerList.infants>0">, {{ passengerList.infants }} Infant<span class="reset" *ngIf="passengerList.infants>1">s</span>
    </span>
    <span [ngClass]="toggle.opened ? 'person-rotate' : ''" class="flaticon-agree"></span>
  </button>
  <div [ngClass]="toggle.opened ? 'person-box' : 'closed' ">
    <div class="passenger-input-container">
      <label class="left">Adults <span class="note">(12+ years)</span></label>
      <div class="passenger-input right">
        <p [class.disable]="!this.canDecrease('adults')" class="dec-passenger-button" (click)="decrease('adults')">
          <span>_</span>
        </p>
        <input type="text" name="adults" value="{{ passengerList.adult }}" readonly>
        <p [class.disable]="!this.canIncrease('adults')" class="dec-passenger-button" (click)="increase('adults')">
          <span class="plus">+</span>
        </p>
      </div>
    </div>
    <div class="clear"></div>
    <div class="passenger-input-container">
      <label class="left">Children <span class="note">(2-11 years)</span></label><span *ngIf="!isOneWay" class="star">*</span>
      <div class="passenger-input right">
        <p [class.disable]="!this.canDecrease('children')" class="dec-passenger-button" (click)="decrease('children')">
          <span>_</span>
        </p>
        <input type="text" name="children" value="{{ passengerList.children }}" readonly>
        <p [class.disable]="!this.canIncrease('children')" class="dec-passenger-button" (click)="increase('children')">
          <span class="plus">+</span>
        </p>
      </div>
    </div>
    <div class="clear"></div>
    <div class="passenger-input-container">
      <label class="left">Infants <span class="note">(Under 2 years)</span></label><span *ngIf="!isOneWay" class="star">*</span>
      <div class="passenger-input right">
        <p [class.disable]="!this.canDecrease('infants')" class="dec-passenger-button" (click)="decrease('infants')">
          <span>_</span>
        </p>
        <input type="text" name="infants" value="{{ passengerList.infants }}" readonly>
        <p [class.disable]="!this.canIncrease('infants')" class="dec-passenger-button" (click)="increase('infants')">
          <span class="plus">+</span>
        </p>
      </div>
    </div>
    <div *ngIf="!isOneWay" class="info"><span class="star">*</span>Age on date of inbound flight</div>
    <div class="clear"></div>
    <!-- <span class="person-box-close transition-all a" (click)="toggle.opened = !toggle.opened">Close</span> -->
  </div>
</div>