<div class="btn-group bootstrap-select left" [ngClass]="{open: opened && !isMobile}">
  <div (click)="toggle()">
    <button #dropdown_toggle_btn type="button" class="btn dropdown-toggle selectpicker btn-default flex-button">
      <img class="selectpicker-icon" src="assets/img/icons/airport-pin.svg" alt="position icon">
      <span #dropdown_toggle class="filter-option pull-left">{{selectedName}}</span>&nbsp;
      <span #flaticon_agree class="flaticon-agree yellow bold"></span>
    </button>
  </div>

  <ng-container *ngIf="opened && !isMobile">
    <div class="dropdown-menu">
      <ul class="dropdown-menu inner selectpicker" role="menu">
        <li *ngFor="let city of departureTree[0].children">
          <ul class="dropdown-menu inner selectpicker" role="menu">

            <!-- parent goes here -->
            <li (click)="selectDeparture(city)" [ngClass]="{selected: selected.code == city.code}">
              <a>
                <span class="text">{{city.children.length > 1 ? city.name + ' (All Airports)' : city.name}}
                  <small class="muted text-muted">({{city.flightsCount}})</small>
                </span>
                <span class="glyphicon glyphicon-ok flaticon-check74"></span>
              </a>
            </li>
            <!-- childs goes here -->
            <ng-container *ngIf="city.children.length > 1">
              <li (click)="selectDeparture(city,airport)" *ngFor="let airport of city.children"
                [ngClass]="{selected: selected.code == airport.code}">
                <a class="child">
                  <span class="text">{{city.name + ' ' + airport.name}}
                    <small class="muted text-muted">({{airport.flightsCount}})</small>
                  </span>
                  <span class="glyphicon glyphicon-ok flaticon-check74"></span>
                </a>
              </li>
            </ng-container>
          </ul>
        </li>
      </ul>
    </div>
  </ng-container>

  <ng-container *ngIf="opened && isMobile">
    <div class="dropdown-container" [@toggleAnimation]>
      <!-- <div class="close-btn"><img (click)="toggle()" alt="" src="../../../assets/img/icons/cross.svg"></div> -->
      <div class="main-dropdown">
        <div #dropdown_content class="dropdown-content" >
          <div *ngFor="let city of departureTree[0].children">
            <!-- Parent item -->
            <div (click)="selectDeparture(city)" class="all-airports">
              <span class="text mobile-text">{{city.children.length > 1 ? city.name + ' (All Airports)' : city.name}}
                <small class="muted text-muted">({{city.flightsCount}})</small>
              </span>
              <input type="radio" [checked]="selected.code == city.code" name="selected-code">
            </div>

            <!-- Child items -->
            <div *ngIf="city.children.length > 1" class="city-aiports">
              <div *ngFor="let airport of city.children" (click)="selectDeparture(city, airport)">
                <span class="text mobile-text">{{city.name + ' ' + airport.name}}
                  <small class="muted text-muted">({{airport.flightsCount}})</small>
                </span>
                <input type="radio" [checked]="selected.code == airport.code" name="selected-code">
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
</div>