import {
    Component,
    Renderer2,
    HostListener,
    OnInit,
    EventEmitter,
    Output,
    ElementRef,
    ViewChild,
    Input,
    TemplateRef,
    ContentChild,
    AfterViewChecked
} from '@angular/core';

import { Flight, toggle } from 'app/shared';
import { RestService } from 'app/services/rest/rest.service';

import * as moment from 'moment';
import { FlightManagerService } from 'app/services/flight-manager/flight-manager.service';
import { AlertService } from 'app/services/alert/alert.service';
declare const window: any;

@Component({
    selector: 'app-basket',
    templateUrl: './basket.component.html',
    styleUrls: ['./basket.component.css'],
    animations: [toggle]
})
export class BasketComponent implements OnInit, AfterViewChecked {

    @ContentChild('exp_date') exp_date: TemplateRef<any>;
    @ViewChild('box') boxContent: ElementRef;
    @ViewChild('global') globalCont: ElementRef;
    @Input('passengerPage') passengerPage: boolean;
    @Input('paymentPage') paymentPage: boolean;
    @Input('isOneWay') isOneWay: boolean;
    @Output('onShowFlightDetails') onShowFlightDetails: EventEmitter<void> = new EventEmitter();
    @Output('onShowPriceBreakdown') onShowPriceBreakdown: EventEmitter<void> = new EventEmitter();

    @HostListener('window:resize', ['$event'])
    onResize(event) {
        this.currentWindowWith = event.target.innerWidth;
        this.setupBoxPosition();
    }

    dataOfpassengerDetails: any;

    tabletWithSize: number = 1130;
    currentWindowWith: number;

    onScrollSubscription: any;
    showScrollToTopButton: boolean = false;
    fixedBox: boolean = false;

    flight: Flight;

    state: string = 'inactive';

    typeofTable: number;

    loading: boolean;
    mltPassangers: boolean = false;
    allTypeOfPassangers: boolean = false;

    totalDuration: moment.Duration = moment.duration(0, 'hours');
    totalDurationOfReturnFlights: moment.Duration = moment.duration(0, 'hours');
    runAfterViewCheckOnlyOneTime = 1;


    constructor(
        private renderer: Renderer2,
        private rest: RestService,
        private flightManager: FlightManagerService,
        private alertService: AlertService
    ) {
        this.currentWindowWith = window.innerWidth;
    }

    ngOnInit() {

        this.flightManager.flightSubject.subscribe((flight: Flight) => {
            this.flight = flight;
            this.getStop(flight);
            this.setupBoxPosition();
            if (flight.returnTickets && flight.returnTickets.length) {
                this.getStopOfReturnFlights(flight);
            }
        });

        this.getPriceBreakdown()
    }

    getPriceBreakdown() {
        if (this.passengerPage || this.paymentPage) {
            this.flightManager.passengerDetails.subscribe((data) => {
                if (data == null) {
                    return
                }
                this.dataOfpassengerDetails = data;

                this.typeofTable = this.flightManager.calcTypeOfTable(this.dataOfpassengerDetails);
                if(this.dataOfpassengerDetails.child?.count  || this.dataOfpassengerDetails.infant?.count) {
                    this.mltPassangers = true;
                    if(this.dataOfpassengerDetails.child?.count && this.dataOfpassengerDetails.infant?.count) {
                        this.allTypeOfPassangers = true;
                    }
                }

            }, (err) => {
                console.log(err)
            })
        }
    }

    ngAfterViewInit() {
        this.setupBoxPosition();
    }

    setupBoxPosition() {
        if (!this.boxContent){
            return;
        }
            
        const MIN_SCROL = 400;
        let rect = this.globalCont.nativeElement.getBoundingClientRect()
        if(this.fixedBox)
            this.boxContent.nativeElement.style.left = rect.left + 'px';

        if (this.onScrollSubscription)
            this.onScrollSubscription();
        this.onScrollSubscription = this.renderer.listen('window', 'scroll', event => {
            if (this.boxContent.nativeElement.clientHeight - 200 <= event.target.scrollingElement.scrollTop) {
                this.boxContent.nativeElement.style.left = rect.left + 'px';
                this.fixedBox = true;
            } else {
                this.fixedBox = false;
                this.boxContent.nativeElement.style.left = '0px';
            }
            if (document.body.scrollTop > MIN_SCROL || document.documentElement.scrollTop > MIN_SCROL) {
                return this.showScrollToTopButton = true;
            }
            this.showScrollToTopButton = false;
        });
    }

    showFlightDetails() {
        this.onShowFlightDetails.emit();
    }

    scrollTop() {
        window.scroll(0, 0);
    }

    ngOnDestroy() {
        if (this.onScrollSubscription)
            this.onScrollSubscription();
    }

    getStop(flight) {
        this.totalDuration = moment.duration(0, 'hours');
        flight.outboundTickets.map((ticket, index) => {
            this.totalDuration.add(ticket.duration);
            if (index > 0) {
                let previousTicket = flight.outboundTickets[index - 1];
                this.totalDuration.add(ticket.departure.date.diff(previousTicket.arrival.date));
            }
        })
    }

    getStopOfReturnFlights(returnFlight) {
        this.totalDurationOfReturnFlights = moment.duration(0, 'hours');
        returnFlight.returnTickets.map((ticket, index) => {
            this.totalDurationOfReturnFlights.add(ticket.duration);
            if (index > 0) {
                let previousTicket = returnFlight.returnTickets[index - 1];
                this.totalDurationOfReturnFlights.add(ticket.departure.date.diff(previousTicket.arrival.date));
            }
        })
    }

    ngAfterViewChecked() {
        if(this.runAfterViewCheckOnlyOneTime === 1 && (this.passengerPage || this.paymentPage)){
            if(this.boxContent) {
                this.setupBoxPosition();
                this.runAfterViewCheckOnlyOneTime = 2;
            }
        }
    }
}
