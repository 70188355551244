<div class="flight-leg-wrapper">

    <div class="col-lg-12 col-md-10 col-sm-15 col-xs-15 col graph">
        <div class="row">
            <!-- <div class="col-lg-5 col-md-5 col-sm-4 col-xs-4 col icon">
                <div class="wrapp">
                    <img src="{{isFirst ? 'assets/img/icons/ascent-2.svg' : 'assets/img/icons/ascent-3.svg'}}" alt="img">
                </div>
            </div> -->
            <div class="col-lg-8 col-md-8 col-sm-6 col-xs-6 col left-text">
                <p class="left-text-p-top">
                    <span class="mobile" [ngClass]="isFirst ? 'strong' : '' "> {{ticket.departureDate |
                        amDateFormat:'HH:mm'}} </span>
                    <span class="desktop" [ngClass]="isFirst ? 'strong' : '' "> {{ticket.departureDate |
                        amDateFormat:'HH:mm'}} </span><br>
                    <span class="reset reset-all for_desktop"> {{ticket.departureDate | amDateFormat:'ddd DD MMM'}}
                    </span>
                    <span class="reset reset-all for_mobile"> {{ticket.departureDate | amDateFormat:'ddd DD MMM'}}
                    </span>

                </p>
            </div>
            <div class="col-lg-5 col-md-5 col-sm-6 col-xs-4 col timeline before-clock">
                <div class="wrapp">
                    <div class="dot dot_top" [ngClass]="isFirst ? 'full' : '' "><img
                            src="{{isFirst ? 'assets/img/icons/ascent-2.svg' : 'assets/img/icons/ascent-3.svg'}}"
                            alt="img"></div>
                </div>
                <div class="wrapp">
                    <div class="line"></div>
                </div>
            </div>
            <div class="col-lg-11 col-md-11 col-sm-12 col-xs-14 col right-text">
                <p class="right-text-p-top" *ngIf="isFirst">
                    <strong>{{ticket.departurePoint.city}} <span class="brackets"> (<span class="airport-code"
                                [ngClass]="highlightDeparture ? 'different' : '' ">{{ticket.departurePoint.code}}</span>)</span></strong>
                    <br>
                    <span
                        [ngClass]="[isFirst ? 'airport-name' : 'name' , highlightDeparture ? 'different' : '' ]">{{ticket.departurePoint.name}}
                    </span>
                    <!-- <span class="airport-code" [ngClass]="highlightDeparture ? 'different' : '' ">{{'(' + ticket.departure.code + ')'}}</span> -->
                    <span class="color1 nowrap terminal terminal-long" *ngIf="ticket.departureTerminal"> Terminal
                        {{ticket.departureTerminal}}</span>
                    <span class="color1 nowrap terminal terminal-short" *ngIf="ticket.departureTerminal"> T.
                        {{ticket.departureTerminal}}</span>
                </p>
                <p class="right-text-p-top" *ngIf="!isFirst">
                    <span> {{ticket.departurePoint.city}} </span> <span
                        class="airport-code">{{' (' + ticket.departurePoint.code + ')'}}</span>
                    <br>
                    <span class="name"> {{ticket.departurePoint.name}} </span>
                    <!-- <span class="airport-code">{{'(' + ticket.departure.code + ')'}}</span> -->
                    <span class="color1 nowrap terminal terminal-long" *ngIf="ticket.departureTerminal"> Terminal
                        {{ticket.departureTerminal}}</span>
                    <span class="color1 nowrap terminal terminal-short" *ngIf="ticket.departureTerminal"> T.
                        {{ticket.departureTerminal}}</span>
                </p>
            </div>
        </div>

        <div class="row middle-row">
            <div class="col-lg-8 col-md-8 col-sm-6 col-xs-6 col left-clock">
                <div>
                    <span class="color1 time">
                        <img class="clock-img" src="assets/img/icons/timer-icon.svg" alt="img">
                        <span>
                            {{ticket.duration | momentDiff: 'shortened'}}
                        </span>
                    </span>
                </div>
            </div>
            <div class="col-lg-5 col-md-5 col-sm-6 col-xs-6 col timeline clock">
                <div class="departure-plane">
                    <img src="assets/img/icons/departure2-yellow.svg" alt="img">
                </div>
            </div>
            <!-- <div class="col-lg-11 col-md-11 col-sm-12 col-xs-12 col moon" >
                <div>
                    <img class="moon-img" src="assets/img/icons/moon-normal-gray.png" alt="img">
                    <p>Overnight flight</p>
                </div>
            </div> -->
            <div class="col-lg-11 col-md-11 col-sm-12 col-xs-12 col airline-logo-section" >
                <div class="airline-icon">
                    <div *ngIf="nightOver">
                        <img class="moon-img" src="assets/img/icons/moon-normal-gray.png" alt="img">
                        <p class="for_desktop overnight">Overnight flight</p>
                    </div>
                    <img src="{{ticket.airline.name ? imgUrl+ticket.airline.airlineLogo : 'assets/img/airline-logos/undisclosed.png'}}"
                        alt="img">
                </div>

            </div>
            <!-- <div class="col-lg-11 col-md-11 col-sm-12 col-xs-12 col additional"> -->
            <!-- <img class="mob-show" src="{{ticket.airline.name ? imgUrl+ticket.airline.airlineLogo : 'assets/img/airline-logos/undisclosed.png'}}" -->
            <!-- alt="img"> -->
            <!-- <span class="color1 mob-show nowrap hide">
                    {{ticket.airline.name ? ticket.airline.name : 'Airline not disclosed'}}
                    <br>
                    <span>{{ticket.flightNumber}}</span>
                    <br>
                </span> -->
            <!-- </div> -->
        </div>
        <div class="row bottom-row">
            <!-- <div class="col-lg-5 col-md-5 col-sm-4 col-xs-4 col icon">
                <div class="wrapp">
                    <img src="{{isLast ? 'assets/img/icons/arrival-2.svg' : 'assets/img/icons/arrival-3.svg'}}" alt="img">
                </div>
            </div> -->
            <div class="col-lg-8 col-md-8 col-sm-6 col-xs-6 col left-text">
                <p class="left-text_bottom">
                    <span class="mobile" [ngClass]="isLast ? 'strong' : '' "> {{ticket.destinationDate |
                        amDateFormat:'HH:mm'}} </span>
                    <span class="desktop" [ngClass]="isLast ? 'strong' : '' "> {{ticket.destinationDate |
                        amDateFormat:'HH:mm'}} </span><br>
                    <span class="reset reset-all for_desktop"> {{ticket.destinationDate | amDateFormat:'ddd DD MMM'}}
                    </span>
                    <span class="reset reset-all for_mobile"> {{ticket.destinationDate | amDateFormat:'ddd DD MMM'}}
                    </span>

                </p>
            </div>
            <div class="col-lg-5 col-md-5 col-sm-6 col-xs-4 col timeline">
                <div class="wrapp bottom_wrap">
                    <div class="dot dot_bottom" [ngClass]="isLast ? 'full' : '' "><img
                            src="{{isLast ? 'assets/img/icons/arrival-2.svg' : 'assets/img/icons/arrival-3.svg'}}"
                            alt="img"></div>
                </div>
                <div *ngIf="!isLast" class="wrapp">
                    <div class="line wait"></div>
                </div>
            </div>
            <div class="col-lg-11 col-md-11 col-sm-12 col-xs-14 col right-text">
                <p class="left-text_bottom" *ngIf="!isLast">
                    {{ticket.destinationPoint.city}} <span class="airport-code">{{' (' + ticket.destinationPoint.code + ')'}}</span>
                    <br>
                    <span class="name"> {{ticket.destinationPoint.name}} </span>
                    <!-- <span class="airport-code">{{'(' + ticket.arrival.code + ')'}}</span> -->
                    <span class="color1 nowrap terminal terminal-long" *ngIf="ticket.arrivalTerminal"> Terminal
                        {{ticket.arrivalTerminal}}</span>
                    <span class="color1 nowrap terminal terminal-short" *ngIf="ticket.arrivalTerminal"> T.
                        {{ticket.arrivalTerminal}}</span>
                </p>
                <p class="left-text_bottom" *ngIf="isLast">
                    <strong> {{ticket.destinationPoint.city}} <span class="brackets"> (<span class="airport-code"
                                [ngClass]="highlightArrival ? 'different' : ''">{{ticket.destinationPoint.code}}</span>)</span></strong>
                    <br>
                    <span class="airport-name" [ngClass]="highlightArrival ? 'different' : ''"> {{ticket.destinationPoint.name}}
                    </span>
                    <!-- <span class="airport-code" [ngClass]="highlightArrival ? 'different' : ''">{{'(' + ticket.arrival.code + ')' }}</span> -->
                    <span class="color1 nowrap terminal terminal-long" *ngIf="ticket.arrivalTerminal"> Terminal
                        {{ticket.arrivalTerminal}}</span>
                    <span class="color1 nowrap terminal terminal-short" *ngIf="ticket.arrivalTerminal"> T.
                        {{ticket.arrivalTerminal}}</span>
                </p>
            </div>
        </div>
    </div>

    <div class="col-lg-12 col-md-14 col-sm-9 col-xs-9 col details">
        <div class="flex">
            <!-- <div class="col-lg-5 col-md-5 col-sm-3 col-xs-3 col airline-icon">
                <img src="{{ticket.airline.name ? imgUrl+ticket.airline.airlineLogo : 'assets/img/airline-logos/undisclosed.png'}}"
                    alt="img">
            </div> -->
            <div class="col-lg-24 col-md-24 col-sm-24 col-xs-24 col leg-info">
                <ul class="info info-mobile">
                    <li>
                        <div>
                            <strong>{{ticket.airline.name ? ticket.airline.name : 'Airline undisclosed'}}</strong>
                            <app-tooltip class="not-disclosed" *ngIf="!ticket.airline.name" [displayPosition]="'right'"
                                [width]="350">
                                Airline will be disclosed once booking is confirmed
                            </app-tooltip>
                            <!-- <span class="put-in-another-line"></span> -->
                        </div>
                    </li>

                    <li *ngIf="ticket.flightNumber">
                        <span class="flight-number">
                            <span class="cabin-aircraft 
                            for_desktop">Flight:</span> {{ticket.flightNumber}}
                            <span class="info-small-for-desktop for_desktop"
                                *ngIf="ticket.operatedByAirline && ticket.operatedByAirline.name && ticket.airline.name && ticket.operatedByAirline.name != ticket.airline.name">
                                <span class="operated-by" [title]="operatedByShortText.length ? ticket.operatedByAirline.name: '' ">
                                    Operated by {{ operatedByShortText.length ? operatedByShortText: ticket.operatedByAirline.name }}
                                </span>
                            </span>
                        </span>
                    </li>
                    <li *ngIf="ticket.operatedByAirline && ticket.operatedByAirline.name && ticket.airline.name && ticket.operatedByAirline.name != ticket.airline.name"
                        class="for_mobile">
                        <span class="info-small">
                            <span class="operated-by">
                                Operated by {{ ticket.operatedByAirline.name }}
                            </span>
                        </span>
                    </li>

                    <li *ngIf="ticket.aircraft"><span class="cabin-aircraft for_desktop">Aircraft: </span><span>
                            {{ticket.aircraft}}</span></li>
                    <li><span class="cabin-aircraft for_desktop">Cabin: </span><span> {{ticket.cabin}}</span></li>
                    <!-- this li is just to make empty row -->
                    <li></li>
                    <li></li>
                    <li *ngIf="ticket.baggage" class="bag">
                        <app-baggage-info [ngClass]="highlightBag ? 'different' : ''" [baggage_items]="ticket.baggage.items"></app-baggage-info>
                        
                        <app-tooltip [hovered]="hovered" [baggage_items]="ticket.baggage.items" [baggage_section]="true" [displayPosition]="'right'"
                            [width]="350">
                            <ng-container [ngSwitch]="typeOfBag">
                                
                                <ng-container *ngSwitchCase="1">
                                    <p [innerHTML]="ticket.baggage.longDescription"></p>
                                </ng-container>

                                <ng-container *ngSwitchCase="2">
                                    <p [innerHTML]="ticket.baggage.longDescription"></p>
                                </ng-container>

                                <ng-container *ngSwitchCase="3">
                                    <p [innerHTML]="ticket.baggage.longDescription"></p>
                                </ng-container>

                            </ng-container>
                        </app-tooltip>
                    </li>
                </ul>
            </div>
        </div>
    </div>
    <div class="fare-rules" *ngIf="isLast" (click)="fareRulesIsOpen = !fareRulesIsOpen">
        <span><a>Ticket conditions</a></span>
        <div class="toggle-icon" [ngClass]="fareRulesIsOpen === true ? 'rotate': '' ">
            <i class="flaticon-agree"></i>
          </div>
    </div>
</div>
<div class="fare-rules-text" *ngIf="fareRulesIsOpen">
    <p class="fare-rules-title">Ticket conditions</p>
    <ng-templete>
        <p [innerHTML]="fareRules | addNewLine">
        </p>
    </ng-templete>
</div>


<div *ngIf="nextTicket" class="stop">
    <div class="col-lg-12 col-md-10 col-sm-15 col-xs-15 col graph">

        <div class="row">
            <div class="col-lg-8 col-md-8 col-sm-6 col-xs-6 col left-clock">
                <div>
                    <span class="color1 time">
                        <img class="clock-img" src="assets/img/icons/timer-icon.svg" alt="img">
                        <span class="unvisible">
                            {{nextTicket.departureDate | amDifference: ticket.destinationDate : 'minutes' |
                            amDifferenceCustom: 'minutes': 'showLeadingZero'}}
                        </span>
                    </span>
                </div>
            </div>
            <div class="col-lg-5 col-md-5 col-sm-6 col-xs-4 col timeline before-clock"></div>
            <div class="col-lg-11 col-md-11 col-sm-12 col-xs-14 col right-text">
                <p>
                    <span class="color1 color1-stop">Stop {{' (in ' + ticket.destinationPoint.code + ')' }} </span>
                    <span class="overnight-stop" *ngIf="nightOvertStop">
                        <img class="moon-img" src="assets/img/icons/moon-normal-gray.png" alt="img">
                        <span class="overnight">Overnight stopover</span>
                        <app-tooltip [hovered]="hovered" [displayPosition]="'right'" [width]="350">Please note that the
                            stopover is overnight
                            and your trip continues the next day. Should you need accommodation it will be at your own
                            expense.
                        </app-tooltip>
                    </span>
                </p>
            </div>
        </div>
    </div>
</div>