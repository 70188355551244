import { Component, EventEmitter, Output, Input, OnInit } from '@angular/core';
import { TableHeaderService } from 'app/components/table-header/table-header.service';
import { FlightAccordionService } from 'components/flight-accordion/flight-accordion.service';

import { Subscription } from 'rxjs';

@Component({
    selector: 'app-table-header',
    templateUrl: './table-header.component.html',
    styleUrls: ['./table-header.component.css']
})
export class TableHeaderComponent implements OnInit {
    @Output('onSort') onSort: EventEmitter<number> = new EventEmitter();
    @Input('isActive') isActive: boolean;
    @Input('cssClasses') cssClasses: string;
    @Input('showArrows') showArrows: boolean;

    order: number;
    
    id: string;
    subscription: Subscription;

    constructor(
        private tableHeaderService: TableHeaderService,
        private accordionService: FlightAccordionService
    ) {
        this.id = Math.random() + '' + Math.random();
        this.subscription = this.tableHeaderService.onClick.subscribe((headerId) => {
            if(this.id != headerId){
                this.isActive = false;
                this.order = null;
            }
        })
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }

    ngOnInit() {
        if(this.isActive)
            this.flip();
    }

    

    flip(){
        this.accordionService.reset();
        this.isActive = true;
        if(!this.order) this.order = -1;
        this.order = -1 * this.order;
        this.onSort.emit(this.order)

        this.tableHeaderService.onClick.emit(this.id);
        
    }

}
