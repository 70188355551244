
import {map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { RestService } from 'services/rest/rest.service';
import { ApiSession, GeoData, Coordinates } from 'app/shared';
import { Observable } from 'rxjs';
import { ActivatedRoute } from '@angular/router';

@Injectable()
export class ApiSessionService {

    private readonly BROWSER_ID_KEY = "bid";
    private readonly SESSIONS_ID_KEY = "sid";

    private sessionId: string = null;
    private browserId: string = null;

    public _geoData: GeoData;
    public get geoData(): GeoData {
        return this._geoData;
    }

    private _defaultCityCode: string;
    public get defaultCityCode(): string {
        return this._defaultCityCode;
    }

    constructor(private restService: RestService, private activatedRoute: ActivatedRoute) {
        this._geoData = new GeoData();
    }

    init() {
        this.tryGetGeoDataFromURL();

        // if (!this._geoData.coordinates)
        //     this.tryGetGeoData().toPromise().then(()=>{ return this.initApiSession() });


       // return this.initApiSession();
    }

    initApiSession() {
        this.browserId = localStorage.getItem(this.BROWSER_ID_KEY);
        this.sessionId = sessionStorage.getItem(this.SESSIONS_ID_KEY);

        let timeZoneOffset = new Date().getTimezoneOffset();
        timeZoneOffset = timeZoneOffset * Math.sign(timeZoneOffset);

        return this.restService.initApiSession(this.browserId, timeZoneOffset, this._geoData.coordinates, this._geoData.countryCode).pipe(
            map((result) => {
                this.storeBrowserId(result.browserId);
                this.storeSessionId(result.sessionId);
                this.restService.setSessionId(this.sessionId);
            }, (error) => { console.log(error) }))
    }

    tryGetGeoData() {
        return this.restService.getGeolocationData().pipe(
        map((result) => {
            this._geoData = result;
        }));
    }

    tryGetGeoDataFromURL() {
        let lat = this.getParameterByName("lat");
        let long = this.getParameterByName("long");
        let countyCode = this.getParameterByName("countryCode");

        if (lat && long && countyCode) {
            this._geoData.coordinates = new Coordinates({ latitude: Number.parseFloat(lat), longitude: Number.parseFloat(long) });
            this._geoData.countryCode = countyCode;
        }
    }

    getBrowserId(): string {
        return this.browserId;
    }

    storeBrowserId(value: string) {
        if (!value) return;
        localStorage.setItem(this.BROWSER_ID_KEY, value);
        this.browserId = value;
    }

    getSessionId(): string {
        return this.sessionId;
    }

    storeSessionId(value: string) {
        if (!value) return;
        sessionStorage.setItem(this.SESSIONS_ID_KEY, value);
        this.sessionId = value;
    }

    getParameterByName(name) {
        let url = window.location.href;
        name = name.replace(/[\[\]]/g, '\\$&');
        var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
            results = regex.exec(url);
        if (!results) return null;
        if (!results[2]) return '';
        return decodeURIComponent(results[2].replace(/\+/g, ' '));
    }
}