<!-- <div #dApplyButton *ngIf="desktop === false">
  <button type="button" class="btn show-filters-box" id="{{name}}"  (click)="toggleFilter()">
    {{name}}
    <span [ngClass]="{'filter-rotate':isOpen}" class="flaticon-agree"></span>
    <span [ngClass]="{'show-toggle': filter_length}" class="toggle">
      <span class="glyphicon glyphicon-ok"></span>
    </span>
  </button>
  <div *ngIf="isOpen" [@toggleAnimationFilter] id="destination-sort" class="filters-box filter-destination {{name}}">
    <div class="filters-box-nav clearfix" *ngIf="(hasSelectAndClearField && !mobile) || (hasSelectAndClearField && mobile && items.length > 3)">
      <button [ngClass]="filter_length ? 'btn select-all' : 'btn select-all disable'" (click)="selectAll(optionItems,typeInString); dApplyButton.isActive = isApplyButtonActive(typeInString)"
        type="button">select all</button>
      <button [ngClass]="filter_length != items.length ? 'btn clear-all' : 'btn clear-all disable'"
        (click)="clearAll(optionItems,typeInString); dApplyButton.isActive = isApplyButtonActive(typeInString)"
        type="button">clear all</button>
      <div class="clear"></div>
    </div>

    <div class="filters-box-scroll" [ngClass]="hasSelectAndClearField == false || (mobile && items.length < 4) ? 'no-search' : ' ' ">
        <span *ngIf ="name=='Stops' || name == 'Destination'" class="price-from">From</span>
      <ul class="list" >
        <li *ngFor="let i = index; let item of items" (change)="dApplyButton.isActive = filterBy(typeInString, item.filterBy)">
          <input [(ngModel)]="item.isChecked" type="checkbox" id="{{name}}-{{i}}">
          <label class="sort-name" for="{{name}}-{{i}}">
            <span></span><span *ngIf="name=='Price'" class="pound">&#163;</span>{{item.value}} <div *ngIf="item.count || item.count === 0" class="number">({{item.count}})</div></label>
            <span *ngIf="name=='Stops' || name=='Destination'" class="price">&#163;{{item.secondText | priceRound}}</span>
        </li>
      </ul>

    </div>
    <div *ngIf="!dApplyButton.isActive" (click)="toggleFilter()" class="submit-wrapp">
      <input class="transition-all apply-filter" type="submit" value="Close">
    </div>
    <div *ngIf="dApplyButton.isActive" class="submit-wrapp" (click)="onApply(); isOpen = false; dApplyButton.isActive = false">
      <input class="transition-all apply-filter active" type="submit" value="Apply">
    </div>

  </div>
</div> -->

<div class="filters-box filters-box-desktop" [ngClass]="{'first-box': name == 'Departure Time'}">
  <div class="name-section" (click)="toggleFilterCollapse(name)">
    <div>
      <span class="name-item" *ngIf="name == 'Departure Time' && items.length">{{name}}</span>
      <span class="name-item" *ngIf="name == 'Stops' && items.length">{{name}}</span>
      <span class="name-item" *ngIf="name == 'Airline' && items.length">{{name}}</span>
      <span class="name-item" *ngIf="name == 'Price' && desktop && items.length && (length_of_flights > 1 || typeOfAppliedFilter === 'Price')">{{name}}</span>
      <span class="name-item" *ngIf="name == 'Price' && !desktop && items.length">{{name}}</span>
      <span class="name-item" *ngIf="name == 'Time left' && desktop && items.length && (length_of_flights > 1 || typeOfAppliedFilter === 'Time left')">{{name}}</span>
      <span class="name-item" *ngIf="name == 'Time left' && !desktop && items.length">{{name}}</span>
      <span class="name-item" *ngIf="name == 'Destination' && items.length">World area</span>

      <span class="filters-count" *ngIf="filter_length && !filterCollapse[name]">({{filter_length}} applied)</span>
    </div>
    
    <i *ngIf="!filterCollapse[name]" class="flaticon-agree"></i>
    <i *ngIf="filterCollapse[name]" class="flaticon-agree rotate"></i>
  </div>
  <div *ngIf="(name === 'Destination' || name === 'Stops') && filterCollapse[name]" class="from-text">
    From
  </div>
  <ul class="list" [ngClass]="items.length <= 1 ? 'short-list' : 'large-list'" *ngIf="name == 'Departure Time' && items.length  && filterCollapse[name]">
    <li *ngFor="let i = index; let item of items">
      <input [(ngModel)]="item.isChecked" type="checkbox" id="{{name}}-{{i}}" (click)="valueChangeOfInputs(name)" (change)="filterBy(typeInString, item.filterBy)">
      <label class="sort-name" for="{{name}}-{{i}}">
          <span class="input"> </span>{{item.value}} <div *ngIf="item.count || item.count === 0" class="number">({{item.count}})</div>
      </label>
    </li>
  </ul>
  <ul class="list" *ngIf="name == 'Destination' && filterCollapse[name]">
    <li *ngFor="let i = index; let item of items">
        <input [(ngModel)]="item.isChecked" (click)="valueChangeOfInputs(name)" (change)="filterBy(typeInString, item.filterBy)" type="checkbox" id="{{name}}-{{i}}">
          <label class="sort-name" for="{{name}}-{{i}}">
         <span class="input"></span>{{item.value}} <div *ngIf="item.count || item.count === 0" class="number">({{item.count}})</div></label>
          <span class="price">&#163;{{item.secondText | priceRound}}</span>
    </li>
  </ul>
  <ul class="list" *ngIf="name === 'Stops' && filterCollapse[name]">
    <li *ngFor="let i = index; let item of items">
        <input [(ngModel)]="item.isChecked" (click)="valueChangeOfInputs(name)" (change)="filterBy(typeInString, item.filterBy)" type="checkbox" id="{{name}}-{{i}}">
          <label class="sort-name" for="{{name}}-{{i}}">
         <span class="input"></span>{{item.value}} <div *ngIf="item.count || item.count === 0" class="number">({{item.count}})</div></label>
          <span class="price">&#163;{{item.secondText | priceRound}}</span>
    </li>
  </ul>
  <ul class="list" *ngIf="name == 'Airline' && items.length  && filterCollapse[name]">
    <li *ngFor="let i = index; let item of items">
      <input [(ngModel)]="item.isChecked" type="checkbox" id="{{name}}-{{i}}" (click)="valueChangeOfInputs(name)" (change)="filterBy(typeInString, item.filterBy)">
      <label class="sort-name" for="{{name}}-{{i}}">
          <span class="input"> </span>{{item.value}} <div *ngIf="item.count || item.count === 0" class="number">({{item.count}})</div>
      </label>
    </li>
  </ul>

  <div *ngIf="name == 'Price' && filterCollapse[name] && desktop && optionsForPrice.floor && (length_of_flights > 1 || typeOfAppliedFilter === 'Price')" class="price-container">
    <div class="slider">
      <ngx-slider [(value)]="filtersService.priceLowValue" [(highValue)]="filtersService.priceHighValue" [options]="optionsForPrice" (userChangeEnd)="valueChangeOfPrice($event)"></ngx-slider>
    </div>
  </div>

  <div *ngIf="name == 'Price' && filterCollapse[name] && !desktop && optionsForPrice.floor" class="price-container">
    <div class="slider">
      <ngx-slider [(value)]="filtersService.priceLowValue" [(highValue)]="filtersService.priceHighValue" [options]="optionsForPrice" (userChangeEnd)="valueChangeOfPrice($event)"></ngx-slider>
    </div>
  </div>

  <div *ngIf="name == 'Time left' && filterCollapse[name] && !desktop && optionsForTime.floor >= 0" class="time-left-container">
    <div class="slider">
      <ngx-slider [(value)]="filtersService.timeLowValue" [(highValue)]="filtersService.timeHighValue" [options]="optionsForTime" (userChangeEnd)="valueChangeOfTime($event)"></ngx-slider>
    </div>
  </div>

  <div *ngIf="name == 'Time left' && filterCollapse[name] && desktop && optionsForTime.floor >= 0 && (length_of_flights > 1 || typeOfAppliedFilter === 'Time left')" class="time-left-container">
    <div class="slider">
      <ngx-slider [(value)]="filtersService.timeLowValue" [(highValue)]="filtersService.timeHighValue" [options]="optionsForTime" (userChangeEnd)="valueChangeOfTime($event)"></ngx-slider>
    </div>
  </div>
</div>