import { Component, OnInit, Input, ChangeDetectorRef, EventEmitter } from '@angular/core';

import { Booker } from 'app/shared';
import { RestService } from 'services/rest/rest.service';
import { HttpClient } from '@angular/common/http';

export interface Option {
    value: any;
    label: any;
}


@Component({
    selector: 'app-booker',
    templateUrl: './booker.component.html',
    styleUrls: ['./booker.component.css']
})
export class BookerComponent implements OnInit {
    @Input('booker') booker: Booker;
    @Input('form') form: any;
    @Input('formSubmitAttempt') formSubmitAttempt: boolean;

    dialCodes: Option[] = [];
    countries: Option[] = [];

    codesLoaded: boolean = false;

    constructor(
        private http: HttpClient,
        private cdr: ChangeDetectorRef,
        private restService: RestService
    ) {
        this.cdr.detach();
    }

    ngOnInit() {   
        this.restService.getCountryList().subscribe((countryCodes)=>{
            this.codesLoaded = true;
            this.countries = countryCodes.map((countryCode) => {
                this.dialCodes.push({
                    value: countryCode.dialCode,
                    label: `${countryCode.name} (${countryCode.dialCode})`
                })
                return {
                    value: countryCode.code,
                    label: countryCode.name
                }
            })
            this.cdr.reattach();
            this.cdr.detectChanges();
        })
    }
}
