<!-- <table>
    <tbody>
        <tr class="first">
            <th>
                <p class="left-section">Fare ({{paxType}}):</p>
            </th>
            <td class="amount-col">
                <p>
                    £{{data.fare  | roundCents}}
                </p>
            </td>
            <td class="count-col">
                <p>
                    (x{{data.count}})
                </p>
            </td>
        </tr>
        <tr>
            <th>
                <p class="left-section">Taxes:</p>
            </th>
            <td class="amount-col">
                <p>
                    £{{data.taxes  | roundCents}}
                </p>
            </td>
            <td class="count-col">
                <p>
                    (x{{data.count}})
                </p>
            </td>
        </tr>

        <tr>
            <th>
                <p class="left-section">Service Fee:</p>
            </th>
            <td class="amount-col">
                <p>
                    £{{data.serviceFee | roundCents}}
                </p>
            </td>
            <td class="count-col">
                <p>
                    (x{{data.count}})
                </p>
            </td>
        </tr>
        <tr class="last">
            <th>
                <p class="bold left-section">Total:</p>
            </th>
            <td class="amount-col">
                <p class="bold">
                    <app-price [value]="data.subTotal"></app-price>
                </p>
            </td>
            <td class="count-col" *ngIf="show_pp">
                <p>
                    (<app-price [value]="data.total"></app-price> p.p.)
                </p>
            </td>
        </tr>
    </tbody>
</table> -->

<div class="main-cont" [ngSwitch]="typeOfTable">

    <ng-templete *ngSwitchCase="1" class="main">
        <div class="type">
            <p>{{paxType}} Fare (p.p.):</p>
            <p>Taxes (p.p.):</p>
            <p>Discount (p.p.):</p>
            <p>Subtotal (p.p.):</p>
            <p>Pax No.:</p>
            <p [ngClass]="{'bold':grandTotal === true}">Total:</p>
            <!-- <p *ngIf="grandTotal != undefined">Grand Total:</p> -->
        </div>
        <div class="price">
            <p><app-price [value]="data.fare"></app-price></p>
            <p><app-price [value]="data.taxes"></app-price></p>
            <p><app-price [value]="data.discount"></app-price></p>
            <p><app-price [value]="data.subTotal"></app-price></p>
            <p>{{data.count}}</p>
            <p [ngClass]="{'bold':grandTotal === true}"><app-price [value]="data.total"></app-price></p>
            <!-- <p *ngIf="grandTotal != undefined"><app-price [value]="grandTotal"></app-price></p> -->
        </div>
    </ng-templete>


    <ng-templete *ngSwitchCase="2" class="main">
        <div class="type">
            <p>{{paxType}} Fare (p.p.):</p>
            <p>Taxes (p.p.):</p>
            <p>Service Fee (p.p.):</p>
            <p>Subtotal (p.p.):</p>
            <p>Pax No.:</p>
            <p>Total:</p>
            <!-- <p *ngIf="grandTotal != undefined">Grand Total:</p> -->
        </div>
        <div class="price">
            <p><app-price [value]="data.fare"></app-price></p>
            <p><app-price [value]="data.taxes"></app-price></p>
            <p><app-price [value]="data.serviceFee"></app-price></p>
            <p><app-price [value]="data.subTotal"></app-price></p>
            <p>{{data.count}}</p>
            <p><app-price [value]="data.total"></app-price></p>
            <!-- <p *ngIf="grandTotal != undefined"><app-price [value]="grandTotal"></app-price></p> -->
        </div>
    </ng-templete>

    <ng-templete *ngSwitchCase="3" class="main">
        <div class="type">
            <p>{{paxType}} Price (p.p.):</p>
            <p>Taxes (p.p.):</p>
            <p>Subtotal (p.p.):</p>
            <p>Pax No.:</p>
            <p>Total:</p>
            <!-- <p *ngIf="grandTotal != undefined">Grand Total:</p> -->
        </div>
        <div class="price">
            <p><app-price [value]="data.price"></app-price></p>
            <p><app-price [value]="data.taxes"></app-price></p>
            <p><app-price [value]="data.subTotal"></app-price></p>
            <p>{{data.count}}</p>
            <p><app-price [value]="data.total"></app-price></p>
            <!-- <p *ngIf="grandTotal != undefined"><app-price [value]="grandTotal"></app-price></p> -->
        </div>
    </ng-templete>
</div>