import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'addNewLine'
})
export class AddNewLine implements PipeTransform {

    transform(value: string) {
        let replacedString: string = '';
        if (value) {
            try {
                replacedString = value.replace(/\n/g, '<br>');
                replacedString = replacedString.replace(/\r\n/g, "<br>");
                replacedString = replacedString.replace(/\r/g, "<br>");

                // delete new lines at the end of the string 
                replacedString = replacedString.replace(/(<br\s*\/?>\s*)+$/, '');
                
                // replacedString = replacedString.replace(/#/g, "");
                // replacedString = replacedString.replace(/-/g, "");

            } catch (error) {
                throw new Error(error);
            }
        }

        return replacedString
    }
}
