import { Injectable, EventEmitter, OnDestroy } from '@angular/core';
import { Subject, from, Subscription } from 'rxjs';
import * as moment from 'moment';

import { ClockService } from 'services/clock/clock.service';
import { RestService } from 'services/rest/rest.service';
import { Router } from '@angular/router';

@Injectable()

export class SessionExpirationService implements OnDestroy {

    sessionWillExpireFrom: any;
    timeRemaining: moment.Duration;
    listenToRemaningTime$ = new Subject();
    checkingTheFlight: EventEmitter<number> = new EventEmitter();
    subscribtionForRouter: Subscription;

    constructor(private clockService: ClockService,
        private restService: RestService,
        private router: Router) {}


    checkSessionExpiration() {
        this.setExpirationTime();
        this.clockService.tick.subscribe(() => {
            if (this.router.url.includes('/payment') || this.router.url.includes('/passenger')) {
                this.timeRemaining = moment.duration(this.sessionWillExpireFrom.diff(moment()));
                this.listenToRemaningTime$.next(this.timeRemaining);

                if (this.timeRemaining.minutes() === 0 && this.timeRemaining.seconds() === 0) {
                    this.checkingTheFlight.emit();
                }
            }
            else {
                if(this.timeRemaining != null)
                    this.timeRemaining = null;
            }
        })

    }

    setExpirationTime() {

        if (this.timeRemaining && this.timeRemaining.minutes() >= 0 && this.timeRemaining.seconds() > 0) {
            this.sessionWillExpireFrom = moment().add(this.timeRemaining.asMinutes(), 'minutes');
        } else {
            let sessionRemainingTime = 20;
            this.sessionWillExpireFrom = moment().add(sessionRemainingTime, 'minutes');
        }

    }

    ngOnDestroy() {
        this.clockService.tick.unsubscribe();
    }
}