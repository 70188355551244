import { Component, Renderer2, ViewChild, EventEmitter, ElementRef, Input, OnChanges, Output, HostListener } from '@angular/core';
import { BaggageItem } from '../../shared/index';

@Component({
    selector: 'app-tooltip',
    templateUrl: './tooltip.component.html',
    styleUrls: ['./tooltip.component.css']
})
export class TooltipComponent implements OnChanges {
    @ViewChild('icon') icon: ElementRef;
    @ViewChild('tooltipBox') tooltipBox: ElementRef;

    @Output('onHideTooltip') onHideTooltip: EventEmitter<void> = new EventEmitter();

    @Input('displayPosition') displayPosition: string;
    @Input('width') width: number;
    @Input('hovered') hovered: boolean;
    @Input('departureBoard') departureBoard: boolean = false;
    @Input('baggage_section') baggage_section: boolean = false; 
    // @Input('isCabinBag') isCabinBag: boolean;
    // @Input('isCheckedBag') isCheckedBag: boolean;
    @Input('baggage_items') baggage_items: Array<any>;
    @Input('center') center: boolean = false; 


    displayStyle: string;

    left: string;
    top: string;

    _tooltipShow: boolean = false;
    isMobile: boolean;

    @HostListener('window:resize', ['$event'])
    onResize(event) {
        if(window.innerWidth <= 650) {
            this.isMobile = true;
        }else {
            this.isMobile = false;
        }
    }

    set tooltipShow(value) {
        if (this.icon) {

            let element = this.icon.nativeElement;

            let rect = element.getBoundingClientRect();

            if (this.displayPosition == 'bottom') {
                this.top = (rect.top + 20) + 'px';
                this.left = (rect.left - this.width / 2.16) + 'px';
            }

            if (window.innerWidth > 1469 && this.displayPosition == 'right') {
                this.left = (rect.left + 23) + 'px';
                this.top = (rect.top - 20) + 'px';
            }
            else if (window.innerWidth < 1470 && window.innerWidth > 1090 && this.displayPosition == 'right') {
                this.left = (rect.left - (this.width / 2 -10)) + 'px';
                this.top = (rect.top + 16) + 'px';
            }

            else if (this.departureBoard === true && window.innerWidth < 1091 && window.innerWidth > 470 && this.displayPosition == 'right') {
                this.left = (rect.left - (this.width - 14)) + 'px';
                this.top = (rect.top + 23) + 'px';
            }

            else if (window.innerWidth < 1091 && window.innerWidth > 470 && this.displayPosition == 'right') {
                this.left = (rect.left - (this.width - 14)) + 'px';
                this.top = (rect.top + 16) + 'px';
            }

            else if(this.departureBoard === true && window.innerWidth < 471 && this.displayPosition == 'right') {
                let smallScreen = this.width;
                smallScreen = 225;
                this.left = (rect.left - (smallScreen - 7)) + 'px';
                this.top = (rect.top + 20) + 'px';
            }

            else if (this.baggage_section === false && window.innerWidth < 471 && this.displayPosition == 'right') {
                let smallScreen = this.width;
                smallScreen = 225;
                this.left = (rect.left - (smallScreen - 7)) + 'px';
                this.top = (rect.top + 16) + 'px';
            }

            else if(this.baggage_section === true && window.innerWidth < 471 && this.displayPosition == 'right') {
                let smallScreen = this.width;
                smallScreen = 225;
                this.left = (rect.left - (smallScreen + 110)) + 'px';
                this.top = (rect.top + 16) + 'px';
            }

            if(this.center &&  window.innerWidth < 1470 && window.innerWidth > 500) {
                
                this.left = (rect.left - ((this.width / 2) -8)) + 'px';
                this.top = (rect.top + 16) + 'px';

            }else if(this.center &&  window.innerWidth < 501 && window.innerWidth > 470){
                this.left = (rect.left - ((this.width / 2) -22)) + 'px';
                this.top = (rect.top + 16) + 'px';

            }else if(this.center &&  window.innerWidth < 471) {
                
                this.left = (rect.left - ((this.width / 2) -28)) + 'px';
                this.top = (rect.top + 16) + 'px';
            }
        }
        this._tooltipShow = value;
    }
    get tooltipShow() {
        return this._tooltipShow;
    }

    onScrollSubscription: any;

    bodyRect: any;
    constructor(
        private renderer: Renderer2
    ) {
        this.onScrollSubscription = this.renderer.listen('window', 'scroll', event => {
            this.tooltipShow = false;
            this.onHideTooltip.emit();
        })
    }

    ngOnInit() {
        if (this.displayPosition === 'right' && this.baggage_section === false) {
            this.displayStyle = 'right-style'
        }else if(this.displayPosition === 'right' && this.baggage_section === true) {
            this.displayStyle = 'right-style baggage-style'
        }
        if(window.innerWidth < 650) {
            this.isMobile = true;
        } else {
            this.isMobile = false;
        }
    }

    ngOnDestroy() {
        if (this.onScrollSubscription)
            this.onScrollSubscription();
    }

    ngOnChanges(changes) {
        if (changes.hovered) {
            this.tooltipShow = changes.hovered.currentValue;
        }
    }

}
