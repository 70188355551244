import { Component, Input, Output, EventEmitter, OnChanges, SimpleChanges, ViewChild, ElementRef, HostListener } from '@angular/core';
import { Flight } from 'app/shared';
import { Subject } from 'rxjs';
import { debounceTime, startWith, tap } from 'rxjs/operators';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss']
})
export class SearchComponent implements OnChanges {
  searchText: string = '';
  @Input() flights: Array<Flight>;
  @Output() onFlightsChanged: EventEmitter<{}> = new EventEmitter<{}>();
  private searchSubject: Subject<string> = new Subject<string>();

  @ViewChild('myInput') myInput: ElementRef;

  placeholderText: string;

  constructor() {
    this.updatePlaceholder();
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.updatePlaceholder();
  }

  updatePlaceholder() {
    if (window.innerWidth > 500) {
      this.placeholderText = "Find a destination (city, country or island)";
      
    } else if(window.innerWidth <= 500 && window.innerWidth >= 375) {
      this.placeholderText = "Find a city, country or island";
    } else {
      // this.placeholderText = "Find a country";
      this.placeholderText = "Find a city, country or island";
    }
  }

  ngAfterViewInit() {
    // Set focus on the input element after the view has been initialized
    this.myInput.nativeElement.focus();
  }

  onInputChange(value: string): void {
    this.searchText = value;
    this.searchSubject.next(value);

    this.searchSubject.pipe(
      debounceTime(300) ,
      startWith(value)
    ).subscribe((value) => {
      this.searchCountries(value);
    });
  }

  clearSearch(): void {
    this.searchText = '';
    this.myInput.nativeElement.focus();
    this.onFlightsChanged.emit(undefined);
  }

  searchCountries(query: string): void {
    if(query) {
      query = query.toLocaleLowerCase();
      let flights = [];
      flights = this.flights.filter((flight) => {
        let country = flight.outboundTickets[flight.outboundTickets.length - 1].arrival.country.toLocaleLowerCase();
        let city = flight.outboundTickets[flight.outboundTickets.length - 1].arrival.city.toLocaleLowerCase();
        let countryInParentheses = this.getCountryInParentheses(country).toLocaleLowerCase();
  
        return this.compareFirstNCharacters(query, city, query.length) || this.compareFirstNCharacters(query, country, query.length) || this.compareFirstNCharacters(query, countryInParentheses, query.length);
      })

      let emitData = {
        flights: flights,
        text: this.searchText
      }
      this.onFlightsChanged.emit(emitData);
    }else {
      this.onFlightsChanged.emit(undefined);
    }
    
  }

  getCountryInParentheses(country: string): string {
    country = country.toLocaleLowerCase();
    const matches = country.match(/\((.*?)\)/);
    return matches ? matches[1] : '';
  }

  compareFirstNCharacters(str1: string, str2: string, length: number): boolean {
    const substr1 = str1.substring(0, length);
    const substr2 = str2.substring(0, length);

    return substr1 !== '' && substr2 !== '' && substr1 === substr2;
  }

  ngOnChanges(changes: SimpleChanges) {
    if(changes['flights'].currentValue && this.searchText) {
      this.searchCountries(this.searchText);
    } 
  }
}
