<div class="img-card"
  title="Last Minute flight {{flight.outboundTickets[0].departure.city}} to {{flight.outboundTickets[flight.outboundTickets.length -1].arrival.city}}">
  <div class="img" [ngStyle]="{ 'background-image': 'url('+ imgUrl +')' }"></div>
  <div class="card-content">
    <div class="inner">
      <h3>
        <a>
          <span class="to">to:</span><span class="destination-city"> {{
            flight.outboundTickets[this.flight.outboundTickets.length - 1].arrival.city }}</span>
          <span class="stops">{{flight.outboundTickets.length == 1 ? 'Direct' : (flight.outboundTickets.length - 1 === 1
            ? flight.outboundTickets.length - 1 + ' stop' : flight.outboundTickets.length - 1 + ' stops' )}}</span>
          <br>
          <span class="from">from:</span>
          <span class="arrival-city">
            {{ flight.outboundTickets[0].departure.city }}
          </span>
          <span class="airport">{{flight.outboundTickets[0].departure.name}} </span>
        </a>
      </h3>
      <table>
        <tr>
          <td class="departs"><span class="text">Departs </span> <span
              class="numbers">{{flight.outboundTickets[0].departure.date | amDateFormat:'HH:mm'}}</span>
            <span class="tomorrow"> {{isToday ? ' today' : ' tomorrow'}}</span>
          </td>
          <!-- <td> {{flight.outboundTickets.length == 1 ? 'Direct' : flight.outboundTickets.length - 1 + ' stop'}} </td> -->
          <td class="departs" [ngClass]="{'red-text': flight.timeLeft.asHours() < 5}"><span>Time left
            </span><app-show-time [img_card]='flight.timeLeft.asHours() < 5 ? false: true'
              [time]="flight.timeLeft | momentDiff"> </app-show-time></td>
        </tr>
      </table>
      <div class="price-container">
        <p class="left">
          <span class="from-small">From</span>
          <span *ngIf="flight.showReferencePrice" class="was-price">£{{ flight.priceBeforeDiscount}}</span>
          <span class="current-price">£{{flight.price | priceRound}}</span> <span class="return"> return</span>
          <span class="per-off" *ngIf="flight.showReferencePrice"> {{'-' + flight.discount + '%'}} </span>
          <span class="top-deal" *ngIf="flight.isTopDeal"><img src="assets//img/icons/top-deal(4).png" alt=""></span>
        </p>
        <button class="yellow-button full-width">
          <span class="btn-content">
            <span class="btn-text">View deal</span>
            <i class="flaticon-agree show-more"></i>
          </span>
        </button>
        <!-- <a class="flaticon-agree right transition-all"></a> -->
        <div class="clear"></div>
      </div>
    </div>
  </div>
</div>