import { Injectable } from '@angular/core';
import { RestService } from 'services/rest/rest.service';
import { Observable, Subject, forkJoin } from 'rxjs';
import { map } from 'rxjs/operators';
import { Flight, FlightsBoard } from 'app/shared';
import { ClockService } from 'services/clock/clock.service';
import * as moment from 'moment';
import { ConfigService } from 'services/config/config.service';
import { SafeHtml, DomSanitizer } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root'
})
export class FlightBoardService {

  private rootDepartureCode = null;
  private clockSubscription: any;
  private localTime: moment.Moment = moment();
  private flights: Flight;
  lastFetchData: moment.Moment;
  autoRefreshInterval: number;
  public isOneWay: boolean;

  public flightBoard$ = new Subject();
  public featuredFlights$ = new Subject();
  destinations: {
    city?: string;
    url: string;
    cityLongName: string;
    imgUrl: string;
    backgroundImgUrl: string;
    generalInfoText: SafeHtml;
    airlinesText: string;
    airportText: string;
    arrivalAirportText?:string;
    questionTexts: Array<string>;
    questionAnswers: Array<SafeHtml>;
    country?:string;
  }[];
  

  constructor(
    private rest: RestService,
    private clock: ClockService,
    private configService: ConfigService,
    private domSanitizer: DomSanitizer
  ) {
    this.autoRefreshInterval = this.configService.get('AUTO_REFRESH_INTERVAL_MINUTES');

    // this.clockSubscription = this.clock.onFlightBoardExpired.subscribe(() => {
    //   if (this.isFlightBoardExpired())
    //     this.refreshFlightBoard();
    // })
    this.creatDestinations();

    this.destinations = this.destinations.sort((a,b)=>a.cityLongName.toLowerCase().localeCompare(b.cityLongName.toLowerCase()));
  }

  creatDestinations() {
    this.destinations = [
      {
        city: 'New York',
        url: 'new-york-city',
        cityLongName: 'New York City',
        imgUrl: 'Images/DestinationImage/NYC.jpg',
        backgroundImgUrl: 'Images/DestinationBackgroundImage/NYC.jpg',
        generalInfoText: this.domSanitizer.bypassSecurityTrustHtml('Looking for cheap last minute flights to New York City? We negotiate with specific airlines to get the best last minute price to New York City. When available, we show the best last minute flight deals departing within the next 72 hours, including both direct and indirect routes, regular and low cost carriers.'),
        airlinesText: 'We currently offer last minute flights to New York City with: Delta, Norwegian, Aer Lingus, Westjet, TAP Portugal, Eurowings, Icelandair, Austrian Airlines.',
        airportText: 'We currently offer last minute flights to New York City from the following airports: London Heathrow (LHR), London Gatwick (LGW) and London City Airport (LCY).',
        questionTexts: ["Where can I find cheap last minute flights to New York from the UK?"," When can I find the cheapest last minute flights to New York?","Are there any special deals for last minute flights to New York with Runtofly?"," Are there any VISA requirements I should be aware of when booking a last minute flight to New York?"," Can I get a same-day flight to New York City using Runtofly?"], 
        questionAnswers: [
          this.domSanitizer.bypassSecurityTrustHtml("If you live in the UK and you are looking for cheap last minute flights to New York you should definitely visit Runtofly. In the homepage there are a number of flights, all with departure within 72 hours, to different destinations. Here you can check if New York City is among them. To make your search easier, you can filter the flights by destination and by selecting ‘America’ you will easily find, if any, all last minute flights to New York available."),
          this.domSanitizer.bypassSecurityTrustHtml("The best months to find the cheapest last minute flights to New York are probably November and February. January, March and October also follow suit. As for days, Tuesdays and Wednesdays tend to be the best days for finding good deals. However, these are just general indications which do not always hold true as you can find even cheaper last minute flights to New York during different months or weekdays."),
          this.domSanitizer.bypassSecurityTrustHtml("When we have a particularly special price, we flag those flights with a TOP DEAL label next to the price which indicates an exceptionally good deal. This obviously can happen with any flights we feature, not just with last minute flights to New York City. In addition, at particular times of the year (e.g. BlackFriday) we might run special promotions with additional discount codes whereby you can get an extra discount on top of the price displayed in the departure board. These discount codes might not be valid for all flights displayed in the departure board, so you would need to check whether that particular code applies also to your chosen last minute flight to New York."),
          this.domSanitizer.bypassSecurityTrustHtml("Please be aware that it is your responsibility to always check any visa requirements. Anyway, when booking a last minute flight to New York you should be aware that under the Visa Waiver Program (VWP) you must apply for an authorisation to travel using the Electronic System for Travel Authorisation (ESTA). The majority of ESTA applications are processed instantly and approved within a minute of submission, so you can still book a last minute flight to New York departing the same day with no problems. However, please be aware that there are rare cases where a decision on an application may be delayed by up to 72 hours."),
          this.domSanitizer.bypassSecurityTrustHtml("Yes! Runtofly specializes in flights departing within 72 hours, so it's possible to find last minute flights to New York departing the very same day. All flights are removed from the departure board 3 hours before take off, so you can find flights departing from 3 up to 72 hours before scheduled departure. If you want to buy a last minute flight to New York the same day it would better to select a flight departing late in the night and make the booking as soon as possible during the day.")
        ]
      },
      {
        city: 'Dubai',
        url: 'dubai',
        cityLongName: 'Dubai',
        imgUrl: 'Images/DestinationImage/DXB.jpg',
        backgroundImgUrl: 'Images/DestinationBackgroundImage/DXB.jpg',
        generalInfoText: this.domSanitizer.bypassSecurityTrustHtml('Searching for discounted last minute flight tickets to Dubai? We continuously talk to airlines to offer you the best last minute fares to Dubai. If available, we display the best last minute flight bargains departing within the following 72 hours, including both non-stop and connecting routes, traditional and low cost carriers.'),
        airlinesText: 'At the moment we offer last minute flights to Dubai with: Royal Jordanian, Oman Air, Pegasus Airlines, UIA and Jazeera Airways.',
        airportText: 'At the moment we offer last minute flights to Dubai from the following airports: London Heathrow (LHR), London Gatwick (LGW) and London Stansted (STN).',
        questionTexts: ["How to find last minute flights to Dubai from the UK?"," When can I find the cheapest last minute flights to Dubai?","Are there any special offers for last minute flights to Dubai with Runtofly?","Are there any VISA requirements I should be aware of when booking a last minute flight to Dubai?","Can I get a same-day flight to Dubai using Runtofly?"], 
        questionAnswers: [
          this.domSanitizer.bypassSecurityTrustHtml("From the UK, one of the best places to find cheap last minute flights to Dubai is Runtofly. In the homepage you find a selection of low-priced flights departing within 72 hours which you can filter by world area (selecting ‘Middle East’ destinations) to see immediately if there is any last minute flight to Dubai available. In addition to Dubai, you can also find flight deals to many other destinations."),
          this.domSanitizer.bypassSecurityTrustHtml("The best period to get cheap flights to Dubai last minute is probably in the summer, during the months of July and August. November can also be a good month to find some good deals. As for days, on Tuesday and Wednesday is generally easier to find convenient last minute flights to Dubai. Anyway, these are just general trends that don’t always hold true, as you can find great prices also in different months and days of the week."),
          this.domSanitizer.bypassSecurityTrustHtml("Typically we don’t have any special offers specific for last minute flights to Dubai. Nevertheless, occasionally we might have some special promotions, for example during Black Friday, with coupons that allow you to get an extra price reduction from the price shown in the homepage. It might be that you can not apply these coupons to all flights on our departure board, so you would need to verify if that particular coupon can be used with last minute flights to Dubai as well."),
          this.domSanitizer.bypassSecurityTrustHtml("Please remember that it is always your duty to personally check any visa requirements for your travel. Anyway, generally, when booking flights to Dubai last minute, passport holders of most countries don’t need any advance visa arrangements as visa are processed at the airport upon arrival. After disembarking your flight at Dubai airport you can proceed to immigration where your passport will be stamped with a 30 or 90day visit visa, depending on your passport, free of charge. So you can book a last minute flight to Dubai departing the same day without worrying about any VISA issues."),
          this.domSanitizer.bypassSecurityTrustHtml("You can certainly book last minute flights to Dubai departing the very same day on Runtofly. All flights published in our departure board take off from 3 to 72 hours before scheduled departure. Flights are generally scheduled up until 11pm so if you want to purchase a last minute flight to Dubai the same day you should choose a late departure and book the flight in the early afternoon at the latest.")
        ]
      },
      {
        city: 'Budapest',
        url: 'budapest',
        cityLongName: 'Budapest',
        imgUrl: 'Images/DestinationImage/BUD.jpg',
        backgroundImgUrl: 'Images/DestinationBackgroundImage/NYC.jpg',
        generalInfoText: this.domSanitizer.bypassSecurityTrustHtml('Are you on the look for inexpensive last minute airline tickets to Budapest? We make deals with each individual airline to get the cheapest last minute price to Budapest. We then show the best last minute flights taking off with 72 hours, when available, including both direct and 1 stop routes, scheduled and low cost airlines.'),
        airlinesText: 'Currently the last minute flights to Budapest we offer are with: easyJet and LOT.',
        airportText: 'Currently the last minute flights to Budapest we offer are from the following airports: London Stansted (STN) and London City Airport (LCY).',
        questionTexts: ["How to find last minute flights to Budapest from the UK?"," When can I find the cheapest last minute flights to Budapest?","Are there any additional discounts for last minute flights to Budapest with Runtofly?"," Any tips for booking cheap last minute flight to Budapest?","Can I book a same-day flight to Budapest using Runtofly?"], 
        questionAnswers: [
          this.domSanitizer.bypassSecurityTrustHtml("If you are after last minute flights to Budapest and you live in the United Kingdom you should check Runtofly's website. In the homepage departure board you can find many last 72 hours flight deals to many destinations, so you can check if there is any last minute flight to Budapest available. Even though there is no search box as such, you can filter the flights by destination to easily find out whether Budapest is there or not."),
          this.domSanitizer.bypassSecurityTrustHtml("November and February are likely to be the best months to find the most convenient last minute flights to Budapest. January, October and March tend to be good also. During the week, especially on Tuesdays and Wednesdays you also have better chances to get cheaper prices for last minute flights to Budapest. Obviously, it then depends on a case by case basis."),
          this.domSanitizer.bypassSecurityTrustHtml("In case we have an exceptionally good agreement with an airline for a certain destination, we will highlight the price with a TOP DEAL label besides it. This does not apply specifically to last minute flights to Budapest, but it might happen with any destination. In addition, we might run special promotions where we give away some promo codes that can be used to reduce the price even further. These promo codes might have restrictions based on the destination, so you would need to always verify if thatparticular promo code can be used with your chosen last minute flights to Budapest."),
          this.domSanitizer.bypassSecurityTrustHtml("There are really no specific tips to book cheap last minute flights to Budapest. You should just adopt the general tips that are usually used to book any cheap flighs. This means that you should be flexible with your travel dates, avoid the main airports and travel with a carry-on baggage only to avoid extra fees. Also, when browsing the internet for online travel agencies you should consider Runtofly which displays a selection of affordable flights departing with 72 hours."),
          this.domSanitizer.bypassSecurityTrustHtml("Runtofly showcases a number of flights departing either today or tomorrow. So if you find a last minute flight to Budapest departing the same day, you can definitely book it. It’s probably preferred to choose a late departure (e.g. after 8pm) and complete the booking no later than late afternoon.")
        ]
      },
      {
        city: 'Bangkok',
        url: 'bangkok',
        cityLongName: 'Bangkok',
        imgUrl: 'Images/DestinationImage/BKK.jpg',
        backgroundImgUrl: 'Images/DestinationBackgroundImage/BKK.jpg',
        generalInfoText: this.domSanitizer.bypassSecurityTrustHtml('Are you in search for low-priced last minute flights to Bangkok? We make agreements with a number of airlines to get the most advantageous last minute rates to Bangkok. When available, we show the top last minute flight offers leaving within the next 72 hours, including both direct and connecting routes, full service and low cost carriers.'),
        airlinesText: 'At this time we are offering last minute flights to Bangkok with: Eva Air, Lufthansa, Austrian Airlines, SWISS, UIA, Eva Air, Royal Jordanian and Oman Air.',
        airportText: 'At this time we are offering last minute flights to Bangkok from the following airports: London Heathrow (LHR), London Gatwick (LGW) and London City Airport (LCY).',
        questionTexts: ["Where can I find last minute flights to Bangkok from the UK?"," When can I find the cheapest last minute flights to Bangkok?","Are there any special promotions for last minute flights to Bangkok with Runtofly?","Are there any VISA requirements I should be aware of when booking a last minute flight to Bangkok?"," Can I get a same-day flight to Bangkok using Runtofly?"], 
        questionAnswers: [
          this.domSanitizer.bypassSecurityTrustHtml("One of the best websites to find cheap last minute flights to Bangkok from the UK is Runtofly. In the homepage you can find a selection of flight deals, all with departure within the next 72 hours. There is no search functionality as such but you can easily filter the flights by destination (e.g. flights to Asia) to check if there is any last minute flights to Bangkok available."),
          this.domSanitizer.bypassSecurityTrustHtml("Chances to find a cheaper price for last minute flights to Bangkok are higher between April and October, typically a low season. In this period, generally from mid-June, the southwestern monsoons roll in and stick around for the whole summer. Midweek is also generally a good time to secure a better price. However these are only generalizations, since you might find even cheaper prices during the winter or in the weekend."),
          this.domSanitizer.bypassSecurityTrustHtml("Usually there are no special promotions specific to last minute flights to Bangkok. Nevertheless, during particular times of the year we might have some discount codes to get an extra cut on the flight prices shown on our homepage. These discount codes might not be usable with all flights displayed on our departure board. So it’s always better to double check whether that exact code can be used for your chosen last minute flight to Bangkok."),
          this.domSanitizer.bypassSecurityTrustHtml("Please note that you are responsible to check any visa requisites for your journey. Anyway, usually passport holders of most countries don’t need any VISA for Thailand, providing that they do not stay longer than 30 days. So, providing that you are not staying for more than 30 days, you can get a last minute flight to Bangkok, even departing the very same day, without having to worry about any VISA. Also, it is recommended a passport validity of at least six months beyond the date of the arrival in Thailand to avoid potential problems."),
          this.domSanitizer.bypassSecurityTrustHtml("It’s definitely possible to book a last minute flight to Bangkok departing the very same day. On Runtofly you can find a selection of last minute flight deals with departure within the following 72 hours, which generally means today and tomorrow. If you wish to book a last minute flight to Bangkok the same day it would be better for you to pick a flight with a late departure, possibly after 8pm.")
        ]
      },
      {
        city: 'Amsterdam',
        url: 'amsterdam',
        cityLongName: 'Amsterdam',
        imgUrl: 'Images/DestinationImage/AMS.jpg',
        backgroundImgUrl: 'Images/DestinationBackgroundImage/AMS.jpg',
        generalInfoText: this.domSanitizer.bypassSecurityTrustHtml('When it comes to go somewhere last minute, Amsterdam is definitely an excellent choice. On our website you can find flights to many cities including Amsterdam, all with scheduled departure within 72 hours. We show any available last-minute flight to Amsterdam in the departure board above and also on our <a href="/" class="underline">homepage</a>, along with other worldwide destinations. The last-minute flights to Amsterdam featured on our platform are direct flights only, generally operated by low-cost airlines.'),
        airlinesText: 'The last minute flights to Amsterdam you can find on Runtofly are currently with easyJet and Vueling.',
        airportText: 'The last minute flights to Amsterdam you can find on Runtofly currently depart from the following airports: London Gatwick (LGW), London Stansted (STN), London Luton (LTN) and London Southend (SEN).',
        questionTexts: ["How to find last minute flight deals to Amsterdam from the UK?"," When can I find the cheapest last minute flights deals to Amsterdam?","Are there any special discounts for last minute flights to Amsterdam with Runtofly?","What is the best way to get from London to Amsterdam?"," Can I book a same-day flight to Amsterdam using Runtofly?"], 
        questionAnswers: [
          this.domSanitizer.bypassSecurityTrustHtml("If you want to book a last minute trip to Amsterdam from the UK you can check several online flight search engines including Runtofly. Here in particular, you can find and book many flights departing within the next 72 hours. Once you are on Runtofly’s homepage, you can use the filter section next to the departure board to easily find, when available, all Amsterdam last minute flights departing within 72 hours."),
          this.domSanitizer.bypassSecurityTrustHtml("If you want to fly to Amsterdam last minute, you can probably find the cheapest fares in October, November and February. January and March might also be good months to find some low-priced flights. With regard to days of the week, Tuesdays and Wednesdays are probably your best bet to find cheaper last minute flights to Amsterdam. Nonetheless, these are just generic suggestions which are not always valid as you can find even better bargains in different periods."),
          this.domSanitizer.bypassSecurityTrustHtml("Generally we don’t have any special discounts specific to last minute Amsterdam flights. During particular occasions (e.g. Black Friday) we might run limited promotions with voucher codes by which you can get a further reduction from the price shown in the homepage. These vouchers might not be applicable to all flights featured in the departure board, so please check whether that specific voucher code applies also to your chosen last minute flight to Amsterdam."),
          this.domSanitizer.bypassSecurityTrustHtml("The best way to get from London to Amsterdam is by plane because it’s the best combination of price and speed. In particular, as for travel time, it takes approximately 12 hours by coach against a little more than 1 hour if you go by plane. As for price, although the coach fares are generally less expensive, you might also find cheap flight tickets, particularly if you book last minute. Runtofly is an excellent place where you can find great last minute flights to Amsterdam departing within 72 hours, together with many other destinations."),
          this.domSanitizer.bypassSecurityTrustHtml("Certainly! All flights available on Runtofly depart within 72 hours. So it's possible to find and book a last minute flight to Amsterdam departing the very same day. Flight departures are scheduled until approximately 11pm so if you want to book a last minute flight to Amsterdam the same day you should make the booking early in the evening at the latest for a late night departure.")
        ]
      },
      {
        city: 'Prague',
        url: 'prague',
        cityLongName: 'Prague',
        imgUrl: 'Images/DestinationImage/PRG.jpg',
        backgroundImgUrl: 'Images/DestinationBackgroundImage/PRG.jpg',
        generalInfoText: this.domSanitizer.bypassSecurityTrustHtml('Looking for affordable last minute flights to Prague? We make contracts with individual airlines to offer you the best last minute rates to Prague. When available, we show the cheapest last minute flight bargains with departure within the next 72 hours, including both direct and stopping routes, regular and no-frills airlines.'),
        airlinesText: 'At the present we offer last minute flights to Prague with: easyJet. ',
        airportText: 'At the present we offer last minute flights to Prague from: London Gatwick (LGW), London Stansted (STN), London Luton (LTN) and London Southend (SEN).',
        questionTexts: [" How to find last minute flight deals to Prague from the UK?"," When can I find the cheapest last minute flights to Prague?","Are there any additional discounts for last minute flight to Prague with Runtofly?"," Any tips for booking cheap last minute flight to Prague?"," Can I book a same-day flight to Prague using Runtofly?"], 
        questionAnswers: [
          this.domSanitizer.bypassSecurityTrustHtml("To find cheap last minute flights to Prague from the UK you should definitely visit Runtofly. In the homepage departure board you can find many last 72 hours flight deals to many destinations. To easily find whether there are any last minute flights to Prague departing within 72 hours just filter the content by destination, in this case checking the ‘Europe’ tick box."),
          this.domSanitizer.bypassSecurityTrustHtml("Generally in October, November and February is more likely to find cheaper prices for last minute flights to Prague. January and March are good months too. With regard to days, if you book from Tuesday to Thursday avoiding weekends, especially Fridays, you have better chances to find cheaper last minute flights to Prague."),
          this.domSanitizer.bypassSecurityTrustHtml("When the price of a certain flight is exceptionally good, we mark the price with a TOP DEAL tag next to it. This might happen with any flights, not just with last minute flights to Prague. Also, from time to timewe might give some promotional code which might be applied to get a further price reduction. These codes might not apply to all flights displayed but just to some of them (e.g. only flights to European destinations). So please, always check whether you can use that particular promotional code with your chosen last minute flights to Prague."),
          this.domSanitizer.bypassSecurityTrustHtml("There are no specific tips to book cheap last minute flights to Prague. The usual tips for booking any last minute flight to anywhere, or actually to book any flight, apply. For example, first of all you should check more flight search engines and, particularly if you want to fly within short notice, Runtofly’s website where you can find last 72 hours flight deals. Also, be flexible with your travel dates, consider secondary airports and travel light to avoid extra baggage fees."),
          this.domSanitizer.bypassSecurityTrustHtml("Absolutely! On Runtofly you can find last minute flights departing either today or tomorrow. If you want to buy a last minute flight to Prague departing late the same day it’s probably better to choose a late departure and book no later than early afternoon to give you sufficient time to pack your stuff and get to the airport.")
        ]
      },
      {
        country: 'Thailand',
        url: 'thailand',
        cityLongName: 'Thailand',
        imgUrl: 'Images/DestinationImage/KBV.jpg',
        backgroundImgUrl: 'Images/DestinationBackgroundImage/PRG.jpg',
        generalInfoText: this.domSanitizer.bypassSecurityTrustHtml('As a destination for a last minute trip, Thailand is definitely in high demand. All our last-minute flights to Thailand available are shown in the departure board above. You can also find them in our homepage departure board, in addition to many other worldwide destinations. Our last-minute flights to Thailand are operated by scheduled airlines and include both direct and indirect flights.'),
        airlinesText: 'Our last-minute flights to Thailand are currently flown by the following airlines: Eva Air, Lufthansa, Malaysia Airlines, Oman Air, and Thai Airways.',
        airportText: 'All our last-minute flights to Thailand currently depart from London Heathrow (LHR).',
        arrivalAirportText: 'Currently, on our platform you can find last-minute flights to Thailand to the following cities: Bangkok, Ko Samui, Phuket, and Krabi.',
        questionTexts: ["Where can I find last minute flights to Thailand from London?"," When can I find the cheapest last minute flights to Thailand?"," Are there any special deals for last minute flights to Thailand with Runtofly?","What are the best destinations in Thailand for a last minute flight?"," Can I get a same-day flight to Thailand using Runtofly?"], 
        questionAnswers: [
          this.domSanitizer.bypassSecurityTrustHtml("If you are based in London and you are looking for a last minute flight to Thailand you should definitely visit Runtofly. In the homepage you can find a selection of last minute flight deals to many destinations, including some of the most popular in Thailand. To easily find all last minute flights to Thailand available in the next 72 hours, just use the filter and sorting functionalities that you find there."),
          this.domSanitizer.bypassSecurityTrustHtml("You are likely to find the cheapest last minute flights to Thailand from May to October, which is when the rainy season hits parts of the country, with May being probably the cheapest month of all. The months from November to February tend to be the most expensive, but that does not mean that you can find some affordable last minute flights to Thailand during these months as well."),
          this.domSanitizer.bypassSecurityTrustHtml("We use a TOP DEAL tag next to the price whenever we have negotiated with an airline a particularly convenient last minute price for a certain route. It is possible that at certain times we might have some special deals for last minute flights to Thailand, as well as to other destinations. Additionally, during special occasions we might also hand out some coupon code to get an extra discount on top of the prices displayed in the departure board. Just check whether that particular code can be used in conjunction with your chosen last minute flight to Thailand."),
          this.domSanitizer.bypassSecurityTrustHtml("It’s impossible to say which destination in Thailand is best for a last minute trip as the answer is a very subjective one. You might prefer the vibes of a big city like Bangkok or just chill in one of the many white-sand beaches in Phuket or Ko Samui. Whatever are your preferences, on Runtofly you are likely to find, at any given time, more than one last minute flight to Thailand to choose from."),
          this.domSanitizer.bypassSecurityTrustHtml("On Runtofly you can find and book many flights departing the same day, including last minute flights to Thailand. Particularly for Thailand, passport holders of most countries don’t need a VISA, providing they stay no longer than 30 days. This comes very useful when booking last-minute. Anyway, even though you don’t have to worry about VISA, you should still book as early as possible during the day if you want to get a last minute flight to Thailand the same day.")
        ]
      },
      {
        country: 'Spain',
        url: 'spain',
        cityLongName: 'Spain',
        imgUrl: 'Images/DestinationImage/SVQ.jpg',
        backgroundImgUrl: 'Images/DestinationBackgroundImage/PRG.jpg',
        generalInfoText: this.domSanitizer.bypassSecurityTrustHtml('Spain is a very popular destination for last minute travelling. We show all last-minute flights to Spain available in the departure board above and in our homepage departure board, along with many other destinations. The last-minute flights to Spain that you can book on Runtofly are all non-stops and generally operated by no-frills carriers.'),
        airlinesText: 'We currently offer last minute flights to Spain with the following airlines: Air Europa, easyJet, Iberia, and Vueling.',
        airportText: 'The last minute flights to Spain you can find here are currently departing from the following airports: London Heathrow (LHR), London Gatwick (LGW), London Stansted (STN), London Luton (LTN) and London City (LCY).',
        arrivalAirportText: 'The last minute flights to Spain you can find here are currently to the following cities: Barcelona, Ibiza, Las Palmas, Madrid, Malaga, Mallorca, Tenerife, and Valencia.',
        questionTexts: ["Where can I find cheap last minute flights to Spain from the UK?"," When can I find the cheapest last minute flights to Spain?","Are there any special deals for last minute flights to Spain with Runtofly?","What are the best destinations in Spain for a last minute break?","Can I get a same-day flight to Spain using Runtofly?"], 
        questionAnswers: [
          this.domSanitizer.bypassSecurityTrustHtml("From the United Kingdom, one of the best places to see if there is any cheap last minute flight to Spain is Runtofly. If you visit the homepage you can see a selection of competitive flights departing with 72 hours to many cities, including some of the most popular destinations in Spain. You can use the destination filters to easily find all last minute flights to Spain available in the next 72 hours."),
          this.domSanitizer.bypassSecurityTrustHtml("Generally the probability to find cheaper last minute flights to Spain is higher during the months of January, February and November. March and October are good months as well. Also, try to avoid the weekends, especially Fridays when prices tend to be higher, whereas you can find some cheaper prices on Saturdays. During the week, Tuesdays and Wednesdays are generally the best. These trends are not always true and you can definitely find some even cheaper last minute flights to Spain in different months and days."),
          this.domSanitizer.bypassSecurityTrustHtml("When we have negotiated with an airline a particularly good bargain for a certain route, we place a TOP DEAL label next to the price of that particular destination. This can happen with any flight, not exclusively with last minute flights to Spain. Moreover, occasionally we can have some ongoing promotions with some voucher codes to get some money off from the flight price displayed in the homepage. These voucher codes might not apply to any flights, so please check if that particular code can be redeemed also with the last minute flights to Spain you choose."),
          this.domSanitizer.bypassSecurityTrustHtml("It’s difficult to say which are the best destinations in Spain for a last minute break. There is no definite answer, as this is a subjective choice. On Runtofly you can definitely find more than one last minute flight to Spain at any time, so you can choose the city you prefer among the ones available in the departure board."),
          this.domSanitizer.bypassSecurityTrustHtml("All flights featured on Runtofly have departure within 72 hours. So yes, it’s likely that you will also find some last minute flights to Spain departing the very same day. In case you decide to buy a last minute flight to Spain the same day it would be better to pick a flight with late departure and do the booking as soon as possible during the day.")
        ]
      },
      {
        city: 'Paris',
        url: 'paris',
        cityLongName: 'Paris',
        imgUrl: 'Images/DestinationImage/PAR.jpg',
        backgroundImgUrl: 'Images/DestinationBackgroundImage/PAR.jpg',
        generalInfoText: this.domSanitizer.bypassSecurityTrustHtml('Paris is one of the most sought-after destinations for a last minute trip. All flights featured on our website have scheduled departure within 72 hours. When a last minute flight to Paris is available we’ll show it in the departure board above and also in our homepage departure board, together with many other destinations. Our last minute flights to Paris are only non-stop flights, usually operated by no-frills carriers.'),
        airlinesText: 'At present, our last minute flights to Paris are with easyJet and Vueling.',
        airportText: 'At present, our last minute flights to Paris depart from the following airports: London Gatwick (LGW) and London Luton (LTN).',
        questionTexts: ["How to find last minute flights to Paris from the UK?","When can I find the cheapest last minute flights to Paris?","Are there any additional discounts for last minute flight to Paris with Runtofly?","What is the best way to get from London to Paris?","Can I book a same-day flight to Paris using Runtofly?"], 
        questionAnswers: [
          this.domSanitizer.bypassSecurityTrustHtml("If you are based in the UK and you are looking for cheap last minute flights to Paris, you should visit Runtofly. On the homepage departure board you’ll find many flights, all departing within 72 hours, to many destinations, including Paris. Although there is no search functionality, you can easily find any available last-minute flight to Paris using the sorting and filter functionalities."),
          this.domSanitizer.bypassSecurityTrustHtml("November and February are probably the best months to find the best price for last minute flights to Paris. January and March tend to be good months as well. As for weekdays, it’s more likely to find cheaper deals on Tuesdays, Wednesdays and Saturdays. However, these tips do not always apply as you can find even cheaper last minute flights to Paris in different months and days."),
          this.domSanitizer.bypassSecurityTrustHtml("There are generally no additional discounts specific for last minute flights to Paris. However, every now and then, for example during Black Friday, we might have some special promo codes that you can takeadvantage of. Some promo codes are valid only for flights within Europe. In that case you can get a further discount on your chosen last minute flight to Paris in addition to the price published in the departure board."),
          this.domSanitizer.bypassSecurityTrustHtml("Although trains can be a very convenient transportation to get to Paris from London, especially in terms of journey duration, these tend to be quite expensive, especially when booked at the last minute. Coaches are also a good option in terms of price, but definitely not in terms of travel duration. Planes are definitely a great alternative between the two. Flight tickets tend to be cheaper than train ones, particularly when travelling within short notice. If you are looking for last minute flights to Paris Runtofly is among the best websites where you can find some great last minute deal."),
          this.domSanitizer.bypassSecurityTrustHtml("Yes, on Runtofly you can find and book last minute flights to Paris the same day of departure. All flights published on our website depart within 3 to 72 hours, so either today or tomorrow. Short flights like London to Paris generally require you less time than intercontinental ones, especially if you are travelling with a carry-on bag only. If you want to book a last minute flight to Paris the same day you should preferably pick a flight with late departure (after 8pm) and have completed the booking by maximum 6pm.")
        ]
      }
    ];
  }


  public refreshFlightBoard() {
    this.getFlightBoardData(this.rootDepartureCode).subscribe(flightBoardData => {
      this.flightBoard$.next(flightBoardData);
      this.lastFetchData = moment(moment.now());
    });
  }

  public getFlightBoardData(rootDepartureCode?: string): Observable<{ roundTrip: FlightsBoard, oneWay: FlightsBoard }> {
    this.rootDepartureCode = rootDepartureCode;
  
    const roundTripRequest = this.rest.getFlightsBoard(rootDepartureCode, false).pipe(
      map(flightBoardData => flightBoardData)
    );
  
    const oneWayRequest = this.rest.getFlightsBoard(rootDepartureCode, true).pipe(
      map(flightBoardData => flightBoardData)
    );
  
    return forkJoin({
      roundTrip: roundTripRequest,
      oneWay: oneWayRequest
    });
  }

  private filterFlightsOver24Hours(flights) {

    return flights.filter((flight) => flight.timeLeft.asSeconds() < 24 * 3600 || flight.timeLeft.asSeconds() == 24 * 3600);
  }

  public filterFeaturedFlights(flights: Array<Flight>): Array<Flight> {
    const featuredFlights = []

     const maxLengthOfFeaturedFlights = 4;
    // let countOfFeaturedFlights = 0;

    let featuredFlightsObj = {};
    flights.map((flight) => {
      if (flight.maxVsActualPriceDiffSort ) {
        if (!featuredFlightsObj[flight.maxVsActualPriceDiffSort]) {
          featuredFlightsObj[flight.maxVsActualPriceDiffSort] = flight;
        }
      }
    })

    Object.keys(featuredFlightsObj).map((key) => {
      featuredFlights.push(featuredFlightsObj[key]);
    })

    featuredFlights.sort((flight1: Flight, flight2:Flight)=>{
      if(flight1.maxVsActualPriceDiffSort > flight2.maxVsActualPriceDiffSort) {
        return 1
      }else if(flight1.maxVsActualPriceDiffSort < flight2.maxVsActualPriceDiffSort) {
        return -1
      }
      return 0;
    })

    if(featuredFlights.length > maxLengthOfFeaturedFlights)
      featuredFlights.length = maxLengthOfFeaturedFlights;

    //want the flight with highest priority to be in the img-card component
    let firstELem = featuredFlights.shift();
    featuredFlights.push(firstELem);
    
    return featuredFlights;
  }

  isFlightBoardExpired() {
    if (this.lastFetchData == undefined)
      return false;

    let elpased = moment.duration(moment(moment.now()).diff(this.lastFetchData)).asMinutes();
    return elpased > this.autoRefreshInterval;
  }

  setIsOneWay(value: boolean) {
    this.isOneWay = value;
  }

  getIsOneWay() {
    return this.isOneWay;
  }
}
