<div #menu class="default-select">
  <div class="input-wrapp">
    <div *ngIf="formControl.valid" class="toggle ok">
      <span class="glyphicon glyphicon-ok"></span>
    </div>
    <div *ngIf="formSubmitAttempt && formControl.invalid" class="toggle error">
      <span class="glyphicon glyphicon-remove"></span>
    </div>
    <select [(ngModel)]="selectedOption" [formControl]="formControl" class="selectpicker" (change)="select($event.target.value)">
      <option>Select</option>
      <option *ngFor="let option of options" [value]="option">{{option.label ? option.label : option}}</option>
    </select>

    <div [ngClass]="{select: selectedOption, open: isOpen, dropup: isOpen && positionMenuUp}" class="btn-group bootstrap-select">
      <button (click)="toggle()" type="button" class="btn dropdown-toggle selectpicker btn-default">
        <span class="filter-option pull-left" [ngClass]="{'dark-gray':formControl.valid}">{{selectedOption ? (selectedOption.value || selectedOption.value === 0 ? selectedOption.label : selectedOption) : caption}}</span>
        <span class="flaticon-agree"></span>
      </button>
      <div *ngIf="isOpen" class="dropdown-menu  options">
        <ul class="dropdown-menu inner selectpicker" role="menu">
          <ng-container *ngFor="let option of options">
          <li *ngIf="(option.disabled && option.disabled === false) || !option.disabled" (click)="select(option)" [ngClass]="{selected: selectedOption && ((option.label && option.label == selectedOption.label) || selectedOption == option)}">
            <a class="">
              <span class="text">{{option.label ? option.label : option}}</span>
              <span class="glyphicon glyphicon-ok flaticon-check74"></span>
            </a>
          </li>
          </ng-container>
        </ul>
      </div>
    </div>



  </div>
</div>
