import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'search' })
export class FilterArrayPipe {
    
    transform(items, args) {
        if (!args) {
            return items;
        }
        else if (items) {
            return items.filter(item => item.value.toLowerCase().includes(args.toLowerCase()));
        }
    }
}