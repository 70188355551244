import { Component, OnInit, Input } from '@angular/core';
import { checkBaggageType, FullFlight, TicketOfOneWayFlight, } from 'app/shared';

@Component({
  selector: 'app-cdeparture-arrival-diff',
  templateUrl: './cdeparture-arrival-diff.component.html',
  styleUrls: ['./cdeparture-arrival-diff.component.css']
})

export class CdepartureArrivalDiffComponent implements OnInit {

    @Input('fullFlight') fullFlight: FullFlight;

    isDepartureAndArrivalSame: boolean = true;
    isArrivalAndDepartureSame: boolean = true;
    isDepartureHasBag: boolean;
    isArrivalHasBag: boolean;
    isTypeOfBagsDifferent: boolean;

    returnFirstTicket: TicketOfOneWayFlight;
    returnLastTicket: TicketOfOneWayFlight;
    outboundFirstTicket: TicketOfOneWayFlight;
    outboundLastTicket: TicketOfOneWayFlight;

    constructor() { }

    ngOnInit(): void {
        this.isDepartureAndArrivalSame = this.fullFlight.isDepartureAndArrivalSame;
        this.isArrivalAndDepartureSame = this.fullFlight.isArrivalAndDepartureSame;

        this.returnFirstTicket = this.fullFlight.inbound.legs[0];
        this.returnLastTicket = this.fullFlight.inbound.legs[this.fullFlight.inbound.legs.length - 1];

        this.outboundFirstTicket = this.fullFlight.outbound.legs[0];
        this.outboundLastTicket = this.fullFlight.outbound.legs[this.fullFlight.outbound.legs.length - 1];

        this.isDepartureHasBag = this.fullFlight.isDepartureHasBag;
        this.isArrivalHasBag = this.fullFlight.isArrivalHasBag;

        this.checkBaggageType(this.outboundFirstTicket,this.returnLastTicket);
    }

    checkBaggageType(outboundFirstTicket,returnLastTicket) {
        let firstTicketResponse = checkBaggageType(outboundFirstTicket.baggage);

        if(firstTicketResponse === 'isSmallBag') {
            let lastTicketResponse = checkBaggageType(returnLastTicket.baggage);
            if(firstTicketResponse != lastTicketResponse) {
                this.isTypeOfBagsDifferent = true;
            }
        }
        else if (firstTicketResponse === 'isCabinBag') {
            let lastTicketResponse = checkBaggageType(returnLastTicket.baggage);
            if(firstTicketResponse != lastTicketResponse) {
                this.isTypeOfBagsDifferent = true;
            }
        }
        else if(firstTicketResponse === 'isCheckedBag'){
            let lastTicketResponse = checkBaggageType(returnLastTicket.baggage);
            if(firstTicketResponse != lastTicketResponse) {
                this.isTypeOfBagsDifferent = true;
            }
        }
    }
}