
<div class="our-advantages hide-in-mobile">
    <div class="container">
      <h3 class="header">
        How to book last minute flights with Runtofly
      </h3>
      <div class="boxes">
        <div class="box">
          <div class="icon">
            <img src="../../../../assets/img/icons/search-icon.svg" alt="">
          </div>
          <h4 class="caption">
            Flight selection
          </h4>
          <div class="text" #contnent>
            With most online travel agencies out there you need to input your departure and arrival airports 
            along with the dates of your trip in order to view all available results that match your criteria. 
            Not so with Runtofly. All available last minute flights are already displayed in our departure board. 
            You might just want to change the departure airport or city in the top section of the departure board in 
            case is not the one you want to fly from. All last minute flight deals shown are return flights with outbound 
            departure within 72 hours. You can filter and sort the results by destination, stops, airline, price and time 
            to departure to navigate the content better. Then, all you have to do is select the lastminute flight you prefer. That’s it.
          </div>
          <span class="btn-show" (click)="toggleContent(1)">{{!isContentOpen1 ? 'Show more' : 'Show Less'}}</span>
        </div>
        <div class="box">
          <div class="icon booking-process">
            <img src="../../../../assets/img/icons/check-mark.svg" alt="">
            <!-- <span>
              <i class="font-awesome"> &#xf00c; </i>
            </span> -->
          </div>
          <h4 class="caption">
            Booking process 
          </h4>
          <div class="text" #contnent>
            There are 5 simple steps to book our last minute flights. In step 1 you select just the outbound of your preferred 
            last minute flight. In step 2 you choose a date for your inbound flight, you select one of the flight options 
            (outbound-inbound combination) available for the chosen date and then you select the number and type of passengers. 
            In step 3 you are required to fill in the details for all passengers. In step 4 you pay with your card and you are done. 
            You’ll get your booking confirmation for your chosen last minute flight in step 5 and it will also be delivered by email, 
            along with your last minute tickets.
          </div>
          <span class="btn-show" (click)="toggleContent(2)">{{!isContentOpen2 ? 'Show more' : 'Show Less'}}</span>
        </div>
    
        <div class="box">
          <div class="icon extra-light">
            <img src="../../../../assets/img/icons/tag_icon.png" alt="">
          </div>
          <h4 class="caption">
            Top deals
          </h4>
          <div class="text" #contnent>
            We aim to publish only cheap last minute flights, so all the content we display is a curated selection of last minute 
            flight deals. In some cases you might find flights displaying a TOP DEAL label next to the price. This means that that 
            price of that specific last minute flight is exceptionally good, which generally means that we have negotiated a 
            particularly advantageous deal with the airline for that destination.
          </div>
          <span class="btn-show" (click)="toggleContent(3)">{{!isContentOpen3 ? 'Show more' : 'Show Less'}}</span>
        </div>
      </div>
    </div>
  </div>