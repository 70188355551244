<div class="booking-flow">
  <!-- HEADER TABS -->
  <div class="tab-header">
    <!-- <div class="yellow-bar"></div> -->
    <!-- <div class="grey-bar"></div> -->
    <ul class="tabs">
      <!-- <li>
        <div class="line-start" [ngClass]="{'active':2<=activeTabIndex}"> </div>
        <div class="line-end" [ngClass]="{'active':2<=activeTabIndex}"> </div>
      </li>-->
      <li [ngClass]="{'one-way-step': isOneWay}"> 
        <!-- <div class="line-start" [ngClass]="{'active':2<=activeTabIndex}"> </div> -->
        <div class="line-end" [ngClass]="{'active':2<=activeTabIndex}"> </div>
        <a routerLink="/">
          <p>
            <i [ngClass]="{'font-awesome':1<activeTabIndex, 'complete': activeTabIndex === 1}"> &#xf00c; </i>
            <span [ngClass]="{'active-tab':2<=activeTabIndex}">Outbound</span>
          </p>
        </a>
      </li>

      <li *ngIf="!isOneWay">
        <div class="line-start" [ngClass]="{'active':2<=activeTabIndex}"> </div>
        <div class="line-end" [ngClass]="{'active':3==activeTabIndex || 3<activeTabIndex}"> </div>
        <a (click)="goBack(2)" [ngClass]="{'active-section': 2 == activeTabIndex ,'cursor-auto': (2 >= activeTabIndex || 5 == activeTabIndex)}">
          <p>
            <i *ngIf="2==activeTabIndex || 2>activeTabIndex" [ngClass]="{'complete': activeTabIndex == 2}"> 2 </i>
            <i *ngIf="2<activeTabIndex" [ngClass]="{'font-awesome':2<activeTabIndex}"> &#xf00c; </i>
            <span [ngClass]="{'active-tab':2<=activeTabIndex,'bold': activeTabIndex == 2}">Inbound</span>
          </p>
        </a>
      </li>
      <li [ngClass]="{'one-way-step': isOneWay}" (click)="goBack(isOneWay ? 2 : 3)">
        <div class="line-start" [ngClass]="{'active': activeTabIndex == (isOneWay ? 2 : 3) || activeTabIndex > (isOneWay ? 2 : 3)}"> </div>
        <div class="line-end" [ngClass]="{'active': activeTabIndex == (isOneWay ? 3 : 4) || activeTabIndex > (isOneWay ? 3 : 4)}"> </div>
        <a [ngClass]="{'active-section': (isOneWay ? 2 : 3) == activeTabIndex, 'cursor-auto': ((isOneWay ? 2 : 3) >= activeTabIndex || (isOneWay ? 4 : 5) == activeTabIndex)}">
          <p>
            <i *ngIf="(isOneWay ? 2 : 3) == activeTabIndex || (isOneWay ? 2 : 3) > activeTabIndex" [ngClass]="{'complete': activeTabIndex == (isOneWay ? 2 : 3)}"> {{ (isOneWay ? 2 : 3) }} </i>
            <i *ngIf="(isOneWay ? 2 : 3)<activeTabIndex" [ngClass]="{'font-awesome':(isOneWay ? 2 : 3)<activeTabIndex}"> &#xf00c; </i>
            <span [ngClass]="{'active-tab':(isOneWay ? 2 : 3)==activeTabIndex || (isOneWay ? 2 : 3)<activeTabIndex, 'bold': activeTabIndex == (isOneWay ? 2 : 3)}">Passenger</span >
          </p>
        </a>
      </li>
      <li [ngClass]="{'one-way-step': isOneWay}" (click)="activeTabIndex == (isOneWay ? 4 : 5) ? goBack(isOneWay ? 4 : 5) : null">
        <div class="line-start" [ngClass]="{'active':(isOneWay ? 3 : 4)==activeTabIndex || (isOneWay ? 3 : 4)<activeTabIndex}"> </div>
        <div class="line-end" [ngClass]="{'active':(isOneWay ? 4 : 5)==activeTabIndex}"> </div>
        <a [ngClass]="{'active-section': (isOneWay ? 3 : 4) == activeTabIndex, 'cursor-auto': ((isOneWay ? 3 : 4) >= activeTabIndex || (isOneWay ? 4 : 5) == activeTabIndex)}">
          <p>
            <i *ngIf="(isOneWay ? 3 : 4)==activeTabIndex || (isOneWay ? 3 : 4)>activeTabIndex" [ngClass]="{'complete': activeTabIndex == (isOneWay ? 3 : 4)}"> {{ (isOneWay ? 3 : 4) }} </i>
            <i *ngIf="(isOneWay ? 3 : 4)<activeTabIndex" [ngClass]="{'font-awesome':(isOneWay ? 3 : 4)<activeTabIndex}"> &#xf00c; </i>
            <span [ngClass]="{'active-tab':(isOneWay ? 3 : 4)==activeTabIndex || (isOneWay ? 3 : 4)<activeTabIndex, 'bold': activeTabIndex == (isOneWay ? 3 : 4)}">Payment</span>
          </p>
        </a>
      </li>
      <li [ngClass]="{'one-way-step': isOneWay}">
        <div class="line-start" [ngClass]="{'active':(isOneWay ? 4 : 5)==activeTabIndex}"> </div>
        <!-- <div class="line-end" [ngClass]="{'active':5==activeTabIndex}"> </div> -->
        <a class="cursor-auto">
          <p>
            <!-- <i *ngIf="5==activeTabIndex || 5>activeTabIndex" [ngClass]="{'complete': activeTabIndex == 5}"> 5 </i> -->
            <!-- <i *ngIf="5<activeTabIndex" [ngClass]="{'font-awesome':5<activeTabIndex}"> &#xf00c; </i> -->
            <i *ngIf="(isOneWay ? 4 : 5)>activeTabIndex" [ngClass]="{'complete': activeTabIndex == (isOneWay ? 4 : 5)}"> {{ (isOneWay ? 4 : 5) }} </i>
            <i *ngIf="(isOneWay ? 4 : 5) == activeTabIndex" [ngClass]="{'font-awesome':(isOneWay ? 4 : 5) == activeTabIndex}"> &#xf00c; </i>
            <span [ngClass]="{'active-tab':(isOneWay ? 4 : 5)==activeTabIndex, 'bold': activeTabIndex == (isOneWay ? 4 : 5)}">Confirmation</span>
          </p>
        </a>
      </li>
      <!-- <li>
        <div class="line-start" [ngClass]="{'active':5==activeTabIndex}"> </div>
        <div class="line-end" [ngClass]="{'active':5==activeTabIndex}"> </div>
      </li> -->
    </ul>
    <div class="clear"></div>
  </div>

</div>
