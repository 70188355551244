
<div class="our-advantages">
    <div class="container">
      <h3 class="header">
        Why Runtofly?
      </h3>
      <div class="boxes">
        <div class="box">
          <div class="icon first">
            <img src="../../../../assets/img/icons/lastminute2.svg" alt="">
          </div>
          <h4 class="caption">
            Last minute flights for real
          </h4>
          <div class="text">
            All our flights are really last minute flights with outbound departure up until the day after tomorrow. 
            We negotiate with airlines to offer flexible travellers the best price for seats which are still empty close to departure. 
          </div>
        </div>
        <div class="box">
          <div class="icon deal_icon">
            <img src="../../../../assets/img/icons/deal_icon.png" alt="">
          </div>
          <h4 class="caption">
            New deals added continually
          </h4>
          <div class="text">
            We work hard negotiating with airlines to continually improve our offering of last minute 
            flights with better prices and new destinations, departure airports and airlines.
          </div>
        </div>
    
        <div class="box">
          <div class="icon extra-light">
            <img src="../../../../assets/img/icons/secure_icon.png" alt="">
          </div>
          <h4 class="caption">
            Secure, transparent and simple
          </h4>
          <div class="text">
            We are IATA accredited agent. Prices are 100% transparent with no hidden charges and fees. 
            Our payments are secure. Booking our lastminute flights is simple and quick.
          </div>
        </div>
      </div>
    </div>
  </div>