<div class="flight-details-container" [ngClass]="{'no-margin': confirmation_page}">
  <div class="container-fluid">
    <div class="row">
      <div class="global-flight-details-header">
        <img *ngIf="type === 'outbound'" class="departure-plane" src="assets/img/icons/departure2.svg" alt="img">
        <img *ngIf="type != 'outbound'" class="return-plane" src="assets/img/icons/return2.svg" alt="img">
        <h4>
          <span class="mob-remove-7">{{type == 'outbound' ?'Outbound:' : 'Inbound:'}}</span>
          {{tickets[0].departure.code}}
          <span class="arrow-icon">
            <img src="assets/img/icons/right-arrow-dark.svg" alt="img" class="right-arrow">
          </span>
          {{ tickets[tickets.length -1].arrival.code + ','}}
          <span class="mob-remove-6">{{tickets[0].departure.date | amDateFormat:'ddd DD MMM'}}</span>
        </h4>
        <div class="time">
          <img src="assets/img/icons/timer-icon.svg" alt="img">
          <span class="only-for-desktop">{{tickets.length > 1 ? 'Travel' : 'Flight'}} duration:</span>
          <span *ngIf="tickets.length == 1" class="stop"> {{ tickets[0].duration | momentDiff: 'shortened' }},
            Direct</span>
          <span *ngIf="tickets.length > 1" class="stop"> {{totalDuration | momentDiff: 'shortened' }}, {{tickets.length
            - 1 + (tickets.length - 1 > 1 ? ' stops' : ' stop') }}</span>
        </div>
      </div>
      <div class="global-flight-details">
        <!-- flight -->
        <div class="flight top" *ngFor="let ticket of tickets; let i=index">
          <app-flight-leg-details 
            [ticket]='ticket' 
            [isFirst]='i==0' 
            [isLast]='i==tickets.length-1'
            [highlightDeparture]='highlightDeparture' 
            [highlightArrival]='highlightArrival'
            [nextTicket]='i!=tickets.length-1 ? tickets[i+1] : null' 
            [highlightBag]='highlightBag'
            [fareFamily]='flight.fareFamily'
            [inboundFareFamily]='flight.inboundFareFamily'
            [flightDetailsType]="type"
            [fareRulesType]="fareRulesType">
          </app-flight-leg-details>
        </div>
      </div>
      
      <ng-container *ngIf="showCountryMessage">
        <div class="country-message">
          <app-country-message></app-country-message>
        </div>
      </ng-container>

      <ng-container *ngIf="flight.isOtherOutbound && type != 'return'">
        <div class="country-message">
          <app-departure-arrival-diff [isOtheroutbound]="flight.isOtherOutbound"></app-departure-arrival-diff>
        </div>
      </ng-container>

      <ng-content></ng-content>
    </div>
  </div>
  <!-- <div class="info-section" *ngIf="flight.isOtherOutbound && type === 'outbound' && returnPage">
    <div class="info-message">
      <span>
        <span class="icon">i</span>
      </span>
      <p>Please note that this outbound flight is different from the one you chose in the homepage departure board.</p>
    </div>
  </div> -->
</div>