import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { Ticket } from 'app/shared/index';
import { ConfigService } from 'services/config/config.service';
import { checkBaggageType } from 'app/shared/index';
import { FlightManagerService } from 'services/flight-manager/flight-manager.service';
import { Subscription } from 'rxjs';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Component({
    selector: 'app-flight-leg-details',
    templateUrl: './flight-leg-details.component.html',
    styleUrls: ['./flight-leg-details.component.scss']
})
export class FlightLegDetailsComponent implements OnInit, OnDestroy {
    @Input('ticket') ticket: Ticket;
    @Input('nextTicket') nextTicket: Ticket;
    @Input('isFirst') isFirst: boolean;
    @Input('isLast') isLast: boolean;
    @Input('highlightDeparture') highlightDeparture: boolean;
    @Input('highlightArrival') highlightArrival:boolean;
    @Input('highlightBag') highlightBag:boolean;
    @Input('fareRulesType') fareRulesType:number;
    @Input('fareFamily') fareFamily:string;
    @Input('inboundFareFamily') inboundFareFamily:string;
    @Input('flightDetailsType') flightDetailsType: string = 'outbound';
    
    typeOfBag:number;
    hovered: boolean = false;
    overnightHovered: boolean = false;
    nightOver: boolean = false;
    nightOvertStop: boolean = false;
    fareRulesIsOpen = false;
    imgUrl: string;
    operatedByShortText = '';
    subscribtion: Subscription;
    fareRules: string;


    constructor(
        private config: ConfigService,
        private flightManagerService: FlightManagerService,
        private sanitizer: DomSanitizer
    ) {
        this.imgUrl = this.config.get('IMAGE_HOST_URL');
    }

    ngOnInit() {
       this.subscribtion = this.flightManagerService.fareRulesOfFlight.subscribe((data)=>{
            this.fareRules = data;
        })
        this.makeTrustedHtml(this.ticket.baggage.longDescription);
        this.checkOperatedByTextLength(this.ticket);
        let response = checkBaggageType(this.ticket.baggage);
        if(response === 'isSmallBag') {
            this.typeOfBag = 1;
        }
        else if (response === 'isCabinBag') {
            this.typeOfBag = 2;
        }
        else if(response === 'isCheckedBag'){
            this.typeOfBag = 3;
        }

        if(this.ticket.arrival.date.day() - this.ticket.departure.date.day() != 0) {
            this.nightOver = true;
        }

        if(this.nextTicket?.departure.date.day() - this.ticket?.arrival.date.day() != 0) {
            let diff = this.ticket?.arrival.date.diff(this.nextTicket?.departure.date, 'hours', true);
            
            diff = diff < 0 ? diff * -1 : diff
            if(diff > 8) {
                this.nightOvertStop = true;
            }
        }
    }

    makeTrustedHtml(item: string | SafeHtml) {
        if(typeof item === 'string')
            item = this.sanitizer.bypassSecurityTrustHtml(item); 
    }

    checkOperatedByTextLength(ticket: Ticket) {
        if(ticket?.operatedByAirline?.name?.length > 20) {
            this.operatedByShortText = ticket.operatedByAirline.name.substring(0,17);
            this.operatedByShortText += '...'
        }
    }

    getFareFamily() {
        if (this.flightDetailsType === "outbound") {
            if (!this.fareFamily) return ""
            return ` (${this.fareFamily})`
        } else {
            if (!this.inboundFareFamily) return ""
            return ` (${this.inboundFareFamily})`
        }
    }

    openTooltip() {
        this.hovered = !this.hovered
    }

    ngOnDestroy(): void {
        if(this.subscribtion) {
            this.subscribtion.unsubscribe();
        }
    }
}
