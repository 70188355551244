<div class="global-info-wrapp yellow" [ngClass]="{'flex-column': isContentOpen, 'fixed-height': !isContentOpen }">
    <div>
        <p>
            <span class="icon">!</span>
            <span class="text">
                <span class="title">USA Visa Waiver Requirements - Electronic System for Travel Authorisation(ESTA) 
                    ... <span class="btn-show" *ngIf="!isContentOpen" (click)="isContentOpen = !isContentOpen">Show more</span>
                </span>
                <span *ngIf="isContentOpen">
                    Since 12 January 2009 all travellers wishing to
                    enter or transit through the USA under the Visa Waiver Program (VWP) must apply for authorisation to
                    travel using the Electronic System for Travel Authorisation (ESTA). You can complete the <a
                        href="https://esta.cbp.dhs.gov/esta" target="_blank"> ESTA application</a>
                    online in just a few minutes.
                    The majority of ESTA applications are processed instantly and approved within a minute of
                    submission.
                    However, please
                    be aware that there are rare cases where a decision on an application may be delayed by up to 72
                    hours. <span class="btn-show" *ngIf="isContentOpen" (click)="isContentOpen = !isContentOpen">Show less</span>
                </span>
            </span>
        </p>
    </div>
</div>