import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-keep-waiting',
  templateUrl: './keep-waiting.component.html',
  styleUrls: ['./keep-waiting.component.scss']
})
export class KeepWaitingComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
