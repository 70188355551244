import { Component, OnInit, ChangeDetectionStrategy, ElementRef, ViewChild } from '@angular/core';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';

@Component({
  selector: 'app-customer-reviews',
  templateUrl: './customer-reviews.component.html',
  styleUrls: ['./customer-reviews.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CustomerReviewsComponent implements OnInit {
  @ViewChild('reviewsContainer') reviewsContainer: ElementRef;


  public reviews = [
    {
      reviewText: "Flew to NYC last minute for just £250 return! Great bargain..one I could not pass up",
      name: "Jenny R.",
      score: 5
    },
    {
      reviewText: "Bought a ryanair flight with runtofly. Price was actually cheaper than ryanair website. All good!",
      name: "Emiro B.",
      score: 5
    },
    {
      reviewText: "Good service for flexible people like me!",
      name: "John G.",
      score: 5
    },
    {
      reviewText: "Good platform. Straightforward and with good last second offers. Sam",
      name: "Samir L.",
      score: 5
    },
    {
      reviewText: "I was a bit hesitant at the beginning as I had never heard of Runtofly, but deals were great and booking straightforward. Will be a repeat customer!",
      name: "Misch A.",
      score: 5
    },
    {
      reviewText: "Easy to book and very good last minute bargains. Thank you runtofly!",
      name: "GIANMARIA",
      score: 5
    },
    {
      reviewText: "I booked a flight for the very same night...It was absolutely hassle-free and at great price. A great way to kill boredom! Will do it again.",
      name: "KV",
      score: 5
    },
  ];

  public transformStyle: any;

  constructor(
    private sanitizer: DomSanitizer
  ) { }

  ngOnInit() { }

  onPreviousClick() {
    this.reviewsContainer.nativeElement.scrollLeft -= this.calcStep();
  }

  onNextClick() {
    this.reviewsContainer.nativeElement.scrollLeft += this.calcStep();
  }

  calcStep() {
    let reviewsDisplayCount = 1;
    if(this.reviewsContainer.nativeElement.clientWidth > 670) {
      reviewsDisplayCount = 3;
    }

    const margin = reviewsDisplayCount * 10;
    const reviewSize = ((this.reviewsContainer.nativeElement.clientWidth - margin) / reviewsDisplayCount);
    const step =  reviewSize;
    return step;
  }

  onScroll(event) {
  }

}
