
import { Injectable, EventEmitter } from '@angular/core';
import * as moment from 'moment';
import { ConfigService } from 'services/config/config.service';

@Injectable()
export class ClockService {
    readonly time: moment.Moment;
    readonly tick: EventEmitter<number> = new EventEmitter();
    readonly onFlightBoardExpired = new EventEmitter();
    readonly checkExpiredFlights = new EventEmitter();
    constructor(private configService:ConfigService) { 
        this.time = moment();
        setInterval(()=>{
            const change = moment().diff(this.time);
            this.time.add(change, 'ms');
            this.tick.emit(change);
        },100);

        const autoRefreshInterval = this.configService.get('AUTO_REFRESH_INTERVAL_MINUTES');
        setInterval(()=>{
            this.onFlightBoardExpired.emit();
        }, 1000 * 60 * autoRefreshInterval)


        setInterval(()=>{
            this.checkExpiredFlights.emit();
        },1000 * 60)
    }
}
