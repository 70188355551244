import { Component, Input, Output, OnInit, EventEmitter, OnChanges, SimpleChanges, OnDestroy, ViewChild, ElementRef } from '@angular/core';
import { Flight, FlightFilter, toggle } from 'app/shared';
import { HttpClient } from '@angular/common/http';
import { stringArrLowerCaseCompare, compareStops } from '../../shared/utils';

import { FlightManagerService } from 'app/services/flight-manager/flight-manager.service';
import { FiltersService } from 'app/services/filters/filters.service';
import { Options } from '@angular-slider/ngx-slider';
import { Subscription } from 'rxjs';
import { FilterDropdownComponent } from './filter-dropdown/filter-dropdown.component';
import * as moment from 'moment';


export class FilterItem {
    text: string;
    secondText: string;
    isChecked: boolean;
    count: number;
    filterBy: any;

    constructor(text: string, isChecked: boolean, filterBy: any, count?: number, secondText?: string) {
        this.text = text;
        this.isChecked = isChecked;
        this.filterBy = filterBy;
        this.count = count;
        this.secondText = secondText;
    }

    get value() {
        return this.text;
    }

    get number() {
        return this.count;
    }
}

@Component({
    selector: 'app-flights-filter',
    templateUrl: './flights-filter.component.html',
    styleUrls: ['./flights-filter.component.css'],
    animations: [toggle]
})

export class FlightsFilterComponent implements OnInit, OnChanges, OnDestroy {
    private _flights: Array<Flight>;

    @Input('flights') set flights(value) {
        this._flights = value;
        this.setupFilterValues();
    }

    get flights() {
        return this._flights;
    }

    @Input('notFilteredFlights') notFilteredFlights: Array<Flight>;
    @Input('desktop') desktop: boolean = false;


    @Output('optionChecked') optionChecked: EventEmitter<FlightFilter> = new EventEmitter();
    @Output('onApply') onApply: EventEmitter<FlightFilter> = new EventEmitter();
    @Output('onReset') onReset: EventEmitter<FlightFilter> = new EventEmitter();
    @Output('onClose') onClose: EventEmitter<FlightFilter> = new EventEmitter();

    @Input('mobile') mobile:boolean = false;

    @ViewChild('stopsRef') stopsElement: FilterDropdownComponent;
    @ViewChild('airlineRef') airlineElement: FilterDropdownComponent;
    @ViewChild('priceRef') priceElement: FilterDropdownComponent;
    @ViewChild('timeLeftRef') timeLeftElement: FilterDropdownComponent;
    @ViewChild('destinationRef') destinationElement: FilterDropdownComponent;
    @ViewChild('departureTimeRef') departureTimeElement: FilterDropdownComponent;

    public previousFilters: FlightFilter = new FlightFilter();

    
    filtersConfig: any;

    departureTimeItems: FilterItem[] = [];
    destinationItems: FilterItem[] = [];
    stopItems: FilterItem[] = [];
    airlineItems: FilterItem[] = [];
    hoursItems: FilterItem[] = [];
    
    optionsForPrice: Options = {};
    optionsForTime: Options = {};
    

    searchDestination: string = '';
    searchAirline: string = '';
    isActive: boolean;
    allCollapsed: boolean = true;

    typeOfAppliedFilter: string;

    setUpFilters$: Subscription;

    constructor(
        private http: HttpClient,
        public flightManagerService: FlightManagerService,
        public filtersService: FiltersService
    ) { }

    _updateFilterPreviousState() {
        this.previousFilters.departureTime = this.filtersService.filters.departureTime.slice();
        this.previousFilters.destinations = this.filtersService.filters.destinations.slice();
        this.previousFilters.airlines = this.filtersService.filters.airlines.slice();
        this.previousFilters.stops = this.filtersService.filters.stops.slice();
        this.previousFilters.price = this.filtersService.filters.price.slice();
        this.previousFilters.timeLeft = this.filtersService.filters.timeLeft.slice();
    }

    isApplyButtonActive(filter: string) {
        this.isActive = false;
        this.previousFilters[filter].map((item) => {
            this.isActive = this.filtersService.filters[filter].indexOf(item) == -1;
        })
        return this.isActive || this.previousFilters[filter].length != this.filtersService.filters[filter].length;
    }

    _calcCounts(arr) {
        let obj = {};
        for (var i = 0; i < arr.length; i++) {
            if (obj[arr[i]]) {
                obj[arr[i]]++
            }
            else {
                obj[arr[i]] = 1
            }
        }
        return obj;
    }

    _updateCount(items: FilterItem[], filterByValue: any, count: number, text?: string, secondText?: string) {
        let targetItem = items.find((item) => {
            return item.filterBy === filterByValue;
        });

        if (!targetItem) {
            targetItem = new FilterItem(text, false, filterByValue, count, secondText);
            return items.push(targetItem);
        }
        targetItem.count = count;
    }

    ngOnInit() {
        this.http.get("./assets/config/filters.json")
            .subscribe((res: any) => {
                this.filtersConfig = res;
                this.setupFilterValues();
            }, (err) => {
                console.log(err)
            })

            this.setUpFilters$ = this.filtersService.setUpFilters.subscribe(()=>{
                this.reset();
            })
    }

    setupFilterValues() {
        if (!this.filtersConfig) return;
        if(!this.typeOfAppliedFilter) {
            this.setUpDestination();
            this.setUpStops();
            this.setUpdepartureTime();
            this.setUpAirline();
            this.setUpPriceForDesktop();
            this.setUpHoursForDesktop();
        }
    }

    filterBy(by: string, value: number | string) {
        let index = this.filtersService.filters[by].indexOf(value);
        if (index > -1) {
            // if the item exists already then we need to remove it from the list
            this.filtersService.filters[by].splice(index, 1);
        } else {
            // if the item doesn't exist then we need to add it in the list
            this.filtersService.filters[by].push(value);
        }
        return this.isApplyButtonActive(by);
    }

    setUpDestination() {
        // this part is for mobile 
        // we don't want to update section which was clicked the last
        if(!this.desktop && this.filtersService.typeOfAppliedFilterMobile === 'Destination' && this.filtersService.filters.destinations.length > 0) {
            this.destinationItems = [...this.filtersService.destinationItems];
            return
        }else if(!this.desktop && this.filtersService.typeOfAppliedFilterMobile === 'Destination' && this.filtersService.filters.destinations.length === 0) {
            this.filtersService.typeOfAppliedFilterMobile = '';
            this.filtersService.destinationItems = [];
        }

        this.resetCounts(this.filtersService.destinationItems);

        let destinations = {};
        this.flights.map(flight => {
            const lastIndex = flight.outboundTickets.length - 1;
            const worldArea = flight.outboundTickets[lastIndex].arrival.worldArea;
            if (!worldArea) return {};

            const worldAreaKey = worldArea.toString();
            const worldAreaObj = destinations[worldAreaKey]
            if (worldAreaObj) {
                worldAreaObj.count++;
                worldAreaObj.minPrice = Math.min(flight.price, worldAreaObj.minPrice);
                worldAreaObj.name = worldArea;
            } else {
                destinations[worldAreaKey] = { count: 1, minPrice: flight.price, name: worldArea };
            }
        });
        
        Object.keys(destinations)
            .sort(function(a,b) {return destinations[b].count - destinations[a].count })
            .map((destination) => {

                // setup min prices from scretch but don't delete whole array
                this.filtersService.destinationItems.forEach((item)=>{
                    if(item.filterBy === destination){
                        item.secondText = destinations[destination].minPrice;
                    }
                    
                });
                this._updateCount(this.filtersService.destinationItems, destination, destinations[destination].count,destinations[destination].name,destinations[destination].minPrice);
            })

        this.updateExistingItems(this.filtersService.destinationItems);
        this.destinationItems = [];
        this.destinationItems = [...this.filtersService.destinationItems];
    }

    setUpStops() {
        if(!this.desktop && this.filtersService.typeOfAppliedFilterMobile === 'Stops' && this.filtersService.filters.stops.length > 0) {
            this.stopItems = [...this.filtersService.stopItems];
            return
        }else if(!this.desktop && this.filtersService.typeOfAppliedFilterMobile === 'Stops' && this.filtersService.filters.stops.length === 0) {
            this.filtersService.typeOfAppliedFilterMobile = '';
            this.filtersService.stopItems = [];
        }

        this.resetCounts(this.filtersService.stopItems);

        let countOfStops = {};
        this.flights.map(flight => {
            let index: string | number = flight.outboundTickets.length - 1;
            if (index == 0)
                index = 'Direct';

            if (countOfStops[index]) {
                countOfStops[index].count++
                countOfStops[index].minPrice = Math.min(countOfStops[index].minPrice, flight.price)
            }
            else
                countOfStops[index] = { count: 1, minPrice: flight.price };
        })

        Object.keys(countOfStops)
            .sort(compareStops)
            .map((stop) => {
                let text = stop;
                if (text != 'Direct')
                    text += ' Stop';
    
                    // // setup min prices from scretch but don't delete whole array
                    this.filtersService.stopItems.forEach((item)=>{
                        if(item.filterBy === stop){
                            item.secondText = countOfStops[stop].minPrice;
                        }
                        
                    });

                this._updateCount(this.filtersService.stopItems,stop, countOfStops[stop].count, text, countOfStops[stop].minPrice);
            })
            this.updateExistingItems(this.filtersService.stopItems);

            this.stopItems = [];
            this.stopItems = [...this.filtersService.stopItems];
    }

    setUpAirline() {
        if(!this.desktop && this.filtersService.typeOfAppliedFilterMobile === 'Airline' && this.filtersService.filters.airlines.length > 0) {
            this.airlineItems = [...this.filtersService.airlineItems];
            return
        }else if(!this.desktop && this.filtersService.typeOfAppliedFilterMobile === 'Airline' && this.filtersService.filters.airlines.length === 0) {
            this.filtersService.typeOfAppliedFilterMobile = '';
            this.filtersService.airlineItems = [];
        }

        this.resetCounts(this.filtersService.airlineItems)
        let airlines = this.flights.map(flight => {
            if(flight.outboundTickets[0].airline.name != flight.outboundTickets[flight.outboundTickets.length -1].airline.name) {
                return 'Multiple airlines'
            }
            return flight.outboundTickets[0].airline.name || 'Undisclosed';
        })

        let countOfAirlines = this._calcCounts(airlines);
        
        Object.keys(countOfAirlines)
            .sort(stringArrLowerCaseCompare)
            .map((item) => {
                const filterBy = item === 'Undisclosed' ? null : item;
                this._updateCount(this.filtersService.airlineItems, filterBy, countOfAirlines[item], item);
            });

        this.updateExistingItems(this.filtersService.airlineItems);

        this.airlineItems = [];
        this.airlineItems = [...this.filtersService.airlineItems];
    }

    setUpdepartureTime() {
        if(!this.desktop && this.filtersService.typeOfAppliedFilterMobile === 'Departure Time' && this.filtersService.filters.departureTime.length > 0) {
            this.departureTimeItems = [...this.filtersService.departureTimeItems];
            return
        }else if(!this.desktop && this.filtersService.typeOfAppliedFilterMobile === 'Departure Time' && this.filtersService.filters.departureTime.length === 0) {
            this.filtersService.typeOfAppliedFilterMobile = '';
            this.filtersService.departureTimeItems = [];
        }

        this.resetCounts(this.filtersService.departureTimeItems)
        const localDate = moment();
        let departureTimes = this.flights.map(flight => {
            if (flight.outboundTickets[0].departure.date.days() === localDate.day()) return "Today";
            else if (
                flight.outboundTickets[0].departure.date.days() - localDate.day() === 1 ||
                flight.outboundTickets[0].departure.date.days() - localDate.day() === -6
            ) return "Tomorrow";
            return "The day after tomorrow";
        })

        let countOfdepartureTimes = this._calcCounts(departureTimes.sort((a: any,b: any) => {
            if (a === "Today") return -1;
            if (b === "Today") return 1;
            if (a === "Tomorrow") return b === "Today" ? 1 : -1;
            if (b === "Tomorrow") return a === "Today" ? -1 : 1;
            return a.localeCompare(b);
        }))

        Object.keys(countOfdepartureTimes)
            .map((item) => {
                this._updateCount(this.filtersService.departureTimeItems, item, countOfdepartureTimes[item], item);
            });
        this.updateExistingItems(this.filtersService.departureTimeItems);

        this.departureTimeItems = [];
        this.departureTimeItems = [...this.filtersService.departureTimeItems];
    }

    setUpPrice() {
        this.filtersConfig.price.map((price, index) => {
            let count = 0;
            this.notFilteredFlights.map((flight) => {
                if (flight.price >= price.min
                    && Math.ceil(flight.price) <= price.max) {
                    count++;
                }
            })
            let text = `${price.min}-${price.max}`;
            let filterByValue = text;
            if (index == this.filtersConfig.price.length - 1)
                text = `${price.min}+`;

            this._updateCount(this.filtersService.priceItems, filterByValue, count, text);
        })
    }

    setUpPriceForDesktop() {
        if(this.filtersService.filters.price.length > 0) {
            let result = this.getMinMaxPriceFromUnfilteredFlights();
            let text = result.min + '-' + result.max;

            this.setUpPriceOptions(result.min,result.max);
            this.filtersService.priceItems.push(new FilterItem(text,false,text));
            return
        }else if(this.filtersService.filters.price.length === 0 && this.filtersService.typeOfAppliedFilterMobile === 'Price') {
            this.filtersService.typeOfAppliedFilterMobile = '';
            this.filtersService.priceItems = [];
        }

        let max = 0;
        let min = Math.ceil(this.flights[0].price);
        this.flights.forEach((flight)=> {
            if(flight.price > max) {
                max = Math.ceil(flight.price);
            }
            if(flight.price < min) {
                min = Math.ceil(flight.price);
            }
        })
        let text = min + '-' + max;
        this.filtersService.priceLowValue = min;
        this.filtersService.priceHighValue = max;

        //if prices are same no sense to show price slider
        if(min != max) {
            this.setUpPriceOptions(min,max); 
        }else {
            this.optionsForPrice = null;
        }

        this.filtersService.priceItems.push(new FilterItem(text,false,text));
    }

    setUpPriceOptions(min:number, max: number) {
      this.optionsForPrice = {
            floor: min,
            ceil: max,
            step: 1,
            minRange: 0,
            translate: (value: number): string => {
              return '£' + value;
            }
        }
  }

  getMinMaxPriceFromUnfilteredFlights() {
    let max = 0;
    let min = Math.ceil(this.notFilteredFlights[0].price);
    this.notFilteredFlights.forEach((flight)=> {
        if(flight.price > max) {
            max = Math.ceil(flight.price);
        }
        if(flight.price < min) {
            min = Math.ceil(flight.price);
        }
    })

    return {min: min, max: max};
  }


  getMinMaxTimeFromUnfilteredFlights() {
    let min = this.notFilteredFlights[0].timeLeft.days() * 24 + this.notFilteredFlights[0].timeLeft.hours();
    let max = this.notFilteredFlights[0].timeLeft.days() * 24 + this.notFilteredFlights[0].timeLeft.hours();

    this.notFilteredFlights.forEach((flight)=>{
        const flightHours = flight.timeLeft.days() * 24 + flight.timeLeft.hours();
        if(flightHours < min) {
            min = flightHours;
        }
        if(flightHours > max) {
            max = flightHours;
        }
    })

    min += 1;
    max += 1;

    return {min: min, max: max};
  }

    setUpHours() {
        this.filtersConfig.hoursLeft.map((hour) => {
            let count = 0;
            this.notFilteredFlights.map((flight) => {
                if (flight.timeLeft.asHours() >= hour.min
                    && flight.timeLeft.asHours() < hour.max) {
                    count++;
                }
            })

            let text = `${hour.min}-${hour.max}`;
            this._updateCount(this.filtersService.hoursItems, text, count, `${text} hrs`);
        });
    }

    setUpHoursForDesktop() {
        if(this.filtersService.filters.timeLeft.length > 0) {
            let result = this.getMinMaxTimeFromUnfilteredFlights();
            let text = result.min + '-' + result.max;

            this.setUpHoursOptions(result.min,result.max);
            this.filtersService.hoursItems.push(new FilterItem(text,false,text));
            return
        }else if(this.filtersService.filters.timeLeft.length === 0 && this.filtersService.typeOfAppliedFilterMobile === 'Time left') {
            this.filtersService.typeOfAppliedFilterMobile = '';
            this.filtersService.hoursItems = [];
        }


        let min = this.flights[0].timeLeft.days() * 24 + this.flights[0].timeLeft.hours();
        let max = this.flights[0].timeLeft.days() * 24 + this.flights[0].timeLeft.hours();

        this.flights.forEach((flight)=>{
            const flightHours = flight.timeLeft.days() * 24 + flight.timeLeft.hours();
            if(flightHours < min) {
                min = flightHours;
            }
            if(flightHours > max) {
                max = flightHours;
            }
        })

        // if time have minutes than we consider it as 1 hour more
        min = min;
        max= max;
        
        let text = min + '-' + max

        this.filtersService.timeLowValue = min;
        this.filtersService.timeHighValue = max;

        //if times are same no sense to show time slider
        if(min != max) {
            this.setUpHoursOptions(min,max);
        }else {
            this.optionsForTime = null;
        }

        this.filtersService.hoursItems.push(new FilterItem(text,false,text));
    }

    setUpHoursOptions(min,max) {
        this.optionsForTime = {
          floor: min,
          ceil: max,
          step: 1,
          minRange: 0,
          translate: (value: number): string => {
            return value + ' hrs' ;
          }
        }
    }

    resetCounts(items: Array<FilterItem>) {
        items.forEach((item)=>{
            if(item.isChecked === false) {
                item.count = 0;
            }
        })
    }

    updateExistingItems(items: Array<FilterItem>) {
        for(let i=0;i<items.length;i++) {
            if(items[i].count === 0) {
                items.splice(i,1);
                i = i - 1;
            }
        }
    }

    selectAll(firstItem: string, secondItem?: string) {
        firstItem = arguments[0].firstItem;
        secondItem = arguments[0].secondItem
        this[firstItem].map((item) => {
            item.isChecked = true;
        });
        this.filtersService.filters[secondItem] = [];
    }

    close() {
        this.onClose.emit();
    }

    reset() {
        setTimeout(()=>{
            this.setUpDestination();
            this.setUpStops();
            this.setUpdepartureTime();
            this.setUpAirline();
            this.setUpPriceForDesktop();
            this.setUpHoursForDesktop();
        })

        this.countNumberOfActiveFilters();
        this.onReset.emit(this.filtersService.filters);
    }

    clearAll() {
        this.filtersService.resetFilters();
        this.reset();
    }

    applyFilter() {
        this._updateFilterPreviousState();

        if(this.desktop) {
            this.countNumberOfActiveFilters();
        }

        if(!this.filtersService.filtersHasLength()) {
            this.reset();
        }
        this.onApply.emit(this.filtersService.filters);
    }

    optionIsClicked() {
        this.countNumberOfActiveFilters();
        this._updateFilterPreviousState();
        if(!this.filtersService.filtersHasLength()) {
            this.filtersService.typeOfAppliedFilterMobile = '';
            this.reset();
        }
        this.optionChecked.emit(this.filtersService.filters);
    }

    updateCountAfterFilterApplied(type: string) {
        this.typeOfAppliedFilter = type;
        this.filtersService.typeOfAppliedFilterMobile = type;
    }

    checkIfFiltersAreEmpty(items): boolean {
        let isChecked = true;
        items.forEach((item)=>{
            if(item.isChecked){
                isChecked = false;
            }
        })

        return isChecked;
    }

    setUpFilterValuesByType(typeOfAppliedFilter) {
        if(typeOfAppliedFilter === 'Stops') {
            if(this.checkIfFiltersAreEmpty(this.filtersService.stopItems)) {
                this.setUpStops();
            }
            this.setUpdepartureTime();
            this.setUpAirline();
            this.setUpDestination();
            this.setUpPriceForDesktop();
            this.setUpHoursForDesktop();
        }else if(typeOfAppliedFilter === 'Departure Time') {
            if(this.checkIfFiltersAreEmpty(this.filtersService.departureTimeItems)) {
                this.setUpdepartureTime();
            }
            this.setUpAirline();
            this.setUpStops();
            this.setUpDestination();
            this.setUpPriceForDesktop()
            this.setUpHoursForDesktop();
        }else if(typeOfAppliedFilter === 'Price') {
            this.setUpdepartureTime();
            this.setUpAirline();
            this.setUpDestination();
            this.setUpStops();
            this.setUpHoursForDesktop();
        }else if(typeOfAppliedFilter === 'Destination') {
            if(this.checkIfFiltersAreEmpty(this.filtersService.destinationItems)) {
                this.setUpDestination();
            }
            this.setUpdepartureTime();
            this.setUpAirline();
            this.setUpStops();
            this.setUpPriceForDesktop()
            this.setUpHoursForDesktop();
        }else if(typeOfAppliedFilter === 'Time Left') {
            this.setUpdepartureTime();
            this.setUpAirline();
            this.setUpStops();
            this.setUpDestination();
            this.setUpPriceForDesktop()
        }
        else if(typeOfAppliedFilter === 'Airline') {
            if(this.checkIfFiltersAreEmpty(this.filtersService.airlineItems)) {
                this.setUpAirline();
            }
            this.setUpdepartureTime();
            this.setUpStops();
            this.setUpDestination();
            this.setUpPriceForDesktop()
            this.setUpHoursForDesktop();
        }
    }

    countNumberOfActiveFilters() {
        this.filtersService.countOfFilters = this.filtersService.filters.destinations.length + this.filtersService.filters.departureTime.length + this.filtersService.filters.airlines.length
        + this.filtersService.filters.stops.length + this.filtersService.filters.price.length + this.filtersService.filters.timeLeft.length;
    }

    ngOnChanges(changes: SimpleChanges) {
        if(this._flights && this.typeOfAppliedFilter) {
            this.setUpFilterValuesByType(this.typeOfAppliedFilter);
        }
    }

    ngOnDestroy() {
        if(this.setUpFilters$) {
            this.setUpFilters$.unsubscribe();
        }
    }

    expendAll() {
        this.allCollapsed = false;
        this.stopsElement.filterCollapse["Stops"] = true;
        this.airlineElement.filterCollapse["Airline"] = true;
        this.priceElement.filterCollapse["Price"] = true;
        this.timeLeftElement.filterCollapse["Time left"] = true;
        this.destinationElement.filterCollapse["Destination"] = true;
        this.departureTimeElement.filterCollapse["Departure Time"] = true;
        this.filtersService.updateHeightOfFilterContainer.next(true);
    }

    collapseAll() {
        this.allCollapsed = true;
        this.stopsElement.filterCollapse["Stops"] = false;
        this.airlineElement.filterCollapse["Airline"] = false;
        this.priceElement.filterCollapse["Price"] = false;
        this.timeLeftElement.filterCollapse["Time left"] = false;
        this.destinationElement.filterCollapse["Destination"] = false;
        this.departureTimeElement.filterCollapse["Departure Time"] = false;
        this.filtersService.updateHeightOfFilterContainer.next(true);
    }
}
