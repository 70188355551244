import { Component, OnInit, ViewEncapsulation, Input } from '@angular/core';
import * as moment from 'moment';

import { ConfigService } from 'app/services/config/config.service';
import { FullFlight, TicketOfOneWayFlight } from 'app/shared/index';



@Component({
  selector: 'app-cflight-details',
  templateUrl: './cflight-details.component.html',
  styleUrls: ['./cflight-details.component.css']
})

export class CflightDetailsComponent implements OnInit {
    @Input('fullFlight') fullFlight: FullFlight;
    @Input('type') type: string = 'outbound';
    @Input('highlightDepartureArivalDiff') highlightDepartureArivalDiff: boolean = true;
    @Input('confirmation_page') confirmation_page: boolean = false;

    highlightDeparture: boolean;
    highlightArrival: boolean;
    highlightBag: boolean = false;

    tickets: Array<TicketOfOneWayFlight>;
    imgUrl: string;
    totalDuration: moment.Duration = moment.duration(0, 'hours');

    constructor(private config: ConfigService) {
        this.imgUrl = this.config.get('IMAGE_HOST_URL');
    }
    ngOnInit() {
        this.tickets = this.fullFlight.outbound.legs;

        if (this.highlightDepartureArivalDiff) {
            this.highlightDeparture = !this.fullFlight.isDepartureAndArrivalSame;
            this.highlightArrival = !this.fullFlight.isArrivalAndDepartureSame;
        }


        if (this.type === 'return') {
            this.tickets = this.fullFlight.inbound.legs;

            if (this.highlightDepartureArivalDiff) {
                this.highlightDeparture = !this.fullFlight.isArrivalAndDepartureSame;
                this.highlightArrival = !this.fullFlight.isDepartureAndArrivalSame;
            }

            this.highlightBaggage();
        }
        this.getStop();
    }

    highlightBaggage() {
        if ((!this.fullFlight.isDepartureHasBag && this.fullFlight.isArrivalHasBag) || (this.fullFlight.isDepartureHasBag && !this.fullFlight.isArrivalHasBag)) {
            this.highlightBag = true;
        }
    }

    getStop() {
        this.tickets.map((ticket, index) => {
            this.totalDuration.add(ticket.duration);
            if (index > 0) {
                let previousTicket = this.tickets[index - 1];
                this.totalDuration.add(ticket.departureDate.diff(previousTicket.destinationDate));
            }
        })
    }

}
