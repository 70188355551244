
  
  <div *ngIf="featuredFlights" class="home-page-section-header">
    <h3 class="home-page-section-header-text">
      Our best last minute flights
    </h3>
  </div>
  
  <div *ngIf="featuredFlights" class="cards-section">
    <div class="row" *ngIf="featuredFlights.length>3">
      <div class="col-sm-8" *ngFor="let i=index ;let flight of featuredFlights">
        <div *ngIf="i<3" id="flight.id" (click)="scrollToFlightAccardion(flight.id)">
          <app-flight-card [flight]="flight">
          </app-flight-card>
        </div>
      </div>
    </div>
  
    <div>
      <div *ngFor="let flight of featuredFlights;let i=index">
        <div *ngIf="featuredFlights.length>3 && i==3" (click)="scrollToFlightAccardion(flight.id)">
          <app-img-card [flight]="flight">
          </app-img-card>
        </div>
        <div *ngIf="featuredFlights.length<=3 && i==0" (click)="scrollToFlightAccardion(flight.id)">
          <app-img-card [flight]="flight">
          </app-img-card>
        </div>
      </div>
    </div>
  </div>
  