import { Injectable } from '@angular/core';
import { EventEmitter } from '@angular/core';
import { Flight } from 'app/shared';

@Injectable()
export class ReturnFlightAccordionService {
    private onCloseCurrentAccordion: EventEmitter<void>;
    readonly onReturnFlightSelected: EventEmitter<Flight> = new EventEmitter();
    constructor() {}

    closeOpenedAccordion(onCloseNextAccordion: EventEmitter<void>){
        if(this.onCloseCurrentAccordion)
            this.onCloseCurrentAccordion.emit();
        this.onCloseCurrentAccordion = onCloseNextAccordion;
    }


    reset(){
        this.onCloseCurrentAccordion = null;
    }
}
