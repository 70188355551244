import { Component, EventEmitter, Input, OnInit, HostListener, Output } from '@angular/core';

import { FlightAccordionService } from 'app/components/flight-accordion/flight-accordion.service';

import { Flight, PassengerList, toggle } from 'app/shared/index';
import { Subscription, timer } from 'rxjs';
import * as moment from 'moment';
import { ClockService } from 'services/clock/clock.service';
import { AnalyticsService } from 'services/analytics/analytics.service';
import { RestService } from 'services/rest/rest.service';
import { finalize } from 'rxjs/operators';
import { Router } from '@angular/router'
import { AlertService } from 'services/alert/alert.service';
import { FlightManagerService } from 'services/flight-manager/flight-manager.service';

@Component({
    selector: 'app-flight-accordion',
    templateUrl: './flight-accordion.component.html',
    styleUrls: ['./flight-accordion.component.scss'],
    animations: [toggle]
})
export class FlightAccordionComponent implements OnInit {
    @Input('flight') flight: Flight;
    @Input('localDate') localDate: moment.Moment;
    @Input('searchText') searchText: string;

    onClose: EventEmitter<void> = new EventEmitter();
    @Output() updateIsLoading = new EventEmitter();

    innerWidth: number;
    UScode: string = "US";
    state: string = 'inactive';
    subscription: Subscription;
    animation_timer = null;
    showCityShortName: boolean = false;
    showCountryShortName: boolean = false;
    lastText: string;
    lastBookedText: string = null;
    lastViewedText: string = null;
    animate: boolean = false;
    multiple: boolean = false;
    isUndisclosed: boolean = false;
    showCountryMessage: boolean = false;
    isMobile: boolean = false;
    passengerList: PassengerList;

    constructor(
        private accordionService: FlightAccordionService,
        private clock: ClockService,
        private analyticsService: AnalyticsService,
        private rest: RestService,
        private router: Router,
        private alertService: AlertService,
        private flightManager: FlightManagerService,
    ) {
        this.subscription = this.onClose.subscribe(() => {
            this.state = 'inactive';
            if (this.animation_timer != null) {
                this.animation_timer.unsubscribe();
                this.animation_timer = null;
            }
        })
        this.passengerList = this.flightManager.passengerList;
    }

    private debounceTimer;
    private debounceDelay = 800;

    @HostListener('window:resize', ['$event'])
    onResize(event) {
        this.innerWidth = window.innerWidth;
        clearTimeout(this.debounceTimer);
        this.debounceTimer = setTimeout(() => {
            if (this.innerWidth < 370 && this.innerWidth > 340) {
                this.changeLongNames();
            } else if (this.innerWidth > 451) {
                this.showCityShortName = false;
                this.showCountryShortName = false;
            }

            if(this.innerWidth <= 650) {
                this.isMobile = true;
            }else {
                this.isMobile = false;
            }

        }, this.debounceDelay);
    }

    changeLongNames() {
        if (this.flight.outboundTickets[this.flight.outboundTickets.length - 1].arrival.city.length > 14) {
            this.flight.outboundTickets[this.flight.outboundTickets.length - 1].arrival.shortCity = this.flight.outboundTickets[this.flight.outboundTickets.length - 1].arrival.city.substring(0, 12) + '...';
            this.showCityShortName = true;
        }

        if (this.flight.outboundTickets[this.flight.outboundTickets.length - 1].arrival.country.length > 14 && !this.showCountryShortName) {
            this.flight.outboundTickets[this.flight.outboundTickets.length - 1].arrival.shortCountry =
            this.flight.outboundTickets[this.flight.outboundTickets.length - 1].arrival.country.substring(0, 14) + '.';
            this.showCountryShortName = true;
        }
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
        this.flight.stopCountDown();
        this.accordionService.deregister(this.flight.id);
    }
    ngOnInit() {
        this.checkAirlines(this.flight);
        this.checkFlightCountry(this.flight);

        this.innerWidth = window.innerWidth;
        this.accordionService.register(this.flight.id);
        this.localDate = this.localDate || moment();
        this.flight.startCountDown(this.clock);
        
        if (this.flight.viewersCount > 0) {
            this.lastViewedText = `${this.flight.viewersCount} people currently looking`;
        }

        if (this.flight.lastBookedAt) {
            this.lastBookedText = `Last booked ${moment(this.flight.lastBookedAt).fromNow()}`;
        }

        if (this.innerWidth < 370 && this.innerWidth > 340) {
            this.changeLongNames();
        }

        if(this.innerWidth < 650) {
            this.isMobile = true;
        }else {
            this.isMobile = false;
        }
        // if (this.flight.outboundTickets[this.flight.outboundTickets.length - 1].arrival.shortCity  && ((window.innerWidth < 700 && window.innerWidth > 650) || window.innerWidth < 441)) {
        //     this.showCityShortName = true;
        // }
    }

    checkAirlines(flight: Flight) {
        if (flight.outboundTickets.length > 1) {
            let airlineName = flight.outboundTickets[0].airline.name;
            flight.outboundTickets.forEach((ticket) => {
                if (ticket.airline.name != airlineName) {
                    this.multiple = true;
                    return
                }
            })
        }

        if (!this.flight.outboundTickets[0].airline.name) {
            this.flight.outboundTickets.forEach((ticket) => {
                if (!ticket.airline.name) {
                    this.isUndisclosed = true;
                } else {
                    this.isUndisclosed = false
                    return
                }
            })
        }
    }

    toggle() {
        this.state = this.state === 'active' ? 'inactive' : 'active';

        if (this.state === 'active') {
            this.accordionService.closeOpenedAccordion(this.onClose);
            this.animation_timer.unsubscribe();
            this.lastText = null;
        }
        else {
            this.animate = false;

            if (this.lastBookedText && this.lastViewedText) {
                this.animation_timer = timer(1000, 5000).subscribe(() => {
                    this.animate = false;

                    const nextText = this.lastText === this.lastBookedText ? this.lastViewedText : this.lastBookedText;
                    this.lastText = nextText;
                    this.animate = true;
                });
            } else if (this.lastBookedText || this.lastViewedText) {
                this.animation_timer = timer(1000).subscribe(() => {
                    this.lastText = this.lastBookedText || this.lastViewedText;
                    this.animate = true;
                });
            }

            this.accordionService.reset();

            const departure = this.flight.outboundTickets[0].departure.code;
            const arrival = this.flight.outboundTickets[this.flight.outboundTickets.length - 1].arrival.city;
            const airline = this.flight.outboundTickets[0].airline.name;
            const price = Math.round(this.flight.price);
            const timeLeft = this.flight.timeLeft;
            const flightDesc = `${departure}-${arrival}; ${airline}; ${price}; ${timeLeft}`
            this.analyticsService.sendEvent('Departure Board', 'Flight Expanded', flightDesc)

        }
    }

    selectFlight(flight: Flight) {
        localStorage.setItem('selectedFlight', flight.outboundTickets[0].flightNumber);
    }

    checkFlightCountry(flight: Flight) {
        if (flight.outboundTickets[flight.outboundTickets.length - 1].arrival.countryCode === this.UScode) {
            this.showCountryMessage = true;
        }

    }

    onPassengersUpdated(passengerList: PassengerList) {
        this.passengerList = passengerList;
    }

    navigateToPassengerPage() {
        this.updateIsLoading.emit(true);
        this.rest.getPriceBreakdown(this.flight.id, this.passengerList.adult, this.passengerList.children, this.passengerList.infants).pipe(
            finalize(() => {
                this.updateIsLoading.emit(false);
            }))
            .subscribe((data) => {
                this.flightManager.getPassengersDetails(data);
                this.router.navigate(['/wizard/passenger'], {
                    queryParams: {
                        bookingId: data.bookingId,
                        flightId: this.flight.id,
                        isOneWay: true,
                        adults: this.passengerList.adult,
                        children: this.passengerList.children,
                        infants: this.passengerList.infants
                    }
                });
            }, (err) => {
                this.updateIsLoading.emit(false);
                if(err.code === 1) {
                    this.alertService.errorCode_1(true);
                }else {
                    this.alertService.somethingBadHappened(true);
                }
                
            })
    }
}
