import { Component, OnInit, Input, OnDestroy } from '@angular/core';

import { TicketOfOneWayFlight } from 'app/shared/index';
import { ConfigService } from 'services/config/config.service';
import { AlertService } from 'app/services/alert/alert.service';
import { checkBaggageType } from 'app/shared/index';
import { FlightManagerService } from 'services/flight-manager/flight-manager.service';
import { Subscription } from 'rxjs';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';


@Component({
  selector: 'app-cflight-leg-details',
  templateUrl: './cflight-leg-details.component.html',
  styleUrls: ['./cflight-leg-details.component.css']
})

export class CflightLegDetailsComponent implements OnInit, OnDestroy {
    @Input('ticket') ticket: TicketOfOneWayFlight;
    @Input('nextTicket') nextTicket: TicketOfOneWayFlight;

    @Input('isFirst') isFirst: boolean;
    @Input('isLast') isLast: boolean;

    @Input('highlightDeparture') highlightDeparture: boolean;
    @Input('highlightArrival') highlightArrival:boolean;

    @Input('highlightBag') highlightBag:boolean;
    
    typeOfBag = 0;
    
    hovered: boolean = false;
    nightOver: boolean = false;
    nightOvertStop: boolean = false;

    fareRulesIsOpen = false;

    imgUrl: string;
    operatedByShortText = '';

    subscribtion: Subscription;

    fareRules: string;

    constructor(private config: ConfigService,
                private alertService: AlertService,
                private flightManagerService: FlightManagerService,
                private sanitizer: DomSanitizer) {
        this.imgUrl = this.config.get('IMAGE_HOST_URL');
    }

    ngOnInit() {

       this.subscribtion = this.flightManagerService.fareRulesOfFlight.subscribe((data)=>{
            this.fareRules = data;
        })
        this.makeTrustedHtml(this.ticket.baggage.longDescription);
        this.checkOperatedByTextLength(this.ticket);
        let response = checkBaggageType(this.ticket.baggage);
        if(response === 'isSmallBag') {
            this.typeOfBag = 1;
        }
        else if (response === 'isCabinBag') {
            this.typeOfBag = 2;
        }
        else if(response === 'isCheckedBag'){
            this.typeOfBag = 3;
        }


        if(this.ticket.destinationDate.day() - this.ticket.departureDate.day() != 0) {
            this.nightOver = true;
        }

        if(this.nextTicket?.departureDate.day() - this.ticket?.destinationDate.day() != 0) {
            let diff = this.ticket?.destinationDate.diff(this.nextTicket?.departureDate, 'hours', true);
            if(diff > 8) {
                this.nightOvertStop = true;
            }
        }
    }

    makeTrustedHtml(item: string | SafeHtml) {
        if(typeof item === 'string')
            item = this.sanitizer.bypassSecurityTrustHtml(item); 
    }

    checkOperatedByTextLength(ticket: TicketOfOneWayFlight) {
        if(ticket?.operatedByAirline?.name?.length > 20) {
            this.operatedByShortText = ticket.operatedByAirline.name.substring(0,17);
            this.operatedByShortText += '...'
        }
    }

    openTooltip() {
        this.hovered = !this.hovered
    }

    ngOnDestroy(): void {
        if(this.subscribtion) {
            this.subscribtion.unsubscribe();
        }
    }
}
