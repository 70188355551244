import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
    name: 'decimalToTimespan'
})
export class DecimalToTimespanPipe implements PipeTransform {

    transform(value: moment.Duration): string {
        let timeSpan = moment.duration(value, 'hours');
        // ToDo: Formating should be another pipe 

        let minutes: any = timeSpan.minutes();
        let hours: any = timeSpan.hours();

        if (parseInt(minutes / 10 + '') == 0) {
            minutes = '0' + minutes;
        }
        if (parseInt(hours / 10 + '') == 0) {
            hours = '0' + hours;
        }

        return hours + 'h' + ' ' + minutes + 'm';
    }
}
