<div *ngIf="!isDepartureAndArrivalSame" class="global-info-wrapp yellow">
    <p>
        <span class="icon">!</span><span class="text">Please note that you are departing from {{outboundFirstTicket.departure.code}} but returning to
        {{returnLastTicket.arrival.code}}.</span>
    </p>
</div>

<div *ngIf="!isArrivalAndDepartureSame" class="global-info-wrapp yellow">
    <p>
        <span class="icon">!</span><span class="text">Please note that you are arriving at {{outboundLastTicket.arrival.code}} but returning from
        {{returnFirstTicket.departure.code}}.</span>
    </p>
</div>
<div *ngIf="isDepartureHasBag && !isArrivalHasBag" class="global-info-wrapp yellow">
    <p>
        <span class="icon">!</span><span class="text">Please note that a checked bag is included for the outbound flight, but not for the inbound flight.</span>
    </p>
</div>
<div *ngIf="!isDepartureHasBag && isArrivalHasBag" class="global-info-wrapp yellow">
    <p>
        <span class="icon">!</span><span class="text">Please note that a checked bag is included for the inbound flight, but not for the outbound flight.</span>
    </p>
</div>
<div *ngIf="isTypeOfBagsDifferent" class="global-info-wrapp yellow">
    <p>
        <span class="icon">!</span><span class="text">Please note that the baggage allowances of outbound and inbound flights are different.</span>
    </p>
</div>
<div *ngIf="isOtheroutbound" class="global-info-wrapp yellow">
    <p>
        <span class="icon">!</span><span class="text">Please note that this outbound flight is different from the one you selected in the homepage.</span>
    </p>
</div>
<div *ngIf="text" class="global-info-wrapp yellow">
    <p>
        <span class="icon">!</span><span class="text">{{text}}</span>
    </p>
</div>