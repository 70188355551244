import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { FlightBoardService } from "app/services/flight-board/flight-board.service";

@Component({
  selector: 'app-popular-destinations',
  templateUrl: './popular-destinations.component.html',
  styleUrls: ['./popular-destinations.component.css']
})
export class PopularDestinationsComponent implements OnInit {

  destinations: Array<Object>;

  constructor(
    private router: Router,
    private flightBoardService:FlightBoardService
    ) { }

  ngOnInit() {
    this.destinations = this.flightBoardService.destinations;
  }

}
