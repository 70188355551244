import { Component, OnInit, Input } from '@angular/core';
import { Location } from '@angular/common';
import { Router } from '@angular/router';

@Component({
    selector: 'app-wizard',
    templateUrl: './wizard.component.html',
    styleUrls: ['./wizard.component.css']
})
export class WizardComponent implements OnInit {
    @Input() activeTabIndex: number;
    @Input() isOneWay: boolean;

    constructor(private location: Location,
                private router: Router) { }

    ngOnInit() {
    }

    goBack(index){
        if(this.activeTabIndex && this.activeTabIndex != 5){
            let count = this.activeTabIndex - index;
            while(count > 0){
                this.location.back();
                count--;
            }
        }else {
            // this.router.navigate([""]);
            return
        }
        
    }

}
