import { Component, OnInit, Input, ChangeDetectorRef, Output, EventEmitter } from '@angular/core';
import { Passenger } from 'app/shared';
import { UntypedFormGroup } from '@angular/forms';
import * as moment from 'moment';

@Component({
    selector: 'app-passenger',
    templateUrl: './passenger.component.html',
    styleUrls: ['./passenger.component.css']
})
export class PassengerComponent implements OnInit {
    @Input('passenger') passenger: Passenger;
    @Input('form') form: UntypedFormGroup;
    @Input('formSubmitAttempt') formSubmitAttempt: boolean;

    @Output('activateBooker') activateBooker: EventEmitter<boolean> = new EventEmitter(false);

    years = [];
    months = [];
    days = [];

    pass: Passenger;

    constructor(
        private cdr: ChangeDetectorRef
    ) {
        this.cdr.detach();
    }

    ngOnInit() {
        this.pass = Object.create(this.passenger);

        this.setupYears();
        let date = moment();
        date.month(0);
        while (this.months.length < 12) {
            this.months.push({
                value: date.month(),
                label: date.format('MMMM')
            })
            date.month(date.month() + 1);
        }
        this.setupDays(0, this.years[0])
        this.cdr.reattach();
        this.cdr.detectChanges();

    }


    setupDays(month, year) {
        // Since no month has fewer than 28 days
        let date = new Date(year, month, 1);
        let days = [];
        while (date.getMonth() === month) {
            days.push(new Date(date).getDate());
            date.setDate(date.getDate() + 1);
            if (days[days.length - 1] < 10)
                days[days.length - 1] = '0' + days[days.length - 1];
        }

        this.days = days;
    }

    setPassangerDay(event: any) {
        this.pass.birthDay = event;
        this.setUpDate('day');
    }


    setPassangerMonth(event: any) {
        this.pass.birthMonth = event;
        this.setUpDate('month');
    }

    setPassangerYear(event: any) {
        this.pass.birthYear = event;
        this.setUpDate('year');


    }

    setUpDate(callFrom: string) {
        //day, month calc year
        if (this.pass.birthDay && this.pass.birthMonth && !this.pass.birthYear) {
            
            let newYears = this.generateArrayOfYears(this.pass.birthDay.toString(), this.pass.birthMonth);
            if (newYears.length) {
                this.years = [...newYears];
            }

        }//day, year calc month
        else if (this.pass.birthDay && this.pass.birthYear && !this.pass.birthMonth) {
            
            let newMonths = this.generateArrayOfMonths(this.pass.birthDay.toString(), this.pass.birthYear);
            if (newMonths.length) {
                this.months = [...newMonths];
            }
        }//month, year calc day
        else if (this.pass.birthMonth && this.pass.birthYear && !this.pass.birthDay) {
            
            let newDays = this.generateArrayOfDays(this.pass.birthMonth, this.pass.birthYear);
            if (newDays.length) {
                this.days = [...newDays];
            }

            let newMonths = this.generateArrayOfMonthsByYear(this.pass.birthYear);
            if (newMonths.length) {
                this.months = [...newMonths];
            }
            
            let newYears = this.generateArrayOfYearsByMonth(this.pass.birthMonth);
            if (newYears.length) {
                this.years = [...newYears];
            }
        }//month
        else if(this.pass.birthMonth && !this.pass.birthYear && !this.pass.birthDay){
            let newYears = this.generateArrayOfYearsByMonth(this.pass.birthMonth);
            if (newYears.length) {
                this.years = [...newYears];
            }
        }//year
        else if (this.pass.birthYear && !this.pass.birthDay && !this.pass.birthMonth) {
            
            let newMonths = this.generateArrayOfMonthsByYear(this.pass.birthYear);
            if (newMonths.length) {
                this.months = [...newMonths];
            }
        }
        else if (this.pass.birthDay && this.pass.birthMonth && this.pass.birthYear) {
            this.checkAdultAge();
            switch (callFrom) {
                case 'day': {
                    let newYears = this.generateArrayOfYears(this.pass.birthDay.toString(), this.pass.birthMonth);
                    if (newYears.length) {
                        this.years = [...newYears];
                    }
                    break;
                }
                case 'month': {
                    let newYears = this.generateArrayOfYears(this.pass.birthDay.toString(), this.pass.birthMonth);
                    if (newYears.length) {
                        this.years = [...newYears];
                    }
                    let newDays = this.generateArrayOfDays(this.pass.birthMonth, this.pass.birthYear);
                    if (newDays.length) {
                        this.days = [...newDays];
                    }
                    break;
                }
                case 'year': {
                    let newMonths = this.generateArrayOfMonths(this.pass.birthDay.toString(), this.pass.birthYear);
                    if (newMonths.length) {
                        this.months = [...newMonths];
                    }
                    let newDays = this.generateArrayOfDays(this.pass.birthMonth, this.pass.birthYear);
                    if (newDays.length) {
                        this.days = [...newDays];
                    }
                    break;
                }
            }
        }
    }


    checkAdultAge() {

        if (this.pass.type === 'adult') {
            let date = new Date();
            let currentYear = date.getFullYear();
            let currentMonth = date.getMonth();
            let today = date.getDate();
            let age = currentYear - this.pass.birthYear;
            let passBirthMonth: any;

            if (age <= 18) {
                passBirthMonth = this.pass.birthMonth;
                
                if (age === 18) {
                    if ((currentMonth < passBirthMonth.value) || (currentMonth === passBirthMonth.value && today < this.pass.birthDay)) {
                        this.passenger.age = 17;
                    }
                } else if (age < 18) {
                    this.passenger.age = 17;
                }else {
                    this.passenger.age = 18;
                }
            }else {
                this.passenger.age = age;
            }

            this.activateBooker.emit(true);
        }
    }

    generateArrayOfDays(month: any, year: number) {
        let daysArray = [];
        let currentYear = new Date().getFullYear();
        let date = moment();

        if (currentYear - year === this.pass.minAge && month.value === date.month()) {
            //fill days until current day inclusive
            let newDate = new Date(year, date.month(), 1);

            while (newDate.getMonth() === month.value && newDate.getDate() <= date.date()) {
                daysArray.push(new Date(newDate).getDate());
                newDate.setDate(newDate.getDate() + 1);
                if (daysArray[daysArray.length - 1] < 10)
                    daysArray[daysArray.length - 1] = '0' + daysArray[daysArray.length - 1];
            }
        } else {
            //fill all days
            if(this.pass.type === 'adult' || currentYear - year !== this.pass.maximumAge) {
                let newDate = new Date(year, 0, 1);

                while (newDate.getMonth() === 0) {
                    daysArray.push(new Date(newDate).getDate());
                    newDate.setDate(newDate.getDate() + 1);
                    if (daysArray[daysArray.length - 1] < 10)
                        daysArray[daysArray.length - 1] = '0' + daysArray[daysArray.length - 1];
                }
            }else if(this.pass.type != 'adult' && currentYear -year === this.pass.maximumAge) {
                let currentMonth = new Date().getMonth();
                if(currentMonth === month.value) {
                    let newDate = new Date(year, 0, new Date().getDate() + 1);

                    while (newDate.getMonth() === 0) {
                        daysArray.push(new Date(newDate).getDate());
                        newDate.setDate(newDate.getDate() + 1);
                        if (daysArray[daysArray.length - 1] < 10)
                            daysArray[daysArray.length - 1] = '0' + daysArray[daysArray.length - 1];
                    }
                }else {
                    let newDate = new Date(year, 0, 1);

                    while (newDate.getMonth() === 0) {
                        daysArray.push(new Date(newDate).getDate());
                        newDate.setDate(newDate.getDate() + 1);
                        if (daysArray[daysArray.length - 1] < 10)
                            daysArray[daysArray.length - 1] = '0' + daysArray[daysArray.length - 1];
                    }
                }
            }
            
        }

        return daysArray;
    }

    generateArrayOfMonthsByYear(year: number) {
        let monthsArray = [];
        let currentYear = new Date().getFullYear();
        let date = moment();

        if (currentYear - year === this.pass.minAge) {
            let diffInMonths = 12 - (date.month()) + 1;
            date.month(0);
            while (monthsArray.length < diffInMonths) {
                monthsArray.push({
                    value: date.month(),
                    label: date.format('MMMM')
                })
                date.month(date.month() + 1);
            }

        } else {
            if(this.pass.type === 'adult' || currentYear - year !== this.pass.maximumAge) {
                date.month(0);
                while (monthsArray.length < 12) {
                    monthsArray.push({
                        value: date.month(),
                        label: date.format('MMMM')
                    })
                    date.month(date.month() + 1);
                }
            }else if(this.pass.type !== 'adult' && currentYear - year === this.pass.maximumAge) {
                let isLastDay = this.isLastDayOfMonth(new Date());
                let diffInMonths = 12 - new Date().getMonth();
                if(isLastDay) {
                    date.month(new Date().getMonth() + 1);
                }else {
                    date.month(new Date().getMonth());
                }
                
                while (monthsArray.length < diffInMonths) {
                    monthsArray.push({
                        value: date.month(),
                        label: date.format('MMMM')
                    })
                    date.month(date.month() + 1);
                }
            }
            
        }

        return monthsArray;
    }

    parseDay(day: string) {
        let parsedDay;
        if (day[0] === '0') {
            day = day.substring(1);
            parsedDay = parseInt(day);

            return parsedDay;
        }
        parsedDay = parseInt(day);
        return parsedDay;
    }

    generateArrayOfMonths(day: string, year: number) {
        let monthsArray = [];
        let currentDay = new Date().getDate();
        let currentYear = new Date().getFullYear();
        let date = moment();
        let parsedDay = this.parseDay(day);

        if (currentYear - year === this.pass.minAge) {
            // let parsedDay = this.parseDay(day);

            if (date.date() >= parsedDay) {
                let diffInMonths = 12 - (date.month()) + 1;
                date.month(0);
                while (monthsArray.length < diffInMonths) {
                    monthsArray.push({
                        value: date.month(),
                        label: date.format('MMMM')
                    })
                    date.month(date.month() + 1);
                }
            } else {
                let diffInMonths = 12 - (date.month());
                date.month(0);
                while (monthsArray.length < diffInMonths) {
                    monthsArray.push({
                        value: date.month(),
                        label: date.format('MMMM')
                    })
                    date.month(date.month() + 1);
                }
            }
        } else {
            if(this.pass.type === 'adult' || currentYear - year != this.pass.maximumAge) {
                date.month(0);
                while (monthsArray.length < 12) {
                    monthsArray.push({
                        value: date.month(),
                        label: date.format('MMMM')
                    })
                    date.month(date.month() + 1);
                }
            }else if(this.pass.type != 'adult' && currentYear - year === this.pass.maximumAge) {
                if(currentDay >= parsedDay) {
                    let diffInMonths = 12 - (date.month());
                    date.month(date.month() + 1);
                    while (monthsArray.length < diffInMonths) {
                        monthsArray.push({
                            value: date.month(),
                            label: date.format('MMMM')
                        })
                        date.month(date.month() + 1);
                    }
                }else {
                    let diffInMonths = 12 - (date.month());
                    date.month(date.month());
                    while (monthsArray.length < diffInMonths) {
                        monthsArray.push({
                            value: date.month(),
                            label: date.format('MMMM')
                        })
                        date.month(date.month() + 1);
                    }
                }
            }
        }

        return monthsArray;
    }

    generateArrayOfYears(day: string, month: any) {
        let date = new Date();
        let parsedDay;
        let startYear = date.getFullYear() - this.pass.maximumAge;
        let endYear = date.getFullYear() - this.pass.minAge;

        parsedDay = this.parseDay(day);
        if(this.pass.type === 'adult') {
            if (date.getMonth() === month.value && date.getDate() >= parsedDay) {
                let diffInYears = endYear - startYear;
                return this.drawYears(startYear, diffInYears);
            } else if (date.getMonth() === month.value && date.getDate() < parsedDay) {
                endYear = endYear - 1;
                let diffInYears = endYear - startYear;
                return this.drawYears(startYear, diffInYears);
            } else if (date.getMonth() > month.value) {
                let diffInYears = endYear - startYear;
                return this.drawYears(startYear, diffInYears);
            } else {
                endYear = endYear - 1;
                let diffInYears = endYear - startYear;
                return this.drawYears(startYear, diffInYears);
            }
        }else {
            if (date.getMonth() === month.value && date.getDate() >= parsedDay) {
                let diffInYears = endYear - startYear;
                return this.drawYears(startYear, diffInYears);
            } else if (date.getMonth() === month.value && date.getDate() < parsedDay) {
                endYear = endYear - 1;
                startYear = startYear - 1;
                let diffInYears = endYear - startYear;
                return this.drawYears(startYear, diffInYears);
            } else if (date.getMonth() > month.value) {
                
                let diffInYears = endYear - startYear;
                return this.drawYears(startYear, diffInYears);
            } else {
                endYear = endYear - 1;
                startYear = startYear - 1;
                let diffInYears = endYear - startYear;
                return this.drawYears(startYear, diffInYears);
            }
        }
        
    }

    generateArrayOfYearsByMonth(month: any) {
        
        let currentMonth = new Date().getMonth();
        let startYear = new Date().getFullYear() - this.pass.maximumAge;
        let endYear = new Date().getFullYear() - this.pass.minAge;

        let isLastDay = this.isLastDayOfMonth(new Date());

        if(currentMonth < month.value) {
            startYear = new Date().getFullYear() - this.pass.maximumAge - 1;
            let diffInYears = endYear - startYear -1;
            return this.drawYears(startYear, diffInYears);
        }else if(currentMonth === month.value && isLastDay === false){
            startYear = new Date().getFullYear() - this.pass.maximumAge - 1;
            let diffInYears = endYear - startYear;
            return this.drawYears(startYear, diffInYears);
        }else {
            let diffInYears = endYear - startYear;
            return this.drawYears(startYear, diffInYears);
        }
    }

    isLastDayOfMonth(date) {
        const nextDay = new Date(date);
        nextDay.setDate(date.getDate() + 1);
        return nextDay.getMonth() !== date.getMonth();
    }

    drawYears(startYear, diffInYears) {
        let yearsArray = [];

        for (let i = 1; i <= diffInYears; i++) {
            let year = startYear + i;
            yearsArray.push(year);
        }
        return yearsArray.reverse();
    }

    setupYears() {
        if(this.pass.type === 'adult'){
            let date = moment();
            date.subtract(this.passenger.minAge, 'year');
            const threshold = moment();
            threshold.subtract(this.passenger.maximumAge, 'year')
    
            while (date.isAfter(threshold)) {
                this.years.push(date.get('year'));
                date.subtract(1, 'year');
            }
        }else {
            let date = moment();
            date.subtract(this.passenger.minAge, 'year');
            const threshold = moment();
            threshold.subtract(this.passenger.maximumAge + 1, 'year')
    
            while (date.isAfter(threshold)) {
                this.years.push(date.get('year'));
                date.subtract(1, 'year');
            }
        }
    }
}