import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'highlight'
})
export class HighlightPipe implements PipeTransform {
  transform(value: string, searchTerm: string): string {
    if (!searchTerm) {
      return value;
    }
  
    // Escape special characters in the search term
    const escapedSearchTerm = searchTerm.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&');
    
    // Create a regex to match the beginning of the string or the beginning letters within parentheses
    const regex = new RegExp(`(^${escapedSearchTerm}|\\(([^)]*?)(${escapedSearchTerm}))`, 'gi');
  
    // Replace the matched text with the highlighted version
    return value.replace(regex, (match, p1, p2, p3) => {
      if (p3) {
        return `(${p2}<span class="highlight">${p3}</span>`;
      } else {
        return `<span class="highlight">${p1}</span>`;
      }
    });
  }
}
