import { Injectable } from '@angular/core';
import { Subject, BehaviorSubject ,  Observable, ReplaySubject } from 'rxjs';

import { Flight, PriceBreakdown, PassengerList } from 'app/shared';

@Injectable()
export class FlightManagerService {
    
    public passengers: any;
    public formSubmitAttempt: boolean = false;
    readonly passengerDetails: ReplaySubject<PriceBreakdown> = new ReplaySubject(1);
    readonly fareRulesOfFlight: ReplaySubject<string> = new ReplaySubject(1);
    readonly flightSubject: Subject<Flight> = new Subject();
    private _passengerList: PassengerList = new PassengerList();

    public typeOfFlightDetailsTable: number;

    set passengerList(value) {
        this._passengerList = value;
    }

    get passengerList() {
        return this._passengerList;
    }

    constructor() { }

    public selectFlight(flight: Flight): void {
        this.flightSubject.next(flight)
    }

    public getPassengersDetails(value: PriceBreakdown): void {
        this.passengerDetails.next(value);
    }

    calcTypeOfTable(dataOfpassengerDetails): number {
        if(dataOfpassengerDetails.adult.serviceFee === null && dataOfpassengerDetails.adult.discount != null) {
            return 1;
        }else if(dataOfpassengerDetails.adult.serviceFee != null && dataOfpassengerDetails.adult.discount === null) {
            return 2;
        }else {
            return 3;
        }
    }

    willArriveNextDay(outboundFirstTicket, outboundLastTicket) {
        let willArriveNextDay = false;
        let dayOfArrival: number;
        if (outboundFirstTicket.departure.date.date() != outboundLastTicket.arrival.date.date()) {
            willArriveNextDay = true;
            dayOfArrival = outboundLastTicket.arrival.date.clone().startOf('day').diff(outboundFirstTicket.departure.date.clone().startOf('day'), 'days');
            return{
                willArriveNextDay,
                dayOfArrival
            }
        }
    }

    
}
