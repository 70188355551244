import {
    trigger,
    state,
    style,
    animate,
    transition,
    keyframes
} from '@angular/animations';


export const toggle = trigger(
    'toggleAnimation', [
    transition(':enter', [
        style({
            // display: 'none',
            overflow: 'hidden',
            height: '0px'
        }),
        animate('500ms', style({
            display: 'block',
            height: '*'
        }))
    ]),
    transition(':leave', [
        style({
            // display: 'block',
            overflow: 'hidden',
            height: '*'
        }),
        animate('300ms', style({
            // display: 'none',
            overflow: 'hidden',
            height: '0px'
        }))
    ])
]
)

export const filterDropdown = trigger(
    'toggleAnimationFilter', [
    transition(':enter', [
        style({
            // display: 'none',
            overflow: 'hidden',
            height: '0px'
        }),
        animate('200ms', style({
            display: 'block',
            height: '*'
        }))
    ]),
    transition(':leave', [
        style({
            // display: 'block',
            overflow: 'hidden',
            height: '*'
        }),
        animate('200ms', style({
            // display: 'none',
            overflow: 'hidden',
            height: '0px'
        }))
    ])
]
)

export const openFilter = trigger(
    'toggleFilter', [
    transition(':enter', [
        style({
            // display: 'none',
            overflow: 'hidden',
            height: '0px'
        }),
        animate('300ms', style({
            display: 'block',
            height: '455px'
        }))
    ]),
    transition(':leave', [
        style({
            // display: 'block',
            overflow: 'hidden',
            height: '*'
        }),
        animate('300ms', style({
            // display: 'none',
            overflow: 'hidden',
            height: '0px'
        }))
    ])
]
)

export const openMobileFilter = trigger(
    'toggleMobileFilter', [
    transition(':enter', [
        style({
            // display: 'none',
            overflow: 'hidden',
            height: '0px'
        }),
        animate('400ms', style({
            display: 'block',
            height: '100vh'
        }))
    ]),
    transition(':leave', [
        style({
            // display: 'block',
            overflow: 'hidden',
            height: '*'
        }),
        animate('500ms', style({
            // display: 'none',
            overflow: 'hidden',
            height: '0px'
        }))
    ])
]
)

export const openCloseSearch = trigger('openCloseSearch', [
    state('open', style({
        flexBasis: 'auto',
        flexGrow: '1',
        display: 'flex'
    })),
    state('closed', style({
        flexBasis: '0',
        flexGrow: '0',
        display: 'none'
    })),
    transition('open => closed', [
        // First, remain open for a delay duration
        style({ flexBasis: '*', flexGrow: '*', display: 'flex' }), // Start with open styles
        animate('0s', style({})), // Ensure the style is applied immediately
        animate('0s ease', style({ flexBasis: 'auto', flexGrow: '1', display: 'flex' })), // Delay
        animate('0.5s ease', style({ flexBasis: '0', flexGrow: '0', display: 'none' })) // Animate to closed state
    ]),
    transition('closed => open', [
        // First, remain open for a delay duration
        style({ flexBasis: '0', flexGrow: '0', display: 'none' }), // Start with open styles
        animate('0s', style({})), // Ensure the style is applied immediately
        animate('0.5s ease', style({ flexBasis: '0', flexGrow: '0', display: 'none' })), // Delay
        animate('0.5s ease', style({ flexBasis: '1', flexGrow: '1', display: 'flex' })) // Animate to closed state
    ])
]);


export const openCloseDepartureHeader = trigger('openCloseDepartureHeader', [
    state('open', style({
        flexBasis: 'auto',
        flexGrow: '1',
        display: 'flex'
    })),
    state('closed', style({
        flexBasis: '0',
        flexGrow: '0',
        display: 'none'
    })),
    transition('open => closed', [
        // First, remain open for a delay duration
        style({ flexBasis: '*', flexGrow: '*', display: 'flex' }), // Start with open styles
        animate('0s', style({})), // Ensure the style is applied immediately
        animate('0s ease', style({ flexBasis: 'auto', flexGrow: '1', display: 'flex', overflow: 'hidden' })), // Delay
        animate('0.5s ease', style({ flexBasis: '0', flexGrow: '0', display: 'none' })) // Animate to closed state
    ]),
    transition('closed => open', [
        // First, remain open for a delay duration
        style({ flexBasis: '0', flexGrow: '0', display: 'none' }), // Start with open styles
        animate('0s', style({})), // Ensure the style is applied immediately
        animate('0.5s ease', style({ flexBasis: '0', flexGrow: '0', display: 'none', overflow: 'hidden' })), // Delay
        animate('0.5s ease', style({ flexBasis: '1', flexGrow: '1', display: 'flex' })) // Animate to closed state
    ])
]);