<div #icon (mouseenter)="tooltipShow = true" (mouseleave)="tooltipShow = false"
  class="glyphicon" aria-hidden="true" [ngClass]="{'position-1':departureBoard === true, 'position-2': departureBoard === false}">
  <!-- <span class="circle"></span> -->
  <div>
    <img width="15px" src="assets/img/icons/info-icon.png">
  </div>
</div>
<div 
  *ngIf="tooltipShow && (!baggage_section || !isMobile)" 
  #tooltipBox 
  class="tooltip fade bottom in" 
  [ngClass]="[
    displayStyle ? displayStyle : '', center ? 'center-style' : '']" 
  role="tooltip" 
  [ngStyle]="{ left: left, top: top }"
>
  <div class="tooltip-arrow"></div>
  <div class="tooltip-inner">
    <div class="img-content" *ngIf="baggage_section">
      <h6>Baggage Allowance</h6>
      <div class="baggages">
        <span class="img-container">
          <img class="small-bag" src="assets/img/icons/personal-item.png"/>
        </span>
        <img class="checked-unchecked-icon" src="assets/img/icons/checked.svg"/>
        <span class="bag-description">Small Cabin Bag: <span class="included green-clr" *ngIf="baggage_items[0].qty">included</span></span>
      </div>
      <div class="baggages">
        <!-- <img [ngClass]="{'disabled': !isCabinBag && !isCheckedBag }" class="cabin-bag" src="assets/img/icons/bag_2.png"/> -->
        <span class="img-container">
          <img class="cabin-bag" src="assets/img/icons/bag_2(1).png"/>
        </span>
        <img *ngIf="!baggage_items[1].qty" class="checked-unchecked-icon" src="assets/img/icons/unchecked.svg"/>
        <img *ngIf="baggage_items[1].qty" class="checked-unchecked-icon" src="assets/img/icons/checked.svg"/>
        <span *ngIf="baggage_items[1].qty" class="bag-description"> Large Cabin Bag: <span class="included green-clr">included</span></span>
        <span *ngIf="!baggage_items[1].qty" class="bag-description"> Large Cabin Bag: <span class="included red-clr">not included</span></span>
      </div>
      <div class="baggages">
        <!-- <img [ngClass]="{'disabled': !isCheckedBag}" class="checked-bag" src="assets/img/icons/bag_1.png"/> -->
        <span class="img-container">
          <img class="checked-bag" src="assets/img/icons/bag_1.png"/>
        </span>
        <img *ngIf="!baggage_items[2].qty" class="checked-unchecked-icon" src="assets/img/icons/unchecked.svg"/>
        <img *ngIf="baggage_items[2].qty" class="checked-unchecked-icon" src="assets/img/icons/checked.svg"/>
        <span *ngIf="baggage_items[2].qty" class="bag-description"> Checked Bag: <span class="included green-clr">included</span> 
          <span *ngIf="baggage_items[2].unit == 'KG'" class="green-clr"> (1={{baggage_items[2].qty}}kg) </span> 
          <span *ngIf="baggage_items[2].unit === 'P' && baggage_items[2].qty >= 1" class="green-clr"> ({{baggage_items[2].qty}}) </span>
        </span>
        <span *ngIf="!baggage_items[2].qty" class="bag-description"> Checked Bag: <span class="included red-clr">not included</span></span>
      </div>
    </div>

    <div class="break" *ngIf="baggage_section"></div>
    <div [ngClass]="{'content': baggage_section === true}" class="ng-content"> 
      <ng-content></ng-content>
    </div> 
  </div>

</div>

<div 
  *ngIf="tooltipShow && baggage_section && isMobile" 
  #tooltipBox
  [ngClass]="{'baggage-tooltip-mobile-container': baggage_section && isMobile}"
  role="tooltip" 
  [ngStyle]="{ left: left, top: top }"
>
  <div class="baggage-tooltip-mobile-content">
    <div class="tooltip-inner tooltip-inner-mobile">
      <div class="img-content">
        <h6>Baggage Allowance</h6>
        <div class="baggages">
          <span class="img-container">
            <img class="small-bag" src="assets/img/icons/personal-item.png"/>
          </span>
          <img class="checked-unchecked-icon" src="assets/img/icons/checked.svg"/>
          <span class="bag-description">Small Cabin Bag: <span class="included green-clr" *ngIf="baggage_items[0].qty">included</span></span>
        </div>
        <div class="baggages">
          <!-- <img [ngClass]="{'disabled': !isCabinBag && !isCheckedBag }" class="cabin-bag" src="assets/img/icons/bag_2.png"/> -->
          <span class="img-container">
            <img class="cabin-bag" src="assets/img/icons/bag_2(1).png"/>
          </span>
          <img *ngIf="!baggage_items[1].qty" class="checked-unchecked-icon" src="assets/img/icons/unchecked.svg"/>
          <img *ngIf="baggage_items[1].qty" class="checked-unchecked-icon" src="assets/img/icons/checked.svg"/>
          <span *ngIf="baggage_items[1].qty" class="bag-description"> Large Cabin Bag: <span class="included green-clr">included</span></span>
          <span *ngIf="!baggage_items[1].qty" class="bag-description"> Large Cabin Bag: <span class="included red-clr">not included</span></span>
        </div>
        <div class="baggages">
          <!-- <img [ngClass]="{'disabled': !isCheckedBag}" class="checked-bag" src="assets/img/icons/bag_1.png"/> -->
          <span class="img-container">
            <img class="checked-bag" src="assets/img/icons/bag_1.png"/>
          </span>
          <img *ngIf="!baggage_items[2].qty" class="checked-unchecked-icon" src="assets/img/icons/unchecked.svg"/>
          <img *ngIf="baggage_items[2].qty" class="checked-unchecked-icon" src="assets/img/icons/checked.svg"/>
          <span *ngIf="baggage_items[2].qty" class="bag-description"> Checked Bag: <span class="included green-clr">included</span> 
            <span *ngIf="baggage_items[2].unit == 'KG'" class="green-clr"> (1={{baggage_items[2].qty}}kg) </span> 
            <span *ngIf="baggage_items[2].unit === 'P' && baggage_items[2].qty >= 1" class="green-clr"> ({{baggage_items[2].qty}}) </span>
          </span>
          <span *ngIf="!baggage_items[2].qty" class="bag-description"> Checked Bag: <span class="included red-clr">not included</span></span>
        </div>
      </div>
  
      <div class="break" *ngIf="baggage_section"></div>
      <div [ngClass]="{'content': baggage_section === true}" class="ng-content"> 
        <ng-content></ng-content>
      </div> 
    </div>
  </div>
  
</div>