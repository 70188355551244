<div class="modal">
    <div class="modal-content">
        <div class="main-container">
            <div class="main-circle">
                <div class="circle blink-circle"></div> <img class="loading-plane" src="assets/img/icons/departure.png">
                <div class="circle big-circle"></div>
                <div class="circle big-circle big-circle2"></div>
                <div class="circle mid-circle"></div>
                <div class="circle mid-circle mid-circle2"></div>
                <div class="circle small-circle"></div>
                <div class="circle small-circle small-circle2"></div>
                <div class="circle extra-small-circle"></div>
                <div class="circle extra-small-circle extra-small-circle2"></div>
                <div class="circle extra-small-circle extra-small-circle3"></div>
                <div class="circle extra-small-circle extra-small-circle4"></div>
                <div class="circle extra-small-circle extra-small-circle5"></div>
            </div>
        </div>
        <div class="text-container">
            <h2>Almost there...</h2>
            <p>We are confirming with the airline that the flight you selected is still available. This may take up to 30 seconds.</p>
        </div>
    </div>
</div>