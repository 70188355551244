import { Component, OnInit, Input, Output, EventEmitter, ElementRef, Renderer2, HostListener } from '@angular/core';


@Component({
  selector: 'app-filter-field',
  templateUrl: './filter-field.component.html',
  styleUrls: ['./filter-field.component.css']
})
export class FilterFieldComponent {
  @Input('isActive') isActive: boolean = false;
  @Input('name') name: string;
  @Input() previousFilters: any;

  private _filters: Array<any>;

  @Input('filters') filters;
  @Input('filter_length') filter_length;
  @Input() items = [];
  @Input() typeInString: string;
  @Input() optionItems: string;
  @Input() hasSelectAndClearField;
  @Input() mobile: boolean = false;

  @Output() onApplyClick = new EventEmitter();
  @Output() selectAllButton = new EventEmitter();
  @Output() clearAllButton = new EventEmitter();


  itemLength: number = 0;
  isOpen = false;

  private wasInside = false;
  @HostListener('click')
  clickInside() {
    this.wasInside = true;
  }

  @HostListener('document:click')
  clickout() {
    if (!this.wasInside) {
      this.isOpen = false;
    }
    this.wasInside = false;
  }

  constructor() {
  }

  ngOnInit() {
  }

  filterBy(by: string, value: number | string) {
    let index = this.filters[by].indexOf(value);
    if (index > -1) {
      // if the item exists already then we need to remove it from the list
      this.filters[by].splice(index, 1);
    } else {
      // if the item doesn't exist then we need to add it in the list
      this.filters[by].push(value);
    }
    return this.isApplyButtonActive(by);
  }

  isApplyButtonActive(filter: string) {
    const prev = this.previousFilters[filter];
    const curr = this.filters[filter];

    if (prev.length != curr.length)
      return true;

    return curr.some(x => !prev.includes(x))
  }

  onApply() {
    this.onApplyClick.emit();
  }

  selectAll(firstItem: string, secondItem: string) {
    this.selectAllButton.emit({ firstItem, secondItem });
  }

  clearAll(firstItem: string, secondItem: string) {
    this.clearAllButton.emit({ firstItem, secondItem });
  }
  toggleFilter() {
    this.isOpen = !this.isOpen;
  }

}
