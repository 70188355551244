import { ChangeDetectorRef, Component, ElementRef, QueryList, Renderer2, ViewChildren } from '@angular/core';

@Component({
  selector: 'app-process-flow',
  templateUrl: './process-flow.component.html',
  styleUrls: ['./process-flow.component.scss']
})
export class ProcessFlowComponent {
  @ViewChildren('contnent') contnents: QueryList<ElementRef>;
  public isContentOpen1 = false;
  public isContentOpen2 = false;
  public isContentOpen3 = false;

  constructor(private renderer: Renderer2) {}

  toggleContent(id) {
    let arrOfContents = this.contnents.toArray();
    let index = id;
    index =- 1 

    if(id === 1) {
      this.isContentOpen1 = !this.isContentOpen1;
      const displayValue = this.isContentOpen1 ? 'block' : '-webkit-box';
      this.renderer.setStyle(arrOfContents[0].nativeElement, 'display', displayValue);
      this.renderer.setStyle(arrOfContents[0].nativeElement, 'max-height', '100%');
    }else if(id === 2) {
      this.isContentOpen2 = !this.isContentOpen2;
      const displayValue = this.isContentOpen2 ? 'block' : '-webkit-box';
      this.renderer.setStyle(arrOfContents[1].nativeElement, 'display', displayValue);
      this.renderer.setStyle(arrOfContents[1].nativeElement, 'max-height', '100%');
    }else {
      this.isContentOpen3 = !this.isContentOpen3;
      const displayValue = this.isContentOpen3 ? 'block' : '-webkit-box';
      this.renderer.setStyle(arrOfContents[2].nativeElement, 'display', displayValue);
      this.renderer.setStyle(arrOfContents[2].nativeElement, 'max-height', '100%');
    }
  }
}
