import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

// import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { MomentModule } from 'angular2-moment';

// services
import { FlightAccordionService} from './flight-accordion/flight-accordion.service';
import { CalendarDayService } from './calendar-day/calendar-day.service';
import { ReturnFlightAccordionService } from './return-flight-accordion/return-flight-accordion.service';
import { TableHeaderService } from './table-header/table-header.service';

// directives
import { LoadingDirective } from 'app/directives/loading.directive';
import { NumberOnlyDirective } from 'app/directives/number-only.directive';
import { HighlightTextDirective } from 'app/directives/highlight-text';

import { PipesModule } from 'app/pipes/pipes.module';


import { WizardComponent } from './wizard/wizard.component';
import { FlightCardComponent } from './flight-card/flight-card.component';
import { ImgCardComponent } from './img-card/img-card.component';
import { CalendarDayComponent } from './calendar-day/calendar-day.component';
import { FlightCalendarComponent } from './flight-calendar/flight-calendar.component';
import { ReturnFlightAccordionComponent } from './return-flight-accordion/return-flight-accordion.component';
import { FlightDetailsComponent } from './flight-details/flight-details.component';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { FlightAccordionComponent } from './flight-accordion/flight-accordion.component';
import { DepartureListComponent } from './departure-list/departure-list.component';
import { FlightsFilterComponent } from './flights-filter/flights-filter.component';
import { LoadingComponent } from './loading/loading.component';
import { TableHeaderComponent } from './table-header/table-header.component';
import { BasketComponent } from './basket/basket.component';
import { BasketFlightComponent } from './basket-flight/basket-flight.component';
import { TooltipComponent } from './tooltip/tooltip.component';
import { SelectComponent } from './select/select.component';
import { PassengersChooserComponent } from './passengers-chooser/passengers-chooser.component';
import { PassengerComponent } from './passenger/passenger.component';
import { BookerComponent } from './booker/booker.component';
import { BaggageInfoComponent } from './baggage-info/baggage-info.component';
import { BookingConfirmationPriceDetailsComponent } from './booking-confirmation-price-details/booking-confirmation-price-details';
import { PriceComponent } from './price/price.component';
import { DepartureArrivalDiffComponent } from './departure-arrival-diff/departure-arrival-diff.component';
import { FlightLegDetailsComponent } from './flight-leg-details/flight-leg-details.component';
import { NewsletterComponent } from './newsletter/newsletter.component';
import { FilterDropdownComponent } from './flights-filter/filter-dropdown/filter-dropdown.component';
import { DepartureBoardComponent } from './departure-board/departure-board.component';
import { CardComponentComponent } from './card-component/card-component.component';
import { PopularDestinationsComponent } from './popular-destinations/popular-destinations.component';
import { KeepWaitingComponent } from './keep-waiting/keep-waiting.component';
import { OurAdvantagesComponent } from './our-advantages/our-advantages.component';
import { CustomerReviewsComponent } from './customer-reviews/customer-reviews.component';
import { ShowTimeComponent } from './show-time/show-time.component';
import { FiltersComponent } from './filters/filters.component';
import { FilterFieldComponent } from './filters/filter-field/filter-field.component';

import { NgxSliderModule } from '@angular-slider/ngx-slider';
import { CflightDetailsComponent } from './confirmation-flights/cflight-details/cflight-details.component';
import { CflightLegDetailsComponent } from './confirmation-flights/cflight-leg-details/cflight-leg-details.component';
import { CdepartureArrivalDiffComponent } from './confirmation-flights/cdeparture-arrival-diff/cdeparture-arrival-diff.component';
import { CountryMessageComponent } from './country-message/country-message.component';
import { ProcessFlowComponent } from './process-flow/process-flow.component';
import { SearchComponent } from './search/search.component';
import { FilterItemComponent } from './filter-item/filter-item.component';


@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        MomentModule,
        FormsModule,
        PipesModule,
        ReactiveFormsModule,
        NgxSliderModule
        // NgbModule.forRoot()
    ],
    declarations: [
        LoadingDirective,
        NumberOnlyDirective,
        HighlightTextDirective,
        FlightDetailsComponent,
        HeaderComponent,
        FooterComponent,
        FlightAccordionComponent,
        ReturnFlightAccordionComponent,
        WizardComponent,
        FlightCardComponent,
        ImgCardComponent,
        CalendarDayComponent,
        FlightCalendarComponent,
        DepartureListComponent,
        FlightsFilterComponent,
        LoadingComponent,
        TableHeaderComponent,
        BasketComponent,
        BasketFlightComponent,
        TooltipComponent,
        SelectComponent,
        PassengersChooserComponent,
        PassengerComponent,
        BookerComponent,
        BaggageInfoComponent,
        BookingConfirmationPriceDetailsComponent,
        PriceComponent,
        DepartureArrivalDiffComponent,
        FlightLegDetailsComponent,
        NewsletterComponent,
        FilterDropdownComponent,
        DepartureBoardComponent,
        CardComponentComponent,
        PopularDestinationsComponent,
        KeepWaitingComponent,
        OurAdvantagesComponent,
        CustomerReviewsComponent,
        ShowTimeComponent,
        FiltersComponent,
        FilterFieldComponent,
        CflightDetailsComponent,
        CflightLegDetailsComponent,
        CdepartureArrivalDiffComponent,
        CountryMessageComponent,
        ProcessFlowComponent,
        SearchComponent,
        FilterItemComponent
    ],
    providers: [
        TableHeaderService,
        FlightAccordionService,
        ReturnFlightAccordionService,
        CalendarDayService
    ],
    exports: [
        LoadingDirective,
        LoadingComponent,
        FlightDetailsComponent,
        HeaderComponent,
        FooterComponent,
        FlightAccordionComponent,
        ReturnFlightAccordionComponent,
        WizardComponent,
        FlightCardComponent,
        ImgCardComponent,
        CalendarDayComponent,
        FlightCalendarComponent,
        DepartureListComponent,
        FlightsFilterComponent,
        TableHeaderComponent,
        BasketComponent,
        TooltipComponent,
        SelectComponent,
        PassengersChooserComponent,
        PassengerComponent,
        BookerComponent,
        BaggageInfoComponent,
        BookingConfirmationPriceDetailsComponent,
        PriceComponent,
        DepartureArrivalDiffComponent,
        FlightLegDetailsComponent,
        NewsletterComponent,
        FilterDropdownComponent,
        DepartureBoardComponent,
        CardComponentComponent,
        PopularDestinationsComponent,
        KeepWaitingComponent,
        OurAdvantagesComponent,
        CustomerReviewsComponent,
        ShowTimeComponent,
        CflightDetailsComponent,
        CflightLegDetailsComponent,
        CdepartureArrivalDiffComponent,
        CountryMessageComponent,
        ProcessFlowComponent,
        SearchComponent,
        FilterItemComponent
    ]
})

export class ComponentsModule { }
