import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'amDifferenceCustom' })
export class AmDifferenceCustomPipe implements PipeTransform {
    transform(value: number, type: string, showLeadingZero: any): string {
        // it assumes that defualt value is in minutes
        // otherwise it will parse to minutes
        switch (type) {
            case 'seconds':
                value /= 60;
                break;
            case 'milliseconds':
                value /= 60000
                break;
            case 'hours':
                value *= 60;
                break;
            default:
                break;
        }
        let hours: any = Math.floor(value / 60).toFixed();
        let minutes: any = (60 * (value / 60 - Math.floor(value / 60))).toFixed();
        let seconds: any = Math.floor(value * 60 % 60).toFixed();

        if (parseInt(seconds / 10 + '') == 0) {
            seconds = '0' + seconds;
        }
        if (parseInt(minutes / 10 + '') == 0) {
                minutes = '0' + minutes;
        }
        if (parseInt(hours / 10 + '') == 0) {
            if (showLeadingZero === 'showLeadingZero') {
                hours = '' + hours
            }
            else {

                hours = '0' + hours;
            }
        }
        let result = hours + 'hrs ' + minutes + 'min';
        if (type == 'seconds' || type == 'milliseconds')
            result += ' ' + seconds + 'sec';
        if (type == 'minutes')
            return hours + 'h ' + minutes + 'm';
        if (type == 'full-numbers')
            return hours + ':' + minutes + ':' + seconds;
        return result;
    }
}