import { Component, OnInit, ViewEncapsulation, Input } from '@angular/core';
import * as moment from 'moment';

import { ConfigService } from 'app/services/config/config.service';
import { Ticket, Flight } from 'app/shared/index';

@Component({
    selector: 'app-flight-details',
    templateUrl: './flight-details.component.html',
    styleUrls: ['./flight-details.component.css']
})
export class FlightDetailsComponent implements OnInit {
    @Input('flight') flight: Flight;
    @Input('type') type: string = 'outbound';
    @Input('highlightDepartureArivalDiff') highlightDepartureArivalDiff: boolean = true;
    @Input('confirmation_page') confirmation_page: boolean = false;
    @Input('homeOrReturnPage') homeOrReturnPage: boolean = false;
    @Input('showCountryMessage') showCountryMessage: boolean = false;
    @Input() returnPage!: boolean;

    highlightDeparture: boolean;
    highlightArrival: boolean;
    highlightBag: boolean = false;

    tickets: Array<Ticket>;
    imgUrl: string;
    totalDuration: moment.Duration = moment.duration(0, 'hours');
    fareRulesType: number;
    isOneWay: boolean;

    constructor(private config: ConfigService) {
        this.imgUrl = this.config.get('IMAGE_HOST_URL');
    }
    ngOnInit() {
        this.tickets = this.flight.outboundTickets;

        const flightType = localStorage.getItem("flightType");
        this.isOneWay = flightType === "one-way";

        if (this.highlightDepartureArivalDiff) {
            this.highlightDeparture = !this.flight.isDepartureAndArrivalSame;
            this.highlightArrival = !this.flight.isArrivalAndDepartureSame;
        }


        if (this.type === 'return') {
            this.tickets = this.flight.returnTickets;

            if (this.highlightDepartureArivalDiff) {
                this.highlightDeparture = !this.flight.isArrivalAndDepartureSame;
                this.highlightArrival = !this.flight.isDepartureAndArrivalSame;
            }

            this.highlightBaggage();
        }
        this.getStop();
        this.fareRulesType = this.isOneWay ? 3 : this.flight?.contentProvider;
    }


    highlightBaggage() {
        if ((!this.flight.isDepartureHasBag && this.flight.isArrivalHasBag) || (this.flight.isDepartureHasBag && !this.flight.isArrivalHasBag)) {
            this.highlightBag = true;
        }
    }

    getStop() {
        this.tickets.map((ticket, index) => {
            this.totalDuration.add(ticket.duration);
            if (index > 0) {
                let previousTicket = this.tickets[index - 1];
                this.totalDuration.add(ticket.departure.date.diff(previousTicket.arrival.date));
            }
        })
    }

}
